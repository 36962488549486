import React from 'react';
import { DeviceProvider } from './DeviceContext';
import { HistoryProvider } from './HistoryContext';
import { SubscribersProvider } from './SubscribersContext';
import { UserProvider } from './UserContext';

const providers = [
  <DeviceProvider />,
  <HistoryProvider />,
  <SubscribersProvider />,
  <UserProvider />,
];

export const ContextProvider = ({ children }) => {
  const renderProvider = (providers, children) => {
    const [provider, ...restProviders] = providers;

    if (provider) {
      return React.cloneElement(
        provider,
        null,
        renderProvider(restProviders, children)
      );
    }

    return children;
  };

  return renderProvider(providers, children);
};
