import {
  ACCESSORIES,
  APPROVAL_VIEW_ADMIN,
  APPROVAL_VIEW_ENDUSER,
  BYOD_PLANS,
  DEVICE_SELECT,
  MY_BUNDLES,
  ORDER_ADMIN_APPROVAL,
  ORDER_HISTORY,
  UPGRADES,
  USER_SETTINGS,
} from 'constants/routes';
import React from 'react';
import styled from 'styled-components';

const StartPageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;

  > a {
    margin-bottom: 1rem;
  }
`;

const StartPage = () => {
  return (
    <StartPageContainer>
      <a href={USER_SETTINGS}>User Settings</a>
      <a href={DEVICE_SELECT}>Devices</a>
      <a href={ORDER_HISTORY}>Order History</a>
      <a href={BYOD_PLANS}>BYOD Plans</a>
      <a href={ACCESSORIES}>Accessories</a>
      <a href={UPGRADES}>Upgrade Subscriptions</a>
      <a href={MY_BUNDLES}>My Bundles</a>
      <a href={APPROVAL_VIEW_ADMIN}>Approval View (Admin)</a>
      <a href={APPROVAL_VIEW_ENDUSER}>Approval View (End User)</a>
    </StartPageContainer>
  );
};

export default StartPage;
