import {
  FlexGrid,
  Spacer,
  BaseModal,
  ExpandCollapse,
  Checkbox,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OrderSummary from 'components/OrderSummary';
import {
  exampleEnduserPaymentOptions,
  exampleByodOrderInfo,
} from 'context/exampleDeviceData';
import Accordion from 'components/Accordion';
import { ORDER_APPROVAL, ORDER_PAYMENT, ORDER_SUBMIT } from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';
import {
  Select,
  StackView,
  StackWrap,
  Typography,
  Divider,
  TextInput,
  TextArea,
  TextButton,
  Button,
  Box,
  Paragraph,
} from '@telus-uds/components-web';
import { HistoryContext } from 'context/HistoryContext';
import { UserContext } from 'context/UserContext';
import { exampleTermsAndConditions } from 'context/exampleUserData';

const EnduserPaymentPageContainer = styled.div`
  width: 100%;
  position: relative;
  // min-height: 100vh;
  // padding-bottom: 208px;

  @media screen and (min-width: 1008px) {
  }
`;

const SummaryContainer = styled.div`
  position: relative;

  @media screen and (min-width: 1008px) {
    position: sticky;
    top: 40px;
    height: fit-content;
    width: 100%;
  }
`;

const PageNavContainer = styled.div`
  overflow: hidden;
`;

const PanelContainer = styled.div`
  > div > div:first-child {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
  }
`;

const PanelControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-right: 1rem;

  > div:first-of-type {
    width: inherit;
  }
`;

const ButtonWrap = styled.div`
  display: block;
  // position: absolute;
  // bottom: 0;
  // right: 0;
  padding: 0 1rem;
`;

const TermsAndConditionsContainer = styled.div`
  border: 1px solid black;
  > div {
    max-height: calc(90vh - 300px);
  }
`;

const ApproveButtons = ({ order, active, onApprove }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    // alert(`Order ${order.orderNumber} rejected`);
    // setReject(true);
    navigate(ORDER_APPROVAL);
  };

  const handleBoxScroll = () => {
    const box = document.getElementById('scroll-box-container').firstChild;
    if (box.scrollHeight - box.scrollTop - box.clientHeight < 30) {
      setScrollToBottom(true);
    }
  };

  return (
    <StackWrap tokens={{ justifyContent: 'flex-end' }}>
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton
            onPress={() => handleCancel()}
            variant={{ alternative: true }}
          >
            Cancel
          </TextButton>
        </StackView>
        <Spacer space={4} direction="row" />
        <Button
          onPress={() => setModalOpen(true)}
          variant={{ priority: 'high' }}
          tokens={{ fontWeight: 400 }}
          inactive={!active}
        >
          Continue
        </Button>
      </StackWrap>
      <BaseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Box space={4}>
          <Typography variant={{ size: 'h2' }} tokens={{ fontWeight: '300' }}>
            Accept terms & conditions
          </Typography>
          <Spacer space={10} />
          <TermsAndConditionsContainer id="scroll-box-container">
            <Box
              vertical={4}
              horizontal={9}
              scroll={{
                onScroll: () => handleBoxScroll(),
                scrollEventThrottle: 0,
              }}
            >
              {/* {cartInfo.termsAndConditions.termsTitle ? (
                <>
                  <Typography variant={{ size: 'h3', colour: 'secondary' }}>
                    {cartInfo.termsAndConditions.termsTitle}
                  </Typography>
                  <Spacer space={3} />
                </>
              ) : (
                ''
              )} */}
              {exampleTermsAndConditions.map((term, i) => (
                <div key={i}>
                  <Paragraph>{term}</Paragraph>
                  <Spacer space={4} />
                </div>
              ))}
            </Box>
          </TermsAndConditionsContainer>
          <Spacer space={5} />
          <StackWrap tokens={{ justifyContent: 'flex-end' }}>
            <Button onPress={() => setModalOpen(false)}>Go back</Button>
            <Spacer space={3} direction="row" />
            <Button
              variant={{ priority: 'high' }}
              inactive={!scrollToBottom}
              onPress={onApprove}
            >
              I accept, continue
            </Button>
          </StackWrap>
        </Box>
      </BaseModal>
    </StackWrap>
  );
};

const EnduserPaymentPage = () => {
  const {
    deviceOptionsSet,
    optionsTracker,
    initializeOptionsTracker,
    setCurrentStep,
    steps,
    setOrderInfo,
  } = useContext(DeviceContext);
  const { orderDetails, setOrderApproverComments, setOrderPaymentDetails } =
    useContext(HistoryContext);
  const { paymentOptions, updateBan, updatePayment, updatePcNumber } =
    useContext(UserContext);
  const [open, setOpen] = useState([null]);
  const [formComplete, setFormComplete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log('optionsTracker: ', optionsTracker);
    // console.log('optionsTracker.length: ', Object.keys(optionsTracker).length);
    if (Object.keys(optionsTracker).length < 1) {
      // console.log('setting options tracker');
      initializeOptionsTracker(null, exampleEnduserPaymentOptions);
    }
    if (steps) {
      let thisStep = parseInt(
        Object.keys(steps).find((key) => steps[key].link === ORDER_PAYMENT)
      );
      setCurrentStep(thisStep);
    }
    setOrderInfo(exampleByodOrderInfo);
  }, []);

  useEffect(() => {
    console.log('deviceOptionsSet: ', deviceOptionsSet);
    if (deviceOptionsSet) {
      setOpen(['payment-options']);
    }
  }, [deviceOptionsSet]);

  useEffect(() => {
    if (deviceOptionsSet && paymentOptions.payment) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  }, [paymentOptions, deviceOptionsSet]);

  const checkChecked = () => {
    if (paymentOptions.payment) {
      return {
        iconBackgroundColor: palette.color.greenAccessible,
        inputBorderRadius: '999px',
        inputBorderWidth: '1px',
        inputBorderColor: palette.color.greenAccessible,
      };
    }
    return {
      iconBackgroundColor: palette.color.white,
      iconColor: palette.color.greyCloud,
      inputBorderRadius: '999px',
      inputBorderWidth: '1px',
      inputBorderColor: palette.color.greyCloud,
    };
  };

  const handleApprove = () => {
    // alert(`Order ${order.orderNumber} approved`);
    // setReject(false);
    console.log(paymentOptions);
    setOrderPaymentDetails([
      { title: 'Account', value: paymentOptions.ban },
      {
        title: 'Payment method',
        value: exampleEnduserPaymentOptions.paymentOptions.options.find(
          (obj) => obj.id === paymentOptions.payment
        ).title,
      },
    ]);
    navigate(ORDER_SUBMIT);
  };

  return (
    <EnduserPaymentPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={8} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            {/* {window.location.hash
                ? `Order # ${window.location.hash.substring(1)}`
                : 'no order loaded'} */}
            Order # {orderDetails.orderNumber}
          </Typography>
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <FlexGrid limitWidth={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={4}>
            <SummaryContainer>
              <OrderSummary />
            </SummaryContainer>
            <Spacer space={6} />
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} lg={8}>
            {/* {paymentOptions.ban ? ( */}
            <Select
              label="Select BAN"
              placeholder="Please select..."
              value={paymentOptions.ban}
              onChange={(id) => updateBan(id)}
            >
              {exampleEnduserPaymentOptions.ban.map((number, i) => (
                <Select.Item value={number} key={i}>
                  {number}
                </Select.Item>
              ))}
            </Select>
            {/* ) : null} */}
            <Spacer space={8} />
            {paymentOptions.ban ? (
              <PageNavContainer>
                <Accordion
                  // deviceOptions={exampleEnduserPaymentOptions.deviceOptions}
                  sections={exampleEnduserPaymentOptions.additionalOptions}
                />
                <Divider variant={{ decorative: true }} />
                <ExpandCollapse open={open} onChange={setOpen} maxOpen={1}>
                  {(expandProps) => (
                    <StackView divider={{ variant: { decorative: true } }}>
                      <PanelContainer>
                        <ExpandCollapse.Panel
                          {...expandProps}
                          panelId={
                            exampleEnduserPaymentOptions.paymentOptions.id
                          }
                          control={
                            <PanelControlContainer>
                              <StackView tokens={{ flexShrink: 'unset' }}>
                                <StackWrap
                                  tokens={{ justifyContent: 'space-between' }}
                                >
                                  <StackView
                                    space={{ xs: 3 }}
                                    tokens={{ alignItems: 'center' }}
                                    direction="row"
                                  >
                                    <Checkbox
                                      checked={true}
                                      onChange={() =>
                                        console.log('check check')
                                      }
                                      tokens={checkChecked(
                                        exampleEnduserPaymentOptions
                                          .paymentOptions.id
                                      )}
                                    />
                                    <Typography
                                      variant={{
                                        size: 'h2',
                                        colour: 'secondary',
                                      }}
                                    >
                                      Payment options
                                    </Typography>
                                  </StackView>
                                </StackWrap>
                              </StackView>
                            </PanelControlContainer>
                          }
                        >
                          <Spacer space={6} />
                          <Select
                            label={
                              exampleEnduserPaymentOptions.paymentOptions.title
                            }
                            placeholder="Please select..."
                            value={paymentOptions.payment}
                            onChange={(id) => updatePayment(id)}
                          >
                            {exampleEnduserPaymentOptions.paymentOptions.options.map(
                              (option, i) => (
                                <Select.Item value={option.id} key={i}>
                                  {option.title}
                                </Select.Item>
                              )
                            )}
                          </Select>
                          <Spacer space={4} />
                          <TextInput
                            label="Enter PC Number that will be displayed in your TELUS IQ order reports and order history (optional)"
                            value={paymentOptions.pcNumber}
                            onChange={updatePcNumber}
                          />
                          <Spacer space={6} />
                        </ExpandCollapse.Panel>
                        <Divider variant={{ decorative: true }} />
                        <ExpandCollapse.Panel
                          {...expandProps}
                          panelId="comments"
                          control={
                            <PanelControlContainer>
                              <StackView tokens={{ flexShrink: 'unset' }}>
                                <StackWrap
                                  tokens={{ justifyContent: 'space-between' }}
                                >
                                  <StackView
                                    space={{ xs: 3 }}
                                    tokens={{ alignItems: 'center' }}
                                    direction="row"
                                  >
                                    <Typography
                                      variant={{
                                        size: 'h2',
                                        colour: 'secondary',
                                      }}
                                    >
                                      Comments
                                    </Typography>
                                  </StackView>
                                </StackWrap>
                              </StackView>
                            </PanelControlContainer>
                          }
                        >
                          <Spacer space={6} />
                          <TextArea
                            label="Approver's comments"
                            value={orderDetails.approverComments}
                            onChange={setOrderApproverComments}
                          />
                          <Spacer space={6} />
                        </ExpandCollapse.Panel>
                        <Divider variant={{ decorative: true }} />
                      </PanelContainer>
                    </StackView>
                  )}
                </ExpandCollapse>
              </PageNavContainer>
            ) : null}
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
      <ButtonWrap>
        <Spacer space={10} />
        <ApproveButtons
          order={orderDetails}
          active={formComplete}
          onApprove={handleApprove}
        />
        <Spacer space={10} />
      </ButtonWrap>
    </EnduserPaymentPageContainer>
  );
};

export default EnduserPaymentPage;
