import {
  Typography,
  FlexGrid,
  Spacer,
  Tabs,
  StackWrap,
  StackView,
  Button,
  TextButton,
} from '@telus-uds/ds-allium';
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { exampleDeviceCategories } from 'context/exampleDeviceData';
import SelectDeviceCard from 'components/SelectDeviceCard';
import { useNavigate } from 'react-router-dom';
import { USER_SETTINGS } from 'constants/routes';
import { UserContext } from 'context/UserContext';

const DisplayDevicesPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const DisplayDevicesPage = () => {
  const { displayDevices, selectedDevices, setSelectedDevices } =
    useContext(UserContext);
  const [localSelectedDevices, setLocalSelectedDevices] = useState(null);
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat) {
      setCategory(urlcat);
    } else {
      setCategory('all');
    }
  }, []);

  useEffect(() => {
    setLocalSelectedDevices(selectedDevices);
  }, [selectedDevices]);

  const handleSelect = (optionId) => {
    let newLocalSelectedDevices = { ...localSelectedDevices };
    newLocalSelectedDevices[optionId] = !newLocalSelectedDevices[optionId];
    setLocalSelectedDevices(newLocalSelectedDevices);
  };

  const handleCategoryChange = () => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat !== category) {
      setCategory(urlcat);
      // alert(`Device category changed to ${urlcat}`);
    }
  };

  const handleCancel = () => {
    navigate(USER_SETTINGS);
  };

  const handleSave = () => {
    setSelectedDevices(localSelectedDevices);
    navigate(USER_SETTINGS);
  };

  return (
    <DisplayDevicesPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={6} />
      <FlexGrid limitWidth={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={12}>
            <Typography block variant={{ size: 'h1' }}>
              Choose which devices to display
            </Typography>
            <Spacer space={4} />
            <Typography block>
              Select the devices to display in your product catalogue
            </Typography>
            <Spacer space={6} />
            <Tabs
              items={exampleDeviceCategories}
              itemTokens={{ maxWidth: '300px' }}
              initialValue={category}
              onChange={() => handleCategoryChange()}
            />
            <Spacer space={2} />
            {localSelectedDevices && (
              <FlexGrid.Row>
                {displayDevices.map((device, i) => (
                  <FlexGrid.Col
                    xs={12}
                    sm={6}
                    lg={4}
                    xl={3}
                    key={i}
                    flex={true}
                  >
                    <SelectDeviceCard
                      deviceInfo={device}
                      optionSelect={handleSelect}
                      selected={localSelectedDevices}
                    />
                  </FlexGrid.Col>
                ))}
              </FlexGrid.Row>
            )}
            <Spacer space={10} />
            <StackWrap tokens={{ justifyContent: 'flex-end' }}>
              <StackView tokens={{ justifyContent: 'center' }}>
                <TextButton onPress={handleCancel}>Cancel</TextButton>
              </StackView>
              <Spacer space={5} direction="row" />
              <Button onPress={handleSave} variant={{ priority: 'high' }}>
                Save
              </Button>
            </StackWrap>
            <Spacer space={10} />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </DisplayDevicesPageContainer>
  );
};

export default DisplayDevicesPage;
