import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Chevron from 'components/Chevron';
import {
  Radio,
  Search,
  Spacer,
  TextButton,
  StackWrap,
  StackView,
} from '@telus-uds/ds-allium';
import styled from 'styled-components';

const SearchBarContainer = styled.div`
  ${(props) => (props.expanded ? 'width: 65%' : 'width: 50%')};
  margin-left: ${(props) => (props.approvalStyle ? '0' : 'auto')};

  > div > div:first-child {
    z-index: 100;
  }
`;

const LevelSelection = styled.div`
  width: 7rem;
  margin-right: 2rem;
  position: relative;
  display: flex;
  align-self: center;

  > div > div {
    display: flex;
  }

  > button.btn-level-selection {
    background-color: rgba($color: #000000, $alpha: 0);
    border: none;
    width: 100%;
    padding: 14px;
    position: relative;
    right: 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .dropdown__chevron-icon.up {
      transform: rotate(180deg);
    }
  }
`;

const SearchInputContainer = styled.div`
  ${(props) => (props.approvalStyle ? 'width: 360px;' : 'flex-grow: 1;')}
`;

const FilterSelectionContainer = styled.div`
  ${(props) => (props.approvalStyle ? 'padding-right:' : 'padding-left:')} 9rem;
  padding-top: 3rem;
  display: flex;
  flex: row;
  flex-wrap: wrap;

  > div {
    width: 33%;
    min-width: 13rem;
    margin-bottom: 2rem;
  }
`;

const AutoCompleteContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  position: relative;
  padding-left: 15px;

  input {
    border: none;
    height: 100%;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .auto-complete-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 0px 16px rgba(213, 213, 213, 0.5);
    border-radius: 0px 0px 4px 4px;
    list-style-type: none;
    padding: 0;

    &.hide {
      display: none;
    }

    button.btn-auto-complete {
      border: none;
      background-color: white;
      padding: 15px 24px;
      width: 100%;
      text-align: left;
      border-radius: 4px;
      position: relative;
      right: 0;
      font-size: 14px;
      line-height: 20px;
      font-family: HelveticaNow400normal;

      .matched-term {
        font-weight: bold;
      }

      &:hover {
        background-color: #f7f7f8;
      }
    }
  }
`;

const SearchBar = ({
  filterOptions,
  searchTerm,
  searchTermUpdated,
  autoCompleteItems,
  onAutoCompleteSelected,
  onSearch,
  chosenFilter,
  updateFilter,
  approvalStyle,
}) => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showAutoCompleteDropdown, setShowAutoCompleteDropdown] =
    useState(false);
  const searchRef = useRef();

  useEffect(() => {
    console.log(showFilterDropdown);
  }, [showFilterDropdown]);

  useEffect(() => {
    if (searchTerm.length > 1 && autoCompleteItems.length) {
      setShowAutoCompleteDropdown(true);
    } else {
      setShowAutoCompleteDropdown(false);
    }
  }, [searchTerm, autoCompleteItems]);

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
    setShowAutoCompleteDropdown(false);
  };

  const autoCompleteItemSelected = (item) => {
    console.log('hi');
    setShowAutoCompleteDropdown(false);
    onAutoCompleteSelected(item);
    updateSearchTerm(item);
  };

  const highlightMatch = (item) => {
    const splitted = item
      .split(searchTerm)
      .map((m, i) => <span key={i}>{m}</span>);
    const joined = [].concat(
      ...splitted.map((n) => [
        n,
        <span className="matched-term" key={n}>
          {searchTerm}
        </span>,
      ])
    );

    return joined.slice(0, -1);
  };

  const updateSearchTerm = (evt) => {
    console.log(evt);
    const value = evt;
    searchTermUpdated(value);
    if (value.length > 1 && autoCompleteItems.length) {
      setShowAutoCompleteDropdown(true);
    } else {
      setShowAutoCompleteDropdown(false);
    }
  };

  const startSearch = () => {
    setShowAutoCompleteDropdown(false);
    onSearch();
  };

  return (
    <SearchBarContainer
      expanded={showFilterDropdown}
      approvalStyle={approvalStyle}
    >
      <StackView>
        <StackWrap
          direction={approvalStyle ? 'row-reverse' : 'row'}
          tokens={
            approvalStyle
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-start' }
          }
        >
          {approvalStyle ? (
            chosenFilter ? (
              <StackView tokens={{ justifyContent: 'center' }}>
                <TextButton
                  onPress={() => updateFilter(null)}
                  variant={{ alternative: true }}
                >
                  Clear 1 filter
                </TextButton>
              </StackView>
            ) : (
              ''
            )
          ) : null}
          {filterOptions.length > 0 && (
            <LevelSelection>
              <TextButton
                onPress={toggleFilterDropdown}
                variant={{ alternative: true }}
              >
                Advanced
                <Spacer space={3} direction="row" />
                <div
                  className={`dropdown__chevron-icon ${
                    showFilterDropdown ? 'up' : ''
                  }`}
                >
                  <Chevron
                    direction={showFilterDropdown ? 'up' : 'down'}
                    color="#000000"
                    width="12"
                    height="8"
                    className="dropdown__button__chevron"
                  />
                </div>
              </TextButton>
            </LevelSelection>
          )}
          {approvalStyle ? <Spacer space={5} direction="row" /> : null}
          <SearchInputContainer approvalStyle={approvalStyle}>
            <Search
              placeholder="Search orders"
              value={searchTerm}
              onChange={updateSearchTerm}
              ref={searchRef}
              onSubmit={startSearch}
            />
            <AutoCompleteContainer>
              <ul
                className={`auto-complete-dropdown ${
                  showAutoCompleteDropdown ? '' : 'hide'
                }`}
              >
                {autoCompleteItems.map((item) => (
                  <li key={item.value}>
                    <button
                      type="button"
                      className="btn-auto-complete"
                      onClick={() => autoCompleteItemSelected(item.value)}
                    >
                      {highlightMatch(item.value).map((a) => a)}
                    </button>
                  </li>
                ))}
              </ul>
            </AutoCompleteContainer>
          </SearchInputContainer>
        </StackWrap>
        {showFilterDropdown ? (
          <FilterSelectionContainer approvalStyle={approvalStyle}>
            {filterOptions.map((option, i) => (
              <Radio
                key={i}
                label={option.label}
                id={option.id}
                checked={chosenFilter === option.id}
                onChange={() => updateFilter(option.id)}
                name="search-filter"
              />
            ))}
          </FilterSelectionContainer>
        ) : (
          ''
        )}
        {!approvalStyle ? (
          chosenFilter ? (
            <StackWrap direction="row-reverse">
              <TextButton
                onPress={() => updateFilter(null)}
                variant={{ alternative: true }}
              >
                Clear 1 filter
              </TextButton>
            </StackWrap>
          ) : (
            ''
          )
        ) : null}
      </StackView>
    </SearchBarContainer>
  );
};

SearchBar.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.object),
  searchTerm: PropTypes.string.isRequired,
  searchTermUpdated: PropTypes.func.isRequired,
  autoCompleteItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAutoCompleteSelected: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onlySearch: PropTypes.bool,
  searchHeader: PropTypes.bool,
};

SearchBar.defaultProps = {
  filterOptions: [],
  placeholder: '',
  onlySearch: false,
  searchHeader: true,
};

export default SearchBar;
