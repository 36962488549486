import React, { useState, useEffect } from 'react';

import { TextInput } from '@telus-uds/ds-allium';

const TextField = ({
  label,
  value,
  required,
  onchange,
  tooltip,
  feedback,
  validation,
}) => {
  const [valid, setValidation] = useState(validation);
  const [newValue, setNewValue] = useState(value);
  const [touched, setTouched] = useState(false);
  const defaultFeedbackContent = '';
  const TextRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

  const feedbackContent = {
    error: `The value must only letters and numbers.`,
    success: '',
  };

  useEffect(() => {
    if (
      !validation &&
      required &&
      newValue !== undefined &&
      newValue.length > 0
    ) {
      if (TextRegex.test(newValue)) {
        onchange(newValue);
        setValidation('success');
      } else {
        setValidation('error');
      }
    }
  }, [newValue]);

  useEffect(() => {
    if (
      !required &&
      touched &&
      !validation &&
      (newValue === undefined || newValue.length === 0)
    ) {
      onchange('');
      setValidation('success');
    }
  }, [touched]);

  const getFeedback = () => {
    if (!validation && !feedback) {
      return valid ? feedbackContent[valid] : defaultFeedbackContent;
    }
    return feedback;
  };

  const checkValue = (val, e) => {
    const tempValue = val.replace(/[^a-zA-Z0-9 ]/g, '');
    setNewValue(tempValue);
  };

  return (
    <TextInput
      label={label}
      onChange={(val, e) => checkValue(val, e)}
      value={newValue}
      feedback={getFeedback()}
      validation={valid}
      tooltip={tooltip}
      onBlur={() => setTouched(true)}
    />
  );
};

export default TextField;
