import {
  FlexGrid,
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Icon,
  Box,
  Checkbox,
  List,
  RadioCardGroup,
  Paragraph,
  Select,
} from '@telus-uds/components-web';
import { BaseModal } from '@telus-uds/ds-allium';
import {
  CaretDown,
  CaretUp,
  Check,
} from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubscribersContext } from 'context/SubscribersContext';
import palette from '@telus-uds/palette-allium';
import { DeviceContext } from 'context/DeviceContext';

const OptionsContainer = styled.div`
  display: block;
  > fieldset > div > div > div > div > div:last-child {
    flex-grow: 1 !important;
  }
`;

const OptionCard = styled.div`
  flex: 1;
`;

const OptionsSection = ({ options, setOption }) => {
  const [items, setItems] = useState([]);
  const [showOverflow, setShowOverflow] = useState(false);
  const [checkedId, setCheckedId] = useState('');

  const formatOptions = () => {
    let newOptions = [];
    options.options.map((option, i) => {
      i < 3 || showOverflow
        ? (newOptions[i] = {
            title: '',
            id: option.id,
            content: (
              <OptionCard>
                <FlexGrid gutter={false}>
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={12} md={8} xl={9}>
                      <StackView>
                        <Spacer space={{ options: { size: 1 } }} />
                        <Typography block variant={{ bold: true }}>
                          {option.title}
                        </Typography>
                        {option.copy ? (
                          <Typography>{option.copy}</Typography>
                        ) : (
                          ''
                        )}
                        {option.greenText ? (
                          <>
                            <Spacer space={2} />
                            <Typography
                              tokens={{ color: palette.color.greenAccessible }}
                            >
                              {option.greenText}
                            </Typography>
                          </>
                        ) : (
                          ''
                        )}
                        {option.checklist ? (
                          <>
                            <Spacer space={2} />
                            {option.checklist.map((checklistItem, i) => (
                              <FlexGrid key={i} gutter={false}>
                                <FlexGrid.Row>
                                  <FlexGrid.Col xs={1}>
                                    <Icon
                                      icon={Check}
                                      tokens={{
                                        color: palette.color.greenAccessible,
                                      }}
                                    />
                                  </FlexGrid.Col>
                                  <FlexGrid.Col xs={11}>
                                    <Paragraph block>{checklistItem}</Paragraph>
                                  </FlexGrid.Col>
                                </FlexGrid.Row>
                              </FlexGrid>
                            ))}
                          </>
                        ) : (
                          ''
                        )}
                        {option.details ? (
                          <ExpandCollapseMini
                            variant={{ alternative: true }}
                            expandTitle="Hide details"
                            collapseTitle="View details"
                          >
                            {option.details.map((detail, i) => (
                              <Typography key={i}>{detail}</Typography>
                            ))}
                          </ExpandCollapseMini>
                        ) : (
                          ''
                        )}
                      </StackView>
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={12} md={4} xl={3}>
                      <StackView>
                        {option.cost ? (
                          <StackWrap
                            space={0}
                            tokens={{
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Typography>$ </Typography>
                            <Typography
                              variant={{ size: 'h2', colour: 'secondary' }}
                            >
                              {option.cost.toFixed(2)}
                            </Typography>
                            {option.costPer ? (
                              <StackView>
                                <Spacer space={{ xs: 1, lg: 2 }} />
                                <Typography>/{option.costPer}</Typography>
                              </StackView>
                            ) : (
                              ''
                            )}
                          </StackWrap>
                        ) : (
                          ''
                        )}
                      </StackView>
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </FlexGrid>
              </OptionCard>
            ),
          })
        : '';
    });
    setItems(newOptions);
  };

  useEffect(() => {
    formatOptions();
  }, [showOverflow]);

  const handleChange = (id) => {
    setCheckedId(id);
    setOption(options.id, id);
  };

  return (
    <OptionsContainer>
      <RadioCardGroup
        items={items}
        checkedId={checkedId}
        tokens={{ direction: 'column' }}
        radioCardTokens={{ paddingBottom: '12px', paddingTop: '12px' }}
        onChange={handleChange}
      />
      {options.options.length > 3 ? (
        <>
          <Spacer space={3} />
          <TextButton
            onPress={() => setShowOverflow(!showOverflow)}
            tokens={{ color: '#4B286D' }}
          >
            {showOverflow ? 'Show less options' : 'Show all options'}
            <Spacer space={2} direction="row" />
            {showOverflow ? (
              <Icon
                icon={CaretUp}
                variant={{ size: 'small', rank: 'primary' }}
                tokens={{ translateY: '4' }}
              />
            ) : (
              <Icon
                icon={CaretDown}
                variant={{ size: 'small', rank: 'primary' }}
                tokens={{ translateY: '4' }}
              />
            )}
          </TextButton>
        </>
      ) : (
        ''
      )}
    </OptionsContainer>
  );
};

const ChooseBundleModal = ({ active, configurationData, handleChoose }) => {
  const { userInfo } = useContext(DeviceContext);
  const { selectedBundle, bundleData } = useContext(SubscribersContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [chosenOptions, setChosenOptions] = useState([]);
  const [buttonInactive, setButtonInactive] = useState(true);
  const [showExtendedInfo, setShowExtendedInfo] = useState(false);
  const [bundleType, setBundleType] = useState(null);
  const [selectedBan, setSelectedBan] = useState(null);

  useEffect(() => {
    let newOptions = [];
    if (configurationData.configurations) {
      configurationData.configurations.map((config) => {
        newOptions.push({ id: config.id, selection: null });
      });
    }
    setChosenOptions(newOptions);
  }, []);

  useEffect(() => {
    if (selectedBundle) {
      setBundleType(bundleData.find((obj) => obj.id === selectedBundle).type);
    } else {
      setBundleType(null);
    }
    console.log('bundleType: ', bundleType);
  }, [active]);

  useEffect(() => {
    if (bundleType === 'byod' && selectedBan) {
      setButtonInactive(false);
    } else {
      if (checked) {
        if (chosenOptions.length) {
          if (chosenOptions.some((option) => option.selection === null)) {
            setButtonInactive(true);
          } else {
            setButtonInactive(false);
          }
        } else {
          setButtonInactive(false);
        }
      } else {
        if (
          chosenOptions.find((obj) => obj.id === 'extended-warranty') &&
          chosenOptions.find((obj) => obj.id === 'extended-warranty')
            .selection === 'no-warranty'
        ) {
          setButtonInactive(false);
        } else {
          setButtonInactive(true);
        }
      }
    }
    if (chosenOptions.length) {
      if (chosenOptions.some((option) => option.selection === null)) {
        setShowExtendedInfo(false);
      } else if (
        chosenOptions.find((obj) => obj.id === 'extended-warranty')
          .selection === 'no-warranty'
      ) {
        setShowExtendedInfo(false);
      } else {
        setShowExtendedInfo(true);
      }
    } else {
      setShowExtendedInfo(false);
    }
  }, [checked, chosenOptions, selectedBan]);

  const handleSave = () => {
    if (handleChoose) {
      handleChoose();
    } else {
      alert(`Saving order for: ${selectedBundle}`);
      console.log('chosen options for ', selectedBundle, ': ', chosenOptions);
    }
  };

  const setOption = (optionId, selection) => {
    let newOptions = [...chosenOptions];
    newOptions.find((option) => option.id === optionId).selection = selection;
    setChosenOptions(newOptions);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setSelectedBan(null);
    let newChosenOptions = [...chosenOptions];
    for (let i = 0; i < chosenOptions.length; i++) {
      newChosenOptions[i].selection = null;
    }
    setChosenOptions(newChosenOptions);
  };

  return (
    <>
      <Button
        onPress={() => setModalOpen(true)}
        variant={{ priority: 'high' }}
        tokens={{ fontWeight: 400 }}
        inactive={!active}
      >
        Choose Bundle
      </Button>
      <BaseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Box space={3}>
          <Typography block variant={{ size: 'h1' }}>
            Select your configuration
          </Typography>
          {/* {bundleType === 'byod' || bundleType === 'upgrade' ? ( */}
          <Box vertical={5}>
            <Select
              label="Select BAN"
              placeholder="Please select..."
              value={selectedBan}
              onChange={setSelectedBan}
            >
              {userInfo.accounts.map((account, i) => (
                <Select.Item
                  value={`${account}`}
                  key={i}
                >{`${account}`}</Select.Item>
              ))}
            </Select>
          </Box>
          {/* ) : null} */}
          {(bundleType === 'new' || bundleType === 'upgrade') && selectedBan ? (
            <Box vertical={5}>
              {configurationData.configurations
                ? configurationData.configurations.map((config, i) => (
                    <React.Fragment key={i}>
                      <Typography>{config.instructions}</Typography>
                      <Spacer space={6} />
                      <OptionsSection options={config} setOption={setOption} />
                    </React.Fragment>
                  ))
                : null}
            </Box>
          ) : null}
          <Spacer space={5} />
          {showExtendedInfo ? (
            <>
              {configurationData.confirmation ? (
                <Checkbox
                  label={configurationData.confirmation}
                  tokens={{ labelFontWeight: 700 }}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              ) : null}
              <Spacer space={5} />
              {configurationData.information ? (
                <List tokens={{ interItemMargin: 30 }}>
                  {configurationData.information.map((info, i) => (
                    <List.Item key={i}>{info}</List.Item>
                  ))}
                </List>
              ) : null}
              <Spacer space={5} />
            </>
          ) : null}
          <Box vertical={3}>
            <StackWrap
              space={6}
              direction="row-reverse"
              tokens={{ alignItems: 'center' }}
            >
              <Button
                variant={{ priority: 'high' }}
                onPress={() => handleSave()}
                inactive={buttonInactive}
              >
                Save
              </Button>
              <StackView>
                <TextButton
                  variant={{ alternative: true }}
                  onPress={() => handleCancel()}
                >
                  Cancel
                </TextButton>
              </StackView>
            </StackWrap>
          </Box>
        </Box>
      </BaseModal>
    </>
  );
};

export default ChooseBundleModal;
