import {
  Checkbox,
  ExpandCollapse,
  StackView,
  StackWrap,
  Typography,
  Spacer,
  Divider,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import OptionsSection from './OptionsSection';
import DeviceOptions from './DeviceOptions';
import { DeviceContext } from 'context/DeviceContext/DeviceContext';

const PanelContainer = styled.div`
  > div > div:first-child {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
  }
`;

const PanelControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-right: 1rem;

  > div:first-of-type {
    width: inherit;
  }
`;

const Accordion = ({ sections, deviceOptions }) => {
  const { optionsTracker } = useContext(DeviceContext);
  const [open, setOpen] = useState([null]);

  useEffect(() => {
    setOpen([sections[0].id]);
    // console.log('sections: ', sections);
  }, []);

  useEffect(() => {
    // console.log('optionsTracker: ', optionsTracker);
    // console.log('open: ', open);
    // console.log('sections: ', sections);
    if (optionsTracker.additionalOptions) {
      if (
        !optionsTracker.deviceOptions ||
        optionsTracker.deviceOptions.every((item) => item.selection)
      ) {
        if (optionsTracker.additionalOptions) {
          for (var i = 0; i < sections.length; i++) {
            console.log(sections[i].id);
            if (
              optionsTracker.additionalOptions.find((obj) => {
                return obj.id === sections[i].id;
              })
            ) {
              if (
                optionsTracker.additionalOptions.find((obj) => {
                  return obj.id === sections[i].id;
                }).selection
              ) {
                if (sections[i].id === 'rate-plan-options') {
                  setOpen([
                    'warranty-options',
                    'dep-options',
                    'add-ons-options',
                  ]);
                  break;
                } else {
                  console.log('nevermind');
                }
              } else {
                setOpen([`${sections[i].id}`]);
                break;
              }
              setOpen([]);
            }
          }
        } else {
          setOpen([]);
        }
      } else {
        setOpen(['device-options']);
      }
    }
  }, [optionsTracker]);

  const checkChecked = (stage) => {
    if (optionsTracker.additionalOptions) {
      if (stage === 'device-options') {
        if (optionsTracker.deviceOptions) {
          if (optionsTracker.deviceOptions.every((item) => item.selection)) {
            return {
              iconBackgroundColor: palette.color.greenAccessible,
              inputBorderRadius: '999px',
              inputBorderWidth: '1px',
              inputBorderColor: palette.color.greenAccessible,
            };
          }
        }
      } else {
        if (
          optionsTracker.additionalOptions.find(
            (element) => element.id === stage
          ).selection
        ) {
          return {
            iconBackgroundColor: palette.color.greenAccessible,
            inputBorderRadius: '999px',
            inputBorderWidth: '1px',
            inputBorderColor: palette.color.greenAccessible,
          };
        }
      }
    }
    return {
      iconBackgroundColor: palette.color.white,
      iconColor: palette.color.greyCloud,
      inputBorderRadius: '999px',
      inputBorderWidth: '1px',
      inputBorderColor: palette.color.greyCloud,
    };
  };

  return (
    <>
      <Divider variant={{ decorative: true }} />
      <ExpandCollapse
        open={open}
        onChange={setOpen}
        // maxOpen={1}
      >
        {(expandProps) => (
          <StackView divider={{ variant: { decorative: true } }}>
            {sections.map((section, i) => (
              <PanelContainer key={i}>
                <ExpandCollapse.Panel
                  {...expandProps}
                  panelId={section.id}
                  key={section.id}
                  control={
                    <PanelControlContainer>
                      <StackView tokens={{ flexShrink: 'unset' }}>
                        <StackWrap
                          // direction="row"
                          tokens={{ justifyContent: 'space-between' }}
                        >
                          <StackView
                            space={{ xs: 3 }}
                            tokens={{ alignItems: 'center' }}
                            direction="row"
                          >
                            <Checkbox
                              checked={true}
                              // inactive={true}
                              onChange={() => console.log('check check')}
                              tokens={checkChecked(section.id)}
                            />
                            <Typography
                              variant={{ size: 'h2', colour: 'secondary' }}
                              // tokens={colorCheck(i)}
                            >
                              {section.title}
                            </Typography>
                          </StackView>
                        </StackWrap>
                      </StackView>
                    </PanelControlContainer>
                  }
                >
                  {section.id !== 'device-options' ? (
                    <Spacer space={6} />
                  ) : (
                    <Spacer space={2} />
                  )}
                  {section.instructions ? (
                    <Typography block variant={{ bold: true }}>
                      {section.instructions}
                    </Typography>
                  ) : (
                    <Spacer space={1} />
                  )}
                  <Spacer space={3} />
                  {section.id === 'device-options' && deviceOptions ? (
                    <DeviceOptions options={deviceOptions} />
                  ) : section.options ? (
                    <OptionsSection options={section} />
                  ) : (
                    <Spacer space={1} />
                  )}
                  <Spacer space={6} />
                </ExpandCollapse.Panel>
              </PanelContainer>
            ))}
          </StackView>
        )}
      </ExpandCollapse>
    </>
  );
};

export default Accordion;
