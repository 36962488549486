import React, { useState, useEffect } from 'react';

import { TextInput } from '@telus-uds/ds-allium';

const PhoneField = ({ label, data, onchange }) => {
  const [validation, setValidation] = useState();
  const [newValue, setNewValue] = useState(data);
  const defaultFeedbackContent = '';

  const feedbackContent = {
    error: `The value must be a 10-digit number`,
    success: '',
  };

  useEffect(() => {
    if (newValue !== undefined && newValue.length > 0) {
      if (/^\([1-9]\d{2}\)\s\d{3}-\d{4}$/i.test(newValue)) {
        onchange(newValue);
        setValidation('success');
      } else {
        onchange('');
        setValidation('error');
      }
    }
  }, [newValue]);

  const feedback =
    validation === undefined
      ? defaultFeedbackContent
      : feedbackContent[validation];

  const normalizeInput = (value, e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    let newValue = '';

    if (value.length > 0) {
      if (cvLength < 4) {
        newValue = currentValue;
      } else if (cvLength < 7) {
        newValue = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      } else {
        newValue = `(${currentValue.slice(0, 3)}) ${currentValue.slice(
          3,
          6
        )}-${currentValue.slice(6, 10)}`;
      }
    }

    if (value.length > 10) {
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    }

    return newValue;
  };

  const checkValue = (value, e) => {
    const formattedNumber = normalizeInput(value, e);
    setNewValue(formattedNumber);
  };

  return (
    <TextInput
      label={label}
      onChange={(value, e) => checkValue(value, e)}
      value={newValue}
      feedback={feedback}
      validation={validation}
    />
  );
};

export default PhoneField;
