import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import palette from '@telus-uds/palette-allium';
import { HistoryContext } from 'context/HistoryContext';
import OrderItem from 'components/OrderItem';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: block;
  max-width: 74px;
  min-width: 24px;
  width: 8%;
`;

const OrderDetails = ({ checkControl, handleCheck }) => {
  const { orderDetails } = useContext(HistoryContext);
  const [overflowControl, setOverflowControl] = useState([]);

  useEffect(() => {
    let newOverflowControl = [];
    orderDetails.devicesAndPlans
      ? orderDetails.devicesAndPlans.map((device) => {
          // console.log(device);
          newOverflowControl.push({
            detailsOpen: false,
            subscribersOpen: false,
          });
        })
      : '';
    // console.log(newOverflowControl);
    setOverflowControl(newOverflowControl);
    // console.log('overflow control: ', overflowControl);
  }, [orderDetails]);

  const toggleDetails = (i) => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl[i].detailsOpen = !newOverflowControl[i].detailsOpen;
    setOverflowControl(newOverflowControl);
  };

  const toggleSubscribers = (i) => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl[i].subscribersOpen =
      !newOverflowControl[i].subscribersOpen;
    setOverflowControl(newOverflowControl);
  };

  const expandAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = true;
      control.subscribersOpen = true;
    });
    setOverflowControl(newOverflowControl);
  };

  const collapseAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = false;
      control.subscribersOpen = false;
    });
    setOverflowControl(newOverflowControl);
  };

  return (
    <>
      {orderDetails.devicesAndPlans ? (
        <StackView>
          <Divider variant={{ decorative: true }} />
          <Spacer space={6} />
          <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
            Devices and plans
          </Typography>
          <Spacer space={8} />
          <FlexGrid limitWidth={false} gutter={false}>
            <FlexGrid.Row>
              {checkControl ? <CheckboxWrapper /> : null}
              <FlexGrid.Col>
                <FlexGrid.Row>
                  {orderDetails.devicesAndPlans.length > 1 ? (
                    <FlexGrid.Col xs={1}>
                      <Typography tokens={{ color: palette.color.greyShuttle }}>
                        Group
                      </Typography>
                    </FlexGrid.Col>
                  ) : null}
                  <FlexGrid.Col
                    xs={orderDetails.devicesAndPlans.length > 1 ? 11 : 12}
                  >
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={5}>
                        <Typography
                          tokens={{ color: palette.color.greyShuttle }}
                        >
                          Item
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={7}>
                        <FlexGrid.Row>
                          <FlexGrid.Col xs={3}>
                            <Typography
                              tokens={{ color: palette.color.greyShuttle }}
                            >
                              Quantity
                            </Typography>
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={3}>
                            <Typography
                              tokens={{ color: palette.color.greyShuttle }}
                            >
                              Due Monthly
                            </Typography>
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={3}>
                            <Typography
                              tokens={{ color: palette.color.greyShuttle }}
                            >
                              Due Now
                            </Typography>
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={3}>
                            <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                              {overflowControl.some(
                                (obj) => obj.detailsOpen === true
                              ) ||
                              overflowControl.some(
                                (obj) => obj.subscribersOpen === true
                              ) ? (
                                <TextButton onPress={collapseAll}>
                                  <Typography
                                    tokens={{
                                      color: palette.color.greyShuttle,
                                    }}
                                  >
                                    Collapse all
                                  </Typography>
                                </TextButton>
                              ) : (
                                <TextButton onPress={expandAll}>
                                  <Typography
                                    tokens={{
                                      color: palette.color.greyShuttle,
                                    }}
                                  >
                                    Expand all
                                  </Typography>
                                </TextButton>
                              )}
                            </StackWrap>
                          </FlexGrid.Col>
                        </FlexGrid.Row>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid.Col>
            </FlexGrid.Row>
            <Spacer space={3} />
            <Divider />
            {orderDetails.devicesAndPlans
              ? orderDetails.devicesAndPlans.map((device, i) =>
                  overflowControl[i] ? (
                    <OrderItem
                      itemOverflow={overflowControl[i]}
                      itemDetails={device}
                      itemNumber={i + 1}
                      setDetailsOpen={() => toggleDetails(i)}
                      setSubscribersOpen={() => toggleSubscribers(i)}
                      key={i}
                      multipleItems={orderDetails.devicesAndPlans.length > 1}
                      checkControl={checkControl}
                      handleCheck={handleCheck}
                    />
                  ) : null
                )
              : null}
            <Spacer space={8} />
            <FlexGrid.Row>
              <FlexGrid.Col
                xs={orderDetails.devicesAndPlans.length > 1 ? 11 : 12}
                xsOffset={orderDetails.devicesAndPlans.length > 1 ? 1 : 0}
              >
                <FlexGrid.Row>
                  <FlexGrid.Col xs={7} xsOffset={5}>
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={2} xsOffset={4}>
                        <Typography>Subtotal</Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography>
                          {orderDetails.subtotal
                            ? `$${orderDetails.subtotal.toFixed(2)}`
                            : null}
                        </Typography>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                    <Spacer space={3} />
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={2} xsOffset={4}>
                        <Typography>Shipping</Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography>
                          {orderDetails.shipping
                            ? `$${orderDetails.shipping.toFixed(2)}`
                            : 'FREE'}
                        </Typography>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                    <Spacer space={3} />
                    {orderDetails.hst ? (
                      <FlexGrid.Row>
                        <FlexGrid.Col xs={2} xsOffset={4}>
                          <Typography>HST</Typography>
                        </FlexGrid.Col>
                        <FlexGrid.Col xs={3}>
                          <Typography>
                            ${orderDetails.hst.toFixed(2)}
                          </Typography>
                        </FlexGrid.Col>
                      </FlexGrid.Row>
                    ) : null}
                    {orderDetails.hst ? <Spacer space={3} /> : null}
                    {orderDetails.total ? (
                      <FlexGrid.Row>
                        <FlexGrid.Col xs={2} xsOffset={4}>
                          <Typography variant={{ bold: true }}>
                            TOTAL
                          </Typography>
                        </FlexGrid.Col>
                        <FlexGrid.Col xs={3}>
                          <Typography variant={{ bold: true }}>
                            ${orderDetails.total.toFixed(2)}
                          </Typography>
                        </FlexGrid.Col>
                      </FlexGrid.Row>
                    ) : null}
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
          <Spacer space={8} />
        </StackView>
      ) : null}
    </>
  );
};

export default OrderDetails;
