import {
  StackView,
  Typography,
  Divider,
  Spacer,
  StackWrap,
  Button,
  FlexGrid,
  Link,
  Tooltip,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { DeviceContext } from 'context/DeviceContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CartPlaceholder = styled.div`
  height: 220px;
`;

const CartContainer = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  background-color: ${palette.color.purpleTelus};
  position: fixed;
  bottom: 0;
`;

const CartLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem 0;
  justify-content: center;
`;

const CircleContainer = styled.div`
  height: 35px;
  min-width: 35px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
`;

const CartFooter = () => {
  const { cartFooterLink, cartFooterData, currentStep, setCurrentStep } =
    useContext(DeviceContext);
  const navigate = useNavigate();

  const handleContinue = () => {
    if (cartFooterLink) {
      navigate(cartFooterLink);
      // setCurrentStep(currentStep + 1);
    } else {
      alert(`linking out - please provide a url`);
    }
  };

  return (
    <CartPlaceholder>
      <CartContainer>
        <CartLayout>
          <FlexGrid gutter={false} limitWidth={false}>
            <FlexGrid.Row verticalAlign="middle" distribute="between">
              <FlexGrid.Col xs={12} md={6}>
                <StackWrap>
                  <StackView>
                    <StackWrap>
                      <Typography variant={{ inverse: true }}>
                        Due now
                      </Typography>
                      <Spacer space={2} />
                      <Tooltip
                        content={cartFooterData.dueNowTooltip}
                        variant={{ inverse: true }}
                      />
                    </StackWrap>
                    <StackWrap space={0}>
                      <StackView>
                        <Spacer space={{ xs: 0, lg: 1 }} />
                        <Typography variant={{ size: 'h2', inverse: true }}>
                          $
                        </Typography>
                      </StackView>
                      <Typography variant={{ size: 'display1', inverse: true }}>
                        {cartFooterData.dueNow.split('.')[0]}
                      </Typography>
                      <StackView>
                        <Spacer space={{ xs: 0, lg: 1 }} />
                        <Typography variant={{ size: 'h2', inverse: true }}>
                          .{cartFooterData.dueNow.split('.')[1]}
                        </Typography>
                      </StackView>
                    </StackWrap>
                  </StackView>
                  <Divider
                    vertical={true}
                    variant={{ inverse: true }}
                    space={6}
                  />
                  <StackView>
                    <StackWrap>
                      <Typography variant={{ inverse: true }}>
                        Due monthly
                      </Typography>
                      <Spacer space={2} />
                      <Tooltip
                        content={cartFooterData.dueMonthlyTooltip}
                        variant={{ inverse: true }}
                      />
                    </StackWrap>
                    <StackWrap space={0}>
                      <StackView>
                        <Spacer space={{ xs: 0, lg: 1 }} />
                        <Typography variant={{ size: 'h2', inverse: true }}>
                          $
                        </Typography>
                      </StackView>
                      <Typography variant={{ size: 'display1', inverse: true }}>
                        {cartFooterData.dueMonthly.split('.')[0]}
                      </Typography>
                      <StackView>
                        <Spacer space={{ xs: 0, lg: 1 }} />
                        <Typography variant={{ size: 'h2', inverse: true }}>
                          .{cartFooterData.dueMonthly.split('.')[1]}
                        </Typography>
                      </StackView>
                    </StackWrap>
                  </StackView>
                </StackWrap>
              </FlexGrid.Col>
              <FlexGrid.Col xs={12} md={6} lg={4} horizontalAlign="right">
                {cartFooterData.poolProgram ? (
                  <StackWrap tokens={{ justifyContent: 'end' }}>
                    <CircleContainer>
                      <Typography variant={{ size: 'large' }}>
                        {cartFooterData.poolProgram}
                      </Typography>
                    </CircleContainer>
                    <Spacer space={3} direction="row" />
                    <Typography
                      variant={{ inverse: true }}
                      tokens={{ lineHeight: '36px' }}
                    >
                      Hardware Pool Program
                    </Typography>
                  </StackWrap>
                ) : cartFooterData.floatBalance ? (
                  <StackWrap tokens={{ justifyContent: 'end' }}>
                    <CircleContainer>
                      <Typography variant={{ size: 'large' }}>
                        {cartFooterData.floatBalance}
                      </Typography>
                    </CircleContainer>
                    <Spacer space={3} direction="row" />
                    <Typography variant={{ inverse: true }}>
                      Float Balance
                    </Typography>
                  </StackWrap>
                ) : (
                  <Spacer space={5} />
                )}
                <Spacer space={5} />
                <StackWrap tokens={{ justifyContent: 'end' }}>
                  <Button
                    onPress={() => handleContinue()}
                    variant={{ priority: 'high', inverse: true }}
                    tokens={{ width: '100%' }}
                  >
                    Continue
                  </Button>
                </StackWrap>
                <Spacer space={4} />
                <Link href="#go-to-cart" variant={{ inverse: true }}>
                  View Cart
                </Link>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
        </CartLayout>
      </CartContainer>
    </CartPlaceholder>
  );
};

export default CartFooter;
