import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Divider,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import palette from '@telus-uds/palette-allium';
import { SubscribersContext } from 'context/SubscribersContext';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import { useNavigate } from 'react-router-dom';
import { DEVICE_SELECT, UPGRADE_BUNDLES } from 'constants/routes';
import {
  exampleSubscriberList,
  exampleUpgradeHeaders,
  // exampleUpgradeSubscriberList,
} from 'context/exampleSubscriberData';
import AddSubscriber from 'components/AddSubscriber';
import { IconButton, Box, Modal, Icon, Link } from '@telus-uds/components-web';
import {
  Question,
  StatusSuccess,
} from '@telus-uds/palette-allium/build/web/icons';
import { DeviceContext } from 'context/DeviceContext';

const UpgradesPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const ButtonWrapper = styled.div`
  div {
    border: none;
  }
`;

const CountWrapper = styled.div`
  background: ${palette.color.greenAccessible};
  border-radius: 100%;
  width: 48px;
  height: 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ROWS_PER_BATCH = 15;

const UpgradeModal = () => {
  const { cartFooterData } = useContext(DeviceContext);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ButtonWrapper>
        <IconButton icon={Question} onPress={() => setModalOpen(true)} />
      </ButtonWrapper>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Box space={4}>
          <Typography variant={{ size: 'h2' }} tokens={{ fontWeight: '300' }}>
            Upgrade offers explained
          </Typography>
          <Spacer space={4} />
          <Typography>
            Your account has the following upgrade offers available to
            subscribers eligible to renew their contracts.
          </Typography>
          <Spacer space={6} />
          <FlexGrid gutter={false}>
            <FlexGrid.Row>
              <FlexGrid.Col xs={2}>
                <Spacer space={1} />
                <Icon
                  icon={StatusSuccess}
                  variant={{ size: 'extraLarge', color: 'success' }}
                />
              </FlexGrid.Col>
              <FlexGrid.Col xs={10}>
                <Box
                  horizontal={4}
                  vertical={3}
                  variant={{ background: 'light' }}
                >
                  <Typography variant={{ size: 'large' }}>
                    TELUS Technology Refresh Program
                  </Typography>
                  <Spacer space={4} />
                  <Typography>
                    The TELUS Technology Refresh Program allows you to upgrade
                    uany subscriber to a new smartphone after 30 months on their
                    current term. The refresh program requires the subscriber
                    sign a new individual term, based on the details outlined in
                    the contract.
                  </Typography>
                </Box>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
          {cartFooterData.floatBalance ? (
            <>
              <Spacer space={6} />
              <FlexGrid gutter={false}>
                <FlexGrid.Row>
                  <FlexGrid.Col xs={2}>
                    <Spacer space={1} />
                    <CountWrapper>
                      <Typography variant={{ inverse: true, size: 'large' }}>
                        {cartFooterData.floatBalance}
                      </Typography>
                    </CountWrapper>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={10}>
                    <Box
                      horizontal={4}
                      vertical={3}
                      variant={{ background: 'light' }}
                    >
                      <Typography variant={{ size: 'large' }}>
                        Float prgoram
                      </Typography>
                      <Spacer space={4} />
                      <Typography>
                        Your current float balance is{' '}
                        {cartFooterData.floatBalance}
                      </Typography>
                      <Spacer space={4} />
                      <Typography>
                        Use your float to upgrade any subscriber to a new
                        smartphone without an early upgrade fee. This program
                        expires 0 omonths before your corporate agreement with
                        TELUS ends.
                      </Typography>
                    </Box>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid>
            </>
          ) : null}
          <Spacer space={6} />
          <StackWrap tokens={{ flexGrow: 1, justifyContent: 'center' }}>
            <Button
              onPress={() => setModalOpen(false)}
              variant={{ priority: 'high' }}
            >
              Close
            </Button>
          </StackWrap>
        </Box>
      </Modal>
    </>
  );
};

const UpgradesPage = () => {
  const {
    tableHeaders,
    setTableHeaders,
    subscriberData,
    // setSubscriberData,
    selectedSubscribers,
    toggleAll,
    toggleSubscriber,
    removeSelectedSubscribers,
  } = useContext(SubscribersContext);
  ////////////////////////////////
  const [formattedData, setFormattedData] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [visibleData, setVisibleData] = useState([]);
  const [numLoaded, setNumLoaded] = useState(0);

  const [lastSorted, setLastSorted] = useState(-1);
  const [order, setOrder] = useState(false);
  ////////////////////////////////
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const navigate = useNavigate();

  // initializeSubscriberData(exampleUpgradeSubscriberList);

  useEffect(() => {
    setTableHeaders(exampleUpgradeHeaders);
    formatData();
  }, [subscriberData]);

  useEffect(() => {
    if (formattedData.length) {
      setTimeout(() => {
        // sortColumn(0);
        if (numLoaded > 0) {
          setVisibleData(formattedData.slice(0, numLoaded));
        } else {
          setVisibleData(formattedData.slice(0, ROWS_PER_BATCH));
          setNumLoaded(ROWS_PER_BATCH);
        }
        setShowLoadMore(false);
      }, 10);
    }
  }, [formattedData]);

  const formatData = () => {
    let data = [];
    for (let i = 0; i < subscriberData.length; i++) {
      let subscriberObject = [...subscriberData[i].details];
      subscriberObject.push(
        { id: 'subscriber-id', value: subscriberData[i].id },
        { id: 'fee-waived', value: false }
      );
      data.push(subscriberObject);
    }
    // console.log('useEffect subscriberData');
    const newOrder = lastSorted === 0 ? !order : true;
    const coef = newOrder ? 1 : -1;
    // console.log('sortColumn check: ', formattedData);

    const newRows = data.sort((row1, row2) => {
      const comp1 = row1[0].value ? row1[0].value : row1[0];
      const comp2 = row2[0].value ? row2[0].value : row2[0];
      if (comp1 === comp2) return 0;
      return coef * (comp1 > comp2 ? 1 : -1);
    });
    setFormattedData(newRows);
  };

  const loadMore = () => {
    if (showLoadMore) return;

    if (visibleData.length < formattedData.length) {
      setShowLoadMore(true);
      setTimeout(supplyRows, 3000);
    } else {
      setShowLoadMore(false);
    }
  };

  const supplyRows = () => {
    setVisibleData(
      visibleData.concat(
        formattedData.slice(numLoaded, numLoaded + ROWS_PER_BATCH)
      )
    );
    setNumLoaded(numLoaded + ROWS_PER_BATCH);
    setShowLoadMore(false);
  };

  const sortColumn = (index) => {
    const newOrder = lastSorted === index ? !order : true;
    const coef = newOrder ? 1 : -1;
    const tableId = tableHeaders[index].id;

    const newRows = formattedData.sort((row1, row2) => {
      const comp1 = row1.find((obj) => obj.id === tableId).value
        ? row1.find((obj) => obj.id === tableId).value
        : row1.find((obj) => obj.id === tableId);
      const comp2 = row2.find((obj) => obj.id === tableId).value
        ? row2.find((obj) => obj.id === tableId).value
        : row2.find((obj) => obj.id === tableId);
      if (comp1 === comp2) return 0;
      return coef * (comp1 > comp2 ? 1 : -1);
    });

    setOrder(newOrder);
    setFormattedData([...newRows]);
    // setVisibleData(newRows.slice(0, numLoaded));
    setLastSorted(index);
    // supplyRows();
    // console.log(lastSorted);
    // console.log(order);
  };

  const handleAddDevice = () => {
    navigate(DEVICE_SELECT);
  };

  const handleChoose = () => {
    navigate(UPGRADE_BUNDLES);
  };

  const handleRemove = () => {
    // let subscribers = '';
    // selectedSubscribers
    //   .filter((subscriber) => subscriber.selected === true)
    //   .map((subscriber) => {
    //     subscribers += `${subscriber.id} `;
    //   });
    // alert(`removing subscribers: ${subscribers}`);
    removeSelectedSubscribers();
    setRemoveModalOpen(false);
  };

  const UpgradeButtons = ({ id }) => {
    // const [float, setFloat] = useState(false);
    // console.log('setting upgrade buttons');

    // useEffect(() => {
    //   console.log('upgradeButtons: ', formattedData, ' - ', id);
    //   if (formattedData.length) {
    //     if (
    //       formattedData.find(
    //         (data) =>
    //           data.find((obj) => obj.id === 'subscriber-id').value === id
    //       ) &&
    //       formattedData
    //         .find(
    //           (data) =>
    //             data.find((obj) => obj.id === 'subscriber-id').value === id
    //         )
    //         .find((obj) => obj.id === 'upgrade-fee').feeWaived
    //     ) {
    //       setFloat(true);
    //     }
    //   }
    // }, [visibleData]);

    const handleFloat = () => {
      let newData = [...formattedData];
      newData
        .find(
          (data) => data.find((obj) => obj.id === 'subscriber-id').value === id
        )
        .find((obj) => obj.id === 'upgrade-fee').feeWaived = true;
      console.log('newData: ', newData);
      setFormattedData(newData);
      // setFloat(true);
      // console.log('float: ', float);
    };

    const handleUpgrade = () => {
      let newData = [...formattedData];
      newData
        .find(
          (data) => data.find((obj) => obj.id === 'subscriber-id').value === id
        )
        .find((obj) => obj.id === 'upgrade-fee').feeWaived = false;
      setFormattedData(newData);
      // setFloat(false);
      // console.log('float: ', float);
    };

    return (
      <StackWrap tokens={{ flexShrink: 0 }}>
        {formattedData.find(
          (data) => data.find((obj) => obj.id === 'subscriber-id').value === id
        ) ? (
          <>
            <Button
              // onPress={() => alert(`upgrading ${id}`)}
              onPress={() => handleUpgrade()}
              variant={
                formattedData
                  .find(
                    (data) =>
                      data.find((obj) => obj.id === 'subscriber-id').value ===
                      id
                  )
                  .find((obj) => obj.id === 'upgrade-fee').feeWaived
                  ? { size: 'small' }
                  : { priority: 'high', size: 'small' }
              }
            >
              Device upgrade
              {/* {id} */}
            </Button>
            <Spacer space={2} direction="row" />
            <Button
              // onPress={() => alert(`float ${id}`)}
              onPress={() => handleFloat()}
              variant={
                formattedData
                  .find(
                    (data) =>
                      data.find((obj) => obj.id === 'subscriber-id').value ===
                      id
                  )
                  .find((obj) => obj.id === 'upgrade-fee').feeWaived
                  ? { priority: 'high', size: 'small' }
                  : { size: 'small' }
              }
            >
              Float
            </Button>
          </>
        ) : null}
      </StackWrap>
    );
  };

  const FeeModal = ({ children, id }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const feeData = subscriberData.find((obj) => obj.id === id)
      ? subscriberData.find((obj) => obj.id === id).feeBreakdown
      : null;

    return (
      <>
        {feeData ? (
          <>
            <TextButton
              variant={{ alternative: true }}
              onPress={() => setModalOpen(true)}
              tokens={{ alignSelf: false }}
            >
              {children}
            </TextButton>
            <Modal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              variant={{ width: 'xl' }}
            >
              <Box space={4}>
                <Typography block variant={{ size: 'h1' }}>
                  Here's how it all breaks down
                </Typography>
                <Spacer space={8} />
                {feeData.items.map((data, i) => (
                  <StackWrap
                    tokens={{ justifyContent: 'space-between', flexGrow: 1 }}
                    key={i}
                    space={4}
                  >
                    <Typography variant={{ size: 'large' }}>
                      {data.label}
                    </Typography>
                    <Typography variant={{ size: 'large' }}>
                      {data.value}
                    </Typography>
                  </StackWrap>
                ))}
                <Divider variant={{ decorative: true }} space={3} />
                <StackWrap
                  tokens={{ justifyContent: 'space-between', flexGrow: 1 }}
                >
                  <Typography variant={{ size: 'large' }}>
                    {feeData.total.label}
                  </Typography>
                  <Typography variant={{ size: 'large' }}>
                    {feeData.total.value}
                  </Typography>
                </StackWrap>
                <Spacer space={6} />
                <StackWrap tokens={{ justifyContent: 'center', flexGrow: 1 }}>
                  <Button
                    variant={{ priority: 'high' }}
                    onPress={() => setModalOpen(false)}
                    tokens={{ borderRadius: '4px' }}
                  >
                    Ok
                  </Button>
                </StackWrap>
              </Box>
            </Modal>
          </>
        ) : null}
      </>
    );
  };

  return (
    <UpgradesPageContainer>
      <Spacer space={10} />
      <FlexGrid limitWidth={false} gutter={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={12}>
            <FlexGrid.Row verticalAlign="middle">
              <FlexGrid.Col xs={6}>
                <StackWrap>
                  <Button
                    onPress={() => handleChoose()}
                    inactive={selectedSubscribers.every((obj) => !obj.selected)}
                  >
                    Choose bundle
                  </Button>
                  <Spacer space={5} direction="row" />
                  <StackView tokens={{ justifyContent: 'center' }}>
                    {selectedSubscribers.every((obj) => !obj.selected) ? (
                      <Typography tokens={{ color: palette.color.greyCloud }}>
                        Add device & plan
                      </Typography>
                    ) : (
                      <TextButton
                        tokens={{ color: palette.color.greyCharcoal }}
                        onPress={() => handleAddDevice()}
                      >
                        Add device & plan
                      </TextButton>
                    )}
                  </StackView>
                  <Spacer space={5} direction="row" />
                  <StackView tokens={{ justifyContent: 'center' }}>
                    {selectedSubscribers.every((obj) => !obj.selected) ? (
                      <Typography tokens={{ color: palette.color.greyCloud }}>
                        Remove
                      </Typography>
                    ) : (
                      <TextButton
                        tokens={{ color: palette.color.greyCharcoal }}
                        onPress={() => setRemoveModalOpen(true)}
                      >
                        Remove
                      </TextButton>
                    )}
                  </StackView>
                </StackWrap>
                <Modal
                  isOpen={removeModalOpen}
                  onClose={() => setRemoveModalOpen(false)}
                >
                  <Typography block variant={{ size: 'h1' }}>
                    Confirmation
                  </Typography>
                  <Spacer space={5} />
                  <Typography>
                    Are you sure you want to remove the selected subscribers?
                  </Typography>
                  <Spacer space={5} />
                  <Box vertical={4}>
                    <StackWrap
                      space={6}
                      direction="row-reverse"
                      tokens={{ alignItems: 'center' }}
                    >
                      <Button
                        variant={{ priority: 'high' }}
                        onPress={() => handleRemove()}
                      >
                        Yes
                      </Button>
                      <StackView>
                        <TextButton
                          variant={{ alternative: true }}
                          onPress={() => setRemoveModalOpen(false)}
                        >
                          No
                        </TextButton>
                      </StackView>
                    </StackWrap>
                  </Box>
                </Modal>
              </FlexGrid.Col>
              <FlexGrid.Col xs={6} horizontalAlign="right">
                <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                  {/* <Button
                    variant={{ priority: 'high' }}
                    onPress={() => alert('add subscribers')}
                  >
                    Add subscribers
                  </Button> */}
                  <AddSubscriber />
                </StackWrap>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid.Col>
        </FlexGrid.Row>
        <Divider space={8} />
        {tableHeaders && visibleData.length ? (
          <FlexGrid.Row>
            <DynamicTable
              headers={tableHeaders}
              data={visibleData}
              originalData={formattedData}
              showLoadMore={showLoadMore}
              selection={selectedSubscribers}
              cbLoadMore={loadMore}
              sortColumn={sortColumn}
              lastSorted={lastSorted}
              order={order}
              toggleAll={toggleAll}
              toggleRow={toggleSubscriber}
              buttons={UpgradeButtons}
              modal={UpgradeModal}
              feeModal={FeeModal}
            />
          </FlexGrid.Row>
        ) : null}
      </FlexGrid>
    </UpgradesPageContainer>
  );
};

export default UpgradesPage;
