import { Divider, Spacer, Spinner, Tooltip } from '@telus-uds/ds-allium';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Chevron from 'components/Chevron';

import './dynamic-table.scss';
import { format } from 'date-fns';
import { Link } from '@telus-uds/components-web';
import palette from '@telus-uds/palette-allium';
import UpdateSubscriber from 'components/UpdateSubscriber';
import { SubscribersContext } from 'context/SubscribersContext';
import hash from './hash';

// const TableWrapper = styled.div`
//   width: 100%;

//   table {
//     width: 100%;

//     tbody {
//       overflow-y: scroll;
//       max-height: 47.5rem;
//       display: block;
//     }

//     thead,
//     tbody tr {
//       display: table;
//       table-layout: fixed;
//       width: 100%;
//     }

//     thead {
//       width: calc(100% - 1rem);
//     }
//   }
// `;

export const CheckBox = ({ selected, value, index, cb, error }) => {
  // console.log('selection ', selected);
  return (
    <div className={`dynamic-table__checkbox ${error ? 'disabled' : ''}`}>
      <input
        type="checkbox"
        name="addons"
        value={value}
        checked={selected ? 'checked' : ''}
        onChange={() => cb(!selected, index)}
        aria-label="selection checkbox"
        disabled={error}
      />
      <span className="checkbox" />
    </div>
  );
};

const TCell = ({
  cellKey,
  field,
  bold,
  selected,
  type,
  button: Button,
  id,
  error,
  feeModal: FeeModal,
}) => {
  let content = field;
  const navigate = useNavigate();
  // console.log(typeof field, ' - ', field);
  // console.log('type: ', type);

  const handleLink = (link) => {
    navigate(link);
  };

  // console.log(
  //   'TCell contents: ',
  //   cellKey,
  //   ' - ',
  //   field,
  //   ' - ',
  //   bold,
  //   ' - ',
  //   selected,
  //   ' - ',
  //   type
  // );

  // console.log('typeof field: ', typeof field);

  // console.log('Button: ', Button);
  // console.log('FeeModal: ', FeeModal);

  if (typeof field === 'object') {
    if (field && field.id === 'status' && error) {
      content = (
        <span>
          <UpdateSubscriber id={id} />
        </span>
      );
    } else if (
      (field.type === 'price' || type === 'price') &&
      typeof field.value === 'number'
    ) {
      if (field.feeWaived) {
        content = (
          <span>
            <strike>${field.value.toFixed(2)}</strike> Waived
          </span>
        );
      } else if (FeeModal) {
        content = <FeeModal id={id}>${field.value.toFixed(2)}</FeeModal>;
      } else {
        content = `$${field.value.toFixed(2)}`;
      }
    } else if (field.type === 'date' || type === 'date') {
      content = format(new Date(field.value), 'MMM dd, yyyy');
    } else if (field.type === 'link' || type === 'link') {
      content = (
        <a onClick={() => handleLink(`${field.link}#${field.value}`)}>
          {field.value}
        </a>
      );
    } else if (field.type === 'buttons' || type === 'buttons') {
      content = <Button id={id} />;
    } else if (field.value) {
      content = <span>{field.value}</span>;
    } else {
      content = null;
    }
  }

  return (
    <div
      className={`dynamic-table__table__cell ${
        bold ? 'dynamic-table__bold__content' : ''
      } ${selected ? 'selected' : ''} ${
        error ? 'dynamic-table__error__state' : ''
      }`}
      style={
        field && field.value
          ? { marginLeft: field.type === 'toggle' ? -18 : 0 }
          : { color: palette.color.red }
      }
      key={cellKey}
    >
      {content || 'Incomplete'}
    </div>
  );
};

const DynamicTable = ({
  headers,
  data,
  selection,
  toggleAll,
  toggleRow,
  buttons: Buttons,
  originalData,
  showLoadMore,
  cbLoadMore,
  sortColumn,
  lastSorted,
  order,
  dynamicHeight,
  modal: Modal,
  feeModal: FeeModal,
  noScroll,
}) => {
  const { subscriberData } = useContext(SubscribersContext);
  const [tableData, setTableData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  // const [columnWidth, setColumnWidth] = useState(null);
  const headerColumns = [];
  const dynamicHeightStyle = {};

  // console.log('data: ', data);

  // console.log('Buttons: ', Buttons);
  // console.log('FeeModal: ', FeeModal);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (!selection || selection.length === originalData.length) {
      let selectionStatus = true;
      data.map((subscriber) => {
        if (
          selection &&
          selection.find(
            (obj) =>
              obj.id ===
              subscriber.find((obj) => obj.id === 'subscriber-id').value
          )
        ) {
          // console.log(
          //   'subscriber: ',
          //   subscriber.find((obj) => obj.id === 'subscriber-id')
          // );
          // console.log('selection: ', selection);
          // console.log(
          //   'selection: ',
          //   selection.find(
          //     (obj) =>
          //       obj.id ===
          //       subscriber.find((obj) => obj.id === 'subscriber-id').value
          //   )
          // );
          if (
            !selection.find(
              (obj) =>
                obj.id ===
                subscriber.find((obj) => obj.id === 'subscriber-id').value
            ).selected
          ) {
            selectionStatus = false;
          }
        }
      });
      setAllSelected(selectionStatus);
    }
  }, [selection, data]);

  let ratio = null;
  // console.log(data);

  if (dynamicHeight) {
    dynamicHeightStyle.height = dynamicHeight;
  }

  const setColumnWidth = (col, index) => {
    const tableHeader = document.getElementsByClassName(
      'dynamic__table__header'
    )[0];
    const tableBody = document.getElementsByClassName(
      'dynamic-table__table__body'
    )[0];
    const tableWrap = document.getElementsByClassName(
      'dynamic-table__table__wrap'
    )[0];
    if (col && col.clientWidth > 10) {
      if (!ratio) {
        // console.log('ratio: ', ratio);
        // console.log('tablewrapwidth: ', tableWrap.clientWidth);
        if (tableBody.clientWidth > tableHeader.clientWidth) {
          ratio = (tableWrap.clientWidth - 150) / tableBody.clientWidth;
        } else {
          ratio = (tableWrap.clientWidth - 150) / tableHeader.clientWidth;
        }
        ratio = Math.max(ratio.toFixed(1), 1);
        // console.log('ratio: ', ratio);
      }
      // console.log('col width: ', col.clientWidth);
      // console.log('header width: ', headerColumns[index].clientWidth);
      const theCol = col;

      if (index !== headerColumns.length - 1) {
        const maxWidth = Math.floor(
          Math.max(col.clientWidth, headerColumns[index].clientWidth) * ratio
        );
        // console.log('maxWidth: ', maxWidth);
        headerColumns[index].style.width = `${maxWidth}px`;
        theCol.style.width = `${maxWidth}px`;
      } else {
        theCol.style.minWidth = `${headerColumns[index].clientWidth}px`;
      }
      tableBody.style.width = '100%';
      tableHeader.style.width = '100%';
      // console.log('___________________________');
    }
  };

  // console.log('selection: ', selection);
  // console.log('data: ', data);
  // console.log('tableData: ', tableData);

  return (
    <>
      {!selection || selection.length === originalData.length ? (
        <div id="dynamicTable" className="dynamic-table">
          <div className="dynamic-table__table__wrap">
            <div className="dynamic__table__header">
              {selection ? (
                <div className="dynamic-table__table__cell">
                  {toggleAll ? (
                    <CheckBox
                      selected={allSelected}
                      value="0"
                      index={0}
                      cb={() => toggleAll(data)}
                    />
                  ) : (
                    <div className="dynamic-table__checkbox-placeholder" />
                  )}
                </div>
              ) : null}
              {headers.map((header, index) => (
                <div
                  onClick={sortColumn ? () => sortColumn(index) : null}
                  role="button"
                  tabIndex={0}
                  key={hash(header.label)}
                  ref={(r) => {
                    headerColumns[index] = r;
                  }}
                  className={`dynamic-table__table__cell ${
                    header.type ? '' : 'limit-width'
                  }`}
                >
                  {header.label}
                  {header.tooltip ? <Tooltip content={header.tooltip} /> : null}
                  {header.modal ? <Modal /> : null}
                  {index === lastSorted ? (
                    <Chevron
                      width="12"
                      height="9"
                      color="#2a2d2e"
                      direction={order ? 'down' : 'up'}
                      className=""
                    />
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
            <div
              className={`dynamic__table__body_wrapper ${
                noScroll ? 'no-scroll' : ''
              }`}
              style={dynamicHeightStyle}
            >
              <div className="dynamic-table__table__body">
                {selection ? (
                  <div className="dynamic__table__column">
                    {data.map((row, rowIndex) => {
                      return (
                        <div
                          key={hash(row)}
                          className={`dynamic-table__table__cell ${
                            row.selected ? 'selected' : ''
                          } ${
                            row.find((x) => x.incomplete)
                              ? 'dynamic-table__error__state'
                              : ''
                          }`}
                        >
                          <CheckBox
                            selected={
                              selection.find(
                                (obj) =>
                                  obj.id ===
                                  data[rowIndex].find(
                                    (obj) => obj.id === 'subscriber-id'
                                  ).value
                              )
                                ? selection.find(
                                    (obj) =>
                                      obj.id ===
                                      data[rowIndex].find(
                                        (obj) => obj.id === 'subscriber-id'
                                      ).value
                                  ).selected
                                : false
                            }
                            value={rowIndex.toString()}
                            index={rowIndex}
                            cb={() =>
                              toggleRow(
                                data[rowIndex].find(
                                  (obj) => obj.id === 'subscriber-id'
                                ).value
                              )
                            }
                            // cb={() => alert('selecting')}
                            // error={row.find((x) => x.incomplete)}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {selection
                  ? headers.map((header, colIndex) => (
                      <div
                        key={hash(header)}
                        className={`dynamic__table__column ${
                          header.type ? '' : 'limit-width'
                        }`}
                        ref={(r) => {
                          setColumnWidth(r, colIndex);
                        }}
                      >
                        {tableData.map((row, rowIndex) => {
                          const field = row.find((obj) => obj.id === header.id);
                          const id = row.find(
                            (obj) => obj.id === 'subscriber-id'
                          )
                            ? row.find((obj) => obj.id === 'subscriber-id')
                                .value
                            : null;
                          if (subscriberData.find((obj) => obj.id === id)) {
                            return (
                              <TCell
                                rowIndex={rowIndex}
                                key={hash(`${rowIndex}-$${colIndex}`)}
                                bold={header.bold}
                                field={field}
                                type={header.type}
                                button={selection ? Buttons : null}
                                id={id}
                                error={row.find((x) => x.incomplete)}
                                feeModal={FeeModal}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    ))
                  : headers.map((header, colIndex) => (
                      <div
                        key={hash(header)}
                        className={`dynamic__table__column ${header.type} ${
                          header.type === null ? 'limit-width' : ''
                        }`}
                        ref={(r) => {
                          setColumnWidth(r, colIndex);
                        }}
                      >
                        {tableData.map((row, rowIndex) => {
                          const field = row.find((obj) => obj.id === header.id);
                          // const id = row.find(
                          //   (obj) => obj.id === 'subscriber-id'
                          // )
                          //   ? row.find((obj) => obj.id === 'subscriber-id')
                          //       .value
                          //   : null; // THIS IS IMPORTANT
                          // if not the subscriber-id fall back to regular field ID
                          //
                          return (
                            <TCell
                              rowIndex={rowIndex}
                              key={hash(`${rowIndex}-$${colIndex}`)}
                              bold={header.bold}
                              field={field}
                              type={header.type}
                              button={selection ? Buttons : null}
                              // id={id}
                              error={row.find((x) => x.incomplete)}
                              feeModal={FeeModal}
                            />
                          );
                        })}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <Spacer space={10} />
          <Divider />
          <div className="dynamic__table__footer">
            <div className="entries">
              <p>
                <span>{`Showing 1-${data.length} of ${originalData.length}`}</span>
              </p>
            </div>
            {data.length < originalData.length && (
              <div className="load-more-container">
                {showLoadMore ? (
                  <button disabled type="button">
                    <Spinner label="" show variant={{ size: 'small' }} />
                    loading
                  </button>
                ) : (
                  <button type="button" onClick={cbLoadMore}>
                    <img src="../../assets/icons/chev-down-green.svg" alt="" />
                    Load more
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
      {/* <TableWrapper>
        <Table>
          <Table.Header>
            {headers.map((header, index) => (
              <Table.Cell key={index}>{header.label}</Table.Cell>
            ))}
          </Table.Header>
          {tableData.length > 0 ? (
            <Table.Body>
              {tableData.map((row, rowIndex) => (
                <Table.Row key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <Table.Cell key={`${colIndex}-${rowIndex}`}>
                      {row[colIndex].value}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          ) : null}
        </Table>
      </TableWrapper> */}
    </>
  );
};

export default DynamicTable;
