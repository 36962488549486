import {
  Typography,
  FlexGrid,
  Spacer,
  Tabs,
  Button,
  StackWrap,
  StackView,
  BaseModal,
  Box,
  Select,
  TextButton,
} from '@telus-uds/ds-allium';
// import SubHeader from 'components/SubHeader';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AccessoryCard from 'components/AccessoryCard';
import OrderSummary from 'components/OrderSummary';
import { useContext } from 'react';
import { DeviceContext } from 'context/DeviceContext';
import {
  exampleAccessoriesState,
  exampleFilteredAccessoriesState,
  exampleOrderInfo,
  exampleDeviceFilter,
} from 'context/exampleDeviceData';
import { DEVICE_ACCESSORIES, DEVICE_SUBSCRIBERS } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

const AccessoriesPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const SummaryContainer = styled.div`
  position: relative;

  @media screen and (min-width: 1008px) {
    position: absolute;
    height: 100%;
    width: calc(100% - 2rem);
  }
`;

const AccessoriesPage = () => {
  const {
    selectedDevice,
    availableAccessories,
    setAvailableAccessories,
    steps,
    setCurrentStep,
    setOrderInfo,
    orderInfo,
  } = useContext(DeviceContext);
  const [category, setCategory] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [deviceFilter, setDeviceFilter] = useState({ make: null, model: null });
  const navigate = useNavigate();

  useEffect(() => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat) {
      setCategory(urlcat);
    } else {
      setCategory('all');
    }
    if (steps) {
      let thisStep = parseInt(
        Object.keys(steps).find((key) => steps[key].link === DEVICE_ACCESSORIES)
      );
      setCurrentStep(thisStep);
    }
    if (!orderInfo) {
      setOrderInfo(exampleOrderInfo);
    }
  }, []);

  useEffect(() => {
    console.log(deviceFilter);
  }, [deviceFilter]);

  const handleCategoryChange = () => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat !== category) {
      setCategory(urlcat);
      alert(`Device category changed to ${urlcat}`);
      setAvailableAccessories(exampleFilteredAccessoriesState);
    }
  };

  const handleOnsubmit = () => {
    // console.log('Device Options Chosen: ', optionsTracker);
    navigate(DEVICE_SUBSCRIBERS);
    // setCurrentStep(3);
  };

  const handleFilter = () => {
    alert('filtering');
    console.log('filtering: ', deviceFilter);
    setAvailableAccessories(exampleFilteredAccessoriesState);
    setFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    setDeviceFilter({ make: null, model: null });
    setAvailableAccessories(exampleAccessoriesState);
  };

  const FilterModal = () => {
    const handleSelectMake = (value) => {
      let newDeviceFilter = { ...deviceFilter };
      newDeviceFilter.make = value;
      if (deviceFilter.make !== newDeviceFilter.make) {
        newDeviceFilter.model = null;
      }
      setDeviceFilter(newDeviceFilter);
    };

    const handleSelectModel = (value) => {
      let newDeviceFilter = { ...deviceFilter };
      newDeviceFilter.model = value;
      setDeviceFilter(newDeviceFilter);
    };

    return (
      <StackWrap>
        {deviceFilter.model ? (
          <StackView tokens={{ justifyContent: 'center' }}>
            <TextButton
              onPress={handleClearFilter}
              variant={{ alternative: true }}
            >
              Clear 1 filter
            </TextButton>
          </StackView>
        ) : null}
        <Spacer space={4} direction="row" />
        <Button onPress={() => setFilterModalOpen(true)}>
          Add device filter
        </Button>
        <BaseModal
          isOpen={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          variant={{ width: 'xl' }}
        >
          <Box space={3}>
            <Typography block variant={{ size: 'h1' }}>
              Add a device filter
            </Typography>
            <Box vertical={6}>
              <Select
                label="Select device make"
                placeholder="Please select..."
                onChange={(value) => handleSelectMake(value)}
                value={deviceFilter.make}
              >
                {exampleDeviceFilter.map((filter, i) => (
                  <Select.Item
                    value={`${filter.make.id}`}
                    key={i}
                  >{`${filter.make.label}`}</Select.Item>
                ))}
              </Select>
              <Spacer space={6} />
              <Select
                label="Select device model"
                placeholder="Please select..."
                onChange={(value) => handleSelectModel(value)}
                value={deviceFilter.model}
              >
                {deviceFilter.make
                  ? exampleDeviceFilter
                      .find((obj) => obj.make.id === deviceFilter.make)
                      .models.map((model, i) => (
                        <Select.Item
                          value={`${model.id}`}
                          key={i}
                        >{`${model.label}`}</Select.Item>
                      ))
                  : null}
              </Select>
            </Box>
            <Spacer space={3} />
            <Box vertical={3}>
              <StackWrap
                space={6}
                direction="row-reverse"
                tokens={{ alignItems: 'center' }}
              >
                <Button
                  variant={{ priority: 'high' }}
                  onPress={handleFilter}
                  inactive={!deviceFilter.model}
                >
                  Save
                </Button>
                <StackView>
                  <TextButton
                    variant={{ alternative: true }}
                    onPress={() => setFilterModalOpen(false)}
                  >
                    Cancel
                  </TextButton>
                </StackView>
              </StackWrap>
            </Box>
          </Box>
        </BaseModal>
      </StackWrap>
    );
  };

  return (
    <AccessoriesPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={6} />
      <FlexGrid limitWidth={false} outsideGutter={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={12}>
            <StackWrap tokens={{ justifyContent: 'space-between' }}>
              <Typography block variant={{ size: 'h1' }}>
                Select accessories for{' '}
                {selectedDevice ? selectedDevice : 'your device'}
              </Typography>
              {selectedDevice ? null : <FilterModal />}
            </StackWrap>
            <Spacer space={4} />
            <Typography block>
              Choose from the brands below to find your next device
            </Typography>
            <Spacer space={6} />
            <Tabs
              items={availableAccessories.categories}
              itemTokens={{ maxWidth: '300px' }}
              initialValue={category}
              onChange={() => handleCategoryChange()}
            />
            <Spacer space={2} />
          </FlexGrid.Col>
        </FlexGrid.Row>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={4}>
            <Spacer space={7} />
            <SummaryContainer>
              <OrderSummary active={true} onsubmit={() => handleOnsubmit()} />
            </SummaryContainer>
            <Spacer space={6} />
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} lg={8}>
            <FlexGrid.Row>
              {Object.keys(availableAccessories.listOfAccessories).map(
                (keyName, keyIndex) => (
                  <FlexGrid.Col
                    xs={12}
                    sm={6}
                    md={4}
                    lg={6}
                    xl={4}
                    key={keyIndex}
                    flex={true}
                  >
                    <AccessoryCard
                      deviceInfo={
                        availableAccessories.listOfAccessories[keyName]
                      }
                      deviceKey={keyName}
                      multiple={availableAccessories.multipleAllowed}
                    />
                  </FlexGrid.Col>
                )
              )}
            </FlexGrid.Row>
            <Spacer space={6} />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </AccessoriesPageContainer>
  );
};

export default AccessoriesPage;
