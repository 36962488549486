import {
  exampleAvailableAccessories,
  exampleAvailableDevices,
  exampleUserSettings,
} from 'context/exampleUserData';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState(exampleUserSettings);

  const [displayDevices, setDisplayDevices] = useState(exampleAvailableDevices);
  const [selectedDevices, setSelectedDevices] = useState({});

  const [displayAccessories, setDisplayAccessories] = useState(
    exampleAvailableAccessories
  );
  const [selectedAccessories, setSelectedAccessories] = useState({});

  const [userType, setUserType] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState({
    ban: null,
    payment: null,
    pcNumber: '',
  });

  // useEffect(() => {
  //   setPaymentOptions({
  //     ban: null,
  //     payment: null,
  //   });
  // }, []);

  useEffect(() => {
    let newSelectedDevices = {};
    if (displayDevices.length) {
      displayDevices.map((device) => {
        device.options.map((option) => {
          newSelectedDevices[option.id] = option.selected;
        });
      });
    }
    setSelectedDevices(newSelectedDevices);
  }, [displayDevices]);

  useEffect(() => {
    let newSelectedAccessories = {};
    if (displayAccessories.length) {
      displayAccessories.map((accessory) => {
        newSelectedAccessories[accessory.id] = accessory.selected;
      });
    }
    setSelectedAccessories(newSelectedAccessories);
  }, [displayAccessories]);

  useEffect(() => {
    console.log('updated user settings: ', userSettings);
  }, [userSettings]);

  useEffect(() => {
    console.log('user: ', userType);
  }, [userType]);

  const updateBan = (number) => {
    let newPaymentOptions = { ...paymentOptions };
    newPaymentOptions.ban = number;
    setPaymentOptions(newPaymentOptions);
  };

  const updatePayment = (id) => {
    let newPaymentOptions = { ...paymentOptions };
    newPaymentOptions.payment = id;
    setPaymentOptions(newPaymentOptions);
  };

  const updatePcNumber = (number) => {
    let newPaymentOptions = { ...paymentOptions };
    newPaymentOptions.pcNumber = number;
    setPaymentOptions(newPaymentOptions);
  };

  const updateComments = (text) => {
    let newPaymentOptions = { ...paymentOptions };
    newPaymentOptions.comments = text;
    setPaymentOptions(newPaymentOptions);
  };

  // All accessible variables
  const value = useMemo(() => {
    return {
      userSettings,
      setUserSettings,
      displayDevices,
      setDisplayDevices,
      selectedDevices,
      setSelectedDevices,
      displayAccessories,
      setDisplayAccessories,
      selectedAccessories,
      setSelectedAccessories,
      userType,
      setUserType,
      paymentOptions,
      updateBan,
      updatePayment,
      updatePcNumber,
      updateComments,
    };
  }, [
    userSettings,
    displayDevices,
    selectedDevices,
    displayAccessories,
    selectedAccessories,
    userType,
    paymentOptions,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
