import { FlexGrid, Spacer } from '@telus-uds/ds-allium';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OrderSummary from 'components/OrderSummary';
// import SubHeader from 'components/SubHeader';
import {
  exampleByodOptions,
  exampleByodOrderInfo,
} from 'context/exampleDeviceData';
import Accordion from 'components/Accordion';
import {
  BYOD_PLANS,
  DEVICE_ACCESSORIES,
  DEVICE_SUBSCRIBERS,
} from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';

const ByodPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const SummaryContainer = styled.div`
  position: relative;

  @media screen and (min-width: 1008px) {
    position: absolute;
    height: 100%;
    width: calc(100% - 2rem);
  }
`;

const PageNavContainer = styled.div`
  overflow: hidden;
`;

const ByodPage = () => {
  const {
    deviceOptionsSet,
    optionsTracker,
    initializeOptionsTracker,
    setCurrentStep,
    setSteps,
    setOrderInfo,
    disallowAccessoryQuantities,
  } = useContext(DeviceContext);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('optionsTracker: ', optionsTracker);
    // console.log('optionsTracker.length: ', Object.keys(optionsTracker).length);
    if (Object.keys(optionsTracker).length < 1) {
      // console.log('setting options tracker');
      initializeOptionsTracker(null, exampleByodOptions);
    }
    setSteps([
      { label: 'Plans & add-ons', link: BYOD_PLANS },
      { label: 'Accessories', link: DEVICE_ACCESSORIES },
      { label: 'Subscriber information', link: DEVICE_SUBSCRIBERS },
    ]);
    setCurrentStep(0);
    setOrderInfo(exampleByodOrderInfo);
  }, []);

  const handleOnsubmit = () => {
    console.log('BYOD Options Chosen: ', optionsTracker);
    disallowAccessoryQuantities();
    navigate(DEVICE_ACCESSORIES);
    // setCurrentStep(1);
  };

  return (
    <ByodPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={6} />
      <FlexGrid limitWidth={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={4}>
            <SummaryContainer>
              <OrderSummary
                active={deviceOptionsSet}
                onsubmit={() => handleOnsubmit()}
              />
            </SummaryContainer>
            <Spacer space={6} />
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} lg={8}>
            <PageNavContainer>
              <Accordion
                deviceOptions={exampleByodOptions.deviceOptions}
                sections={exampleByodOptions.additionalOptions}
              />
            </PageNavContainer>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </ByodPageContainer>
  );
};

export default ByodPage;
