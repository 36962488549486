import { Card, Checkbox } from '@telus-uds/components-web';
import {
  RadioCardGroup,
  StackWrap,
  StackView,
  Typography,
  Spacer,
  Icon,
  Paragraph,
  FlexGrid,
  ExpandCollapseMini,
  TextButton,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import {
  Check,
  CaretDown,
  CaretUp,
} from '@telus-uds/palette-allium/build/web/icons';
import { DeviceContext } from 'context/DeviceContext';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

const OptionsContainer = styled.div`
  display: block;
  > fieldset > div > div > div > div > div:last-child {
    flex-grow: 1 !important;
  }
`;

const OptionCard = styled.div`
  flex: 1;
`;

const CheckboxCard = styled.div`
  flex: 1 1 0%;
  min-width: 266px;
  margin: -2px;
  padding: 0px;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    border-color: rgb(227, 230, 232);
  }

  > div {
    flex: 1 1 0%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(178, 185, 191);
    border-radius: 6px;
    padding: 12px 16px 12px 10px;
    min-width: 264px;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      border-color: rgb(103, 110, 115);
    }
  }
`;

const FormattedOptions = ({ option }) => {
  return (
    <StackView>
      <Spacer space={{ options: { size: 1 } }} />
      <Typography block variant={{ bold: true }}>
        {option.title}
      </Typography>
      {option.copy ? <Typography>{option.copy}</Typography> : ''}
      {option.greenText ? (
        <>
          <Spacer space={2} />
          <Typography tokens={{ color: palette.color.greenAccessible }}>
            {option.greenText}
          </Typography>
        </>
      ) : (
        ''
      )}
      {option.checklist ? (
        <>
          <Spacer space={2} />
          {option.checklist.map((checklistItem, i) => (
            <FlexGrid key={i} gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col xs={1}>
                  <Icon
                    icon={Check}
                    tokens={{
                      color: palette.color.greenAccessible,
                    }}
                  />
                </FlexGrid.Col>
                <FlexGrid.Col xs={11}>
                  <Paragraph block>{checklistItem}</Paragraph>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
          ))}
        </>
      ) : (
        ''
      )}
      {option.details ? (
        <ExpandCollapseMini
          variant={{ alternative: true }}
          expandTitle="Hide details"
          collapseTitle="View details"
        >
          {option.details.map((detail, i) => (
            <Typography key={i}>{detail}</Typography>
          ))}
        </ExpandCollapseMini>
      ) : (
        ''
      )}
    </StackView>
  );
};

const FormattedCost = ({ option }) => {
  return (
    <StackView>
      {option.cost ? (
        <StackWrap
          space={0}
          tokens={{
            justifyContent: 'flex-end',
          }}
        >
          <Typography>$ </Typography>
          <Typography variant={{ size: 'h2', colour: 'secondary' }}>
            {option.cost.toFixed(2)}
          </Typography>
          {option.costPer ? (
            <StackView>
              <Spacer space={{ xs: 1, lg: 2 }} />
              <Typography>/{option.costPer}</Typography>
            </StackView>
          ) : (
            ''
          )}
        </StackWrap>
      ) : (
        ''
      )}
    </StackView>
  );
};

const CheckboxOptions = ({ options }) => {
  const { setChecklistOption, optionsTracker } = useContext(DeviceContext);

  const handleCheck = (id) => {
    setChecklistOption(
      options.id,
      id,
      options.options.find((obj) => obj.id === id),
      options.title
    );
  };

  return (
    <StackView space={3}>
      {options.options.map((option, i) => (
        <CheckboxCard key={i} onClick={() => handleCheck(option.id)}>
          <div>
            <FlexGrid gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col xs={12} md={8}>
                  <StackWrap>
                    <StackView>
                      <Spacer space={1} />
                      <Checkbox
                        readOnly={true}
                        onChange={() => handleCheck(option.id)}
                        checked={optionsTracker.additionalOptions
                          .find((element) => element.id === options.id)
                          .selection.some((obj) => obj === option.id)}
                      />
                    </StackView>
                    <Spacer direction="row" space={1} />
                    <FormattedOptions option={option} />
                  </StackWrap>
                </FlexGrid.Col>
                <FlexGrid.Col xs={12} md={4}>
                  <FormattedCost option={option} />
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
          </div>
        </CheckboxCard>
      ))}
    </StackView>
  );
};

const OptionsSection = ({ options }) => {
  const { setOptionSelection, optionsTracker } = useContext(DeviceContext);
  const [items, setItems] = useState([]);
  const [showOverflow, setShowOverflow] = useState(false);
  const [checkedId, setCheckedId] = useState('');

  useEffect(() => {
    if (optionsTracker.additionalOptions) {
      setCheckedId(
        optionsTracker.additionalOptions.find(
          (element) => element.id === options.id
        ).selection
      );
    }
  }, []);

  const formatOptions = () => {
    let newOptions = [];
    options.options.map((option, i) => {
      i < 3 || showOverflow
        ? (newOptions[i] = {
            title: '',
            id: option.id,
            content: (
              <OptionCard>
                <FlexGrid gutter={false}>
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={12} md={8}>
                      <FormattedOptions option={option} />
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={12} md={4}>
                      <FormattedCost option={option} />
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </FlexGrid>
              </OptionCard>
            ),
          })
        : '';
    });
    setItems(newOptions);
  };

  useEffect(() => {
    formatOptions();
  }, [showOverflow]);

  const handleChange = (id) => {
    console.log('options: ', options);
    setOptionSelection(
      options.id,
      id,
      options.options.find((obj) => obj.id === id),
      options.title
    );
    setCheckedId(id);
    // console.log(optionsTracker);
  };

  // console.log('options: ', options.type);

  return (
    <OptionsContainer>
      {options.type === 'checklist' ? (
        <CheckboxOptions options={options} />
      ) : (
        <>
          <RadioCardGroup
            items={items}
            checkedId={checkedId}
            tokens={{ direction: 'column' }}
            radioCardTokens={{ paddingBottom: '12px', paddingTop: '12px' }}
            onChange={handleChange}
          />
          {options.options.length > 3 ? (
            <>
              <Spacer space={3} />
              <TextButton
                onPress={() => setShowOverflow(!showOverflow)}
                tokens={{ color: '#4B286D' }}
              >
                {showOverflow ? 'Show less options' : 'Show all options'}
                <Spacer space={2} direction="row" />
                {showOverflow ? (
                  <Icon
                    icon={CaretUp}
                    variant={{ size: 'small', rank: 'primary' }}
                    tokens={{ translateY: '4' }}
                  />
                ) : (
                  <Icon
                    icon={CaretDown}
                    variant={{ size: 'small', rank: 'primary' }}
                    tokens={{ translateY: '4' }}
                  />
                )}
              </TextButton>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </OptionsContainer>
  );
};

export default OptionsSection;
