import { Box, Divider } from '@telus-uds/components-web';
import {
  RadioCardGroup,
  StackWrap,
  StackView,
  Typography,
  Spacer,
  Image,
  Paragraph,
  FlexGrid,
  ButtonGroup,
  Select,
  ExpandCollapseMini,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { Check } from '@telus-uds/palette-allium/build/web/icons';
import { DeviceContext } from 'context/DeviceContext';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

const ColorRadioContainer = styled.div`
  display: flex;
`;

const ColorOption = styled.button`
  display: inline-block;
  position: relative;
  min-width: 0;
  padding: 0;
  width: auto;
  min-height: 0;
  margin: 8px 12px;
  border: 0;
  outline: none;
  text-decoration: none;
  overflow: visible;
  vertical-align: middle;
  background: #fff;
  cursor: pointer;
`;

const ColorSwatch = styled.span`
  display: block;
  width: 24px;
  min-height: 0;
  height: 24px;
  padding: 0;
  overflow: hidden;
  border-radius: 50%;
  text-decoration: none;
  line-height: 34px;
  pointer-events: none;
  color: transparent;
  font-size: 14px;
  background-color: ${(props) => (props.color ? props.color : '#fff')};
`;

const SelectedOption = styled(ColorOption)`
  &::before {
    content: '';
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 1px solid #54595f;
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

const OptionsContainer = styled.div`
  display: block;
  > fieldset > div > div > div > div > div:last-child {
    flex-grow: 1 !important;
  }
`;

const OptionCard = styled.div`
  flex: 1;
`;

const DollarSign = styled.span`
  font-size: 12px;
  vertical-align: super;
  margin: 0 1px;
`;

const ColorRadioOptions = ({
  options,
  sectionKey,
  sectionId,
  handleChange,
}) => {
  const { optionsTracker } = useContext(DeviceContext);
  const [selectedColor, setSelectedColor] = useState(options[0]);

  useEffect(() => {
    if (optionsTracker.deviceOptions) {
      let trackerColorId = optionsTracker.deviceOptions.find(
        (element) => element.id === sectionId
      ).selection;
      if (trackerColorId) {
        setSelectedColor(
          options.find((element) => element.id === trackerColorId)
        );
      } else {
        setSelectedColor(options[0]);
        handleChange(
          sectionKey,
          sectionId,
          selectedColor.id,
          options[0],
          options.title
        );
      }
    }
  }, []);

  const handleClick = (color, id) => {
    setSelectedColor(color);
    handleChange(
      sectionKey,
      sectionId,
      id,
      options.find((obj) => obj.id === id),
      options.title
    );
  };

  return (
    <>
      <Typography variant={{ bold: true }}>
        Colour options: {selectedColor.title}
      </Typography>
      <Spacer space={2} />
      <ColorRadioContainer>
        {options.map((color, i) =>
          color.id === selectedColor.id ? (
            <SelectedOption
              title={color.title}
              id={color.id}
              type="button"
              onClick={() => handleClick(color, color.id)}
              key={i}
            >
              <ColorSwatch color={color.hex}></ColorSwatch>
            </SelectedOption>
          ) : (
            <ColorOption
              title={color.title}
              id={color.id}
              type="button"
              onClick={() => handleClick(color, color.id)}
              key={i}
            >
              <ColorSwatch color={color.hex}></ColorSwatch>
            </ColorOption>
          )
        )}
      </ColorRadioContainer>
      <Spacer space={4} />
    </>
  );
};

const DropdownOptions = ({ options, sectionKey, sectionId, handleChange }) => {
  const { optionsTracker } = useContext(DeviceContext);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (optionsTracker.deviceOptions) {
      setValue(
        optionsTracker.deviceOptions.find((element) => element.id === sectionId)
          .selection
      );
    }
  }, []);

  const handleOptionSelection = (key, sectionId, id) => {
    handleChange(
      key,
      sectionId,
      id,
      options.find((obj) => obj.id === id),
      options.title
    );
    setValue(id);
  };

  return (
    <Select
      placeholder="Please select..."
      value={value}
      onChange={(id) => handleOptionSelection(sectionKey, sectionId, id)}
    >
      {options.map((option, i) => (
        <Select.Item key={i} value={option.id}>
          {option.text}
        </Select.Item>
      ))}
    </Select>
  );
};

const HalfRadioOptions = ({ options, sectionKey, sectionId, handleChange }) => {
  const { optionsTracker } = useContext(DeviceContext);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    formatOptions();
    if (optionsTracker.deviceOptions) {
      setValue(
        optionsTracker.deviceOptions.find((element) => element.id === sectionId)
          .selection
      );
    }
  }, []);

  const handleOptionSelection = (key, sectionId, id) => {
    handleChange(
      key,
      sectionId,
      id,
      options.find((obj) => obj.id === id),
      options.title
    );
    setValue(id);
  };

  const formatOptions = () => {
    let newOptions = [];
    options.map((option, i) => {
      newOptions[i] = {
        title: '',
        id: `${option.id}`,
        content: (
          <OptionCard>
            <FlexGrid gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col xs={10}>
                  <StackView>
                    <Spacer space={{ options: { size: 1 } }} />
                    <Typography>{option.title}</Typography>
                  </StackView>
                </FlexGrid.Col>
                <FlexGrid.Col xs={2}>
                  <StackView>
                    <Spacer space={{ options: { size: 1 } }} />
                    {option.cost ? (
                      <StackWrap
                        space={0}
                        tokens={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography block variant={{ size: 'micro' }}>
                          $
                        </Typography>
                        <Typography>{option.cost}</Typography>
                      </StackWrap>
                    ) : (
                      ''
                    )}
                  </StackView>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
          </OptionCard>
        ),
      };
    });
    setItems(newOptions);
  };

  return (
    <OptionsContainer>
      <RadioCardGroup
        items={items}
        checkedId={value}
        onChange={(id) => handleOptionSelection(sectionKey, sectionId, id)}
        radioCardTokens={{ paddingBottom: '12px', paddingTop: '12px' }}
      />
    </OptionsContainer>
  );
};

const OfferDetails = ({ details }) => {
  return (
    <ExpandCollapseMini
      variant={{ alternative: true }}
      expandTitle="Hide details"
      collapseTitle="View details"
    >
      <Spacer space={5} />
      <Divider variant={{ decorative: true }} />
      <Box space={3}>
        <Typography variant={{ size: 'large' }}>
          Here's how it all breaks down
        </Typography>
      </Box>
      <Spacer space={3} />
      <StackWrap
        tokens={{
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Typography>Retail Price</Typography>
        <Typography>
          <DollarSign>$</DollarSign>
          {details.retailPrice.toFixed(2)}
        </Typography>
      </StackWrap>
      {details.discounts.map((discount, i) => (
        <StackWrap
          tokens={{
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
          key={i}
        >
          <Typography variant={{ bold: true }}>{discount.label}</Typography>
          <Typography>
            -<DollarSign>$</DollarSign>
            {discount.value.toFixed(2)}
          </Typography>
        </StackWrap>
      ))}
      <Spacer space={4} />
      <Divider variant={{ decorative: true }} />
      <Spacer space={5} />
      <StackWrap
        tokens={{
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Typography variant={{ bold: true }}>Total savings</Typography>
        <Typography variant={{ bold: true, colour: 'positive' }}>
          -<DollarSign>$</DollarSign>
          {details.totalSavings.toFixed(2)}
        </Typography>
      </StackWrap>
      <Spacer space={5} />
      <Divider variant={{ decorative: true }} />
      <Box variant={{ background: 'light' }} space={1}>
        <Spacer space={3} />
        <StackWrap
          tokens={{
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <Typography>Device repayment amount</Typography>
          <Typography>
            <DollarSign>$</DollarSign>
            {details.repaymentAmount.toFixed(2)}
          </Typography>
        </StackWrap>
        <StackWrap
          tokens={{
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <Typography variant={{ bold: true }}>Due today</Typography>
          <Typography>
            <DollarSign>$</DollarSign>
            {details.dueToday.toFixed(2)}
          </Typography>
        </StackWrap>
        <StackWrap
          tokens={{
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <Typography variant={{ bold: true }}>Due monthly</Typography>
          <Typography>
            <DollarSign>$</DollarSign>
            {details.dueMonthly.toFixed(2)}/mo.
          </Typography>
        </StackWrap>
      </Box>
    </ExpandCollapseMini>
  );
};

const FullRadioOptions = ({ options, sectionKey, sectionId, handleChange }) => {
  const { optionsTracker } = useContext(DeviceContext);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    formatOptions();
    if (optionsTracker.deviceOptions) {
      setValue(
        optionsTracker.deviceOptions.find((element) => element.id === sectionId)
          .selection
      );
    }
  }, []);

  const handleOptionSelection = (key, sectionId, id) => {
    handleChange(
      key,
      sectionId,
      id,
      options.find((obj) => obj.id === id),
      options.title
    );
    setValue(id);
  };

  const formatOptions = () => {
    let newOptions = [];
    options.map((option, i) => {
      newOptions[i] = {
        title: '',
        id: `${option.id}`,
        content: (
          <OptionCard>
            <FlexGrid gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col xs={9}>
                  <StackView>
                    <Spacer space={{ options: { size: 1 } }} />
                    {option.swapBold ? (
                      <Typography block>{option.title}</Typography>
                    ) : (
                      <Typography block variant={{ bold: true }}>
                        {option.title}
                      </Typography>
                    )}
                    {option.copy ? (
                      option.swapBold ? (
                        <Typography variant={{ bold: true }}>
                          {option.copy}
                        </Typography>
                      ) : (
                        <Typography>{option.copy}</Typography>
                      )
                    ) : null}
                    {option.greenText ? (
                      <>
                        <Spacer space={2} />
                        <Typography
                          tokens={{ color: palette.color.greenAccessible }}
                        >
                          {option.greenText}
                        </Typography>
                      </>
                    ) : null}
                    {option.checklist ? (
                      <>
                        <Spacer space={2} />
                        {option.checklist.map((checklistItem, i) => (
                          <FlexGrid key={i} gutter={false}>
                            <FlexGrid.Row>
                              <FlexGrid.Col xs={1}>
                                <Icon
                                  icon={Check}
                                  tokens={{
                                    color: palette.color.greenAccessible,
                                  }}
                                />
                              </FlexGrid.Col>
                              <FlexGrid.Col xs={11}>
                                <Paragraph block>{checklistItem}</Paragraph>
                              </FlexGrid.Col>
                            </FlexGrid.Row>
                          </FlexGrid>
                        ))}
                      </>
                    ) : null}
                  </StackView>
                </FlexGrid.Col>
                <FlexGrid.Col xs={3}>
                  <StackView>
                    {option.cost ? (
                      <StackWrap
                        space={0}
                        tokens={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography>$ </Typography>
                        <Typography
                          variant={{ size: 'h2', colour: 'secondary' }}
                        >
                          {option.cost.toFixed(2)}
                        </Typography>
                        {option.costPer ? (
                          <StackView>
                            <Spacer space={2} />
                            <Typography>/{option.costPer}</Typography>
                          </StackView>
                        ) : null}
                      </StackWrap>
                    ) : null}
                  </StackView>
                </FlexGrid.Col>
              </FlexGrid.Row>
              {option.offerDetails ? (
                <FlexGrid.Row>
                  <FlexGrid.Col>
                    <OfferDetails details={option.offerDetails} />
                  </FlexGrid.Col>
                </FlexGrid.Row>
              ) : null}
            </FlexGrid>
          </OptionCard>
        ),
      };
    });
    setItems(newOptions);
  };

  return (
    <OptionsContainer>
      <RadioCardGroup
        items={items}
        checkedId={value}
        tokens={{ direction: 'column' }}
        radioCardTokens={{ paddingBottom: '12px', paddingTop: '12px' }}
        onChange={(id) => handleOptionSelection(sectionKey, sectionId, id)}
      />
    </OptionsContainer>
  );
};

const DeviceOptions = ({ options }) => {
  const { optionsTracker, setOptionSelection, setCurrentStep } =
    useContext(DeviceContext);
  const [optionsTrackerLocal, setOptionsTrackerLocal] = useState([]);
  const [sizeValue, setSizeValue] = useState(null);

  useEffect(() => {
    let newOptionsTrackerLocal = [];
    options.options.map((optionSet, i) => {
      newOptionsTrackerLocal[i] = false;
    });
    setOptionsTrackerLocal(newOptionsTrackerLocal);
    if (optionsTracker.deviceOptions) {
      if (options.options.find((element) => element.type === 'size')) {
        setSizeValue(
          optionsTracker.deviceOptions.find(
            (element) =>
              element.id ===
              options.options.find((element) => element.type === 'size').id
          ).selection
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      optionsTrackerLocal.length > 0 &&
      optionsTrackerLocal.every(Boolean) &&
      !options.byodPlan
    ) {
      setCurrentStep(1);
    }
  }, [optionsTrackerLocal]);

  const checkOptionsTrackerLocal = (
    sectionKey,
    optionId,
    optionSelection,
    option,
    category
  ) => {
    let newOptionsTrackerLocal = [...optionsTrackerLocal];
    newOptionsTrackerLocal[sectionKey] = true;
    setOptionsTrackerLocal(newOptionsTrackerLocal);
    setOptionSelection(optionId, optionSelection, option, category);
  };

  const handleSizeChange = (key, id, selection, options) => {
    checkOptionsTrackerLocal(key, id, selection, options, 'Size Change');
    setSizeValue(selection);
  };

  return (
    <StackView>
      {options.brand ? (
        <>
          <Typography block variant={{ bold: true }}>
            {options.brand}
          </Typography>
          <Spacer space={2} />
        </>
      ) : null}
      {options.name ? (
        <>
          <Typography block variant={{ size: 'h2' }}>
            {options.name}
          </Typography>
          <Spacer space={4} />
        </>
      ) : null}
      {options.byodPlan ? (
        <Spacer space={3} />
      ) : (
        <>
          <StackWrap>
            {options.image ? (
              <>
                <Image
                  src={`../assets${options.image}`}
                  alt={`image of ${options.name}`}
                  height={330}
                  width={270}
                />
                <Spacer space={8} direction="row" />
              </>
            ) : null}
            <StackView tokens={{ justifyContent: 'flex-end' }}>
              {options.options
                ? options.options.map((optionSet, i) =>
                    optionSet.type === 'size' ? (
                      <div key={i}>
                        <Typography variant={{ bold: true }}>
                          Options
                        </Typography>
                        <Spacer space={2} />
                        <ButtonGroup
                          items={optionSet.options}
                          values={[sizeValue]}
                          onChange={(id) =>
                            handleSizeChange(
                              i,
                              optionSet.id,
                              id[0],
                              optionSet.options
                            )
                          }
                        />
                        <Spacer space={4} />
                      </div>
                    ) : (
                      ''
                    )
                  )
                : ''}
              {options.options
                ? options.options.map((optionSet, i) =>
                    optionSet.type === 'color' ? (
                      <ColorRadioOptions
                        options={optionSet.options}
                        key={i}
                        sectionKey={i}
                        sectionId={optionSet.id}
                        handleChange={checkOptionsTrackerLocal}
                      />
                    ) : (
                      ''
                    )
                  )
                : ''}
              {options.availabilityText ? (
                <StackWrap>
                  <Typography block variant={{ bold: true }}>
                    Stock:{' '}
                  </Typography>
                  {options.available ? (
                    <Typography
                      tokens={{ color: palette.color.greenAccessible }}
                    >
                      {options.availabilityText}
                    </Typography>
                  ) : (
                    <Typography tokens={{ color: palette.color.red }}>
                      {options.availabilityText}
                    </Typography>
                  )}
                </StackWrap>
              ) : null}
            </StackView>
          </StackWrap>
          <Spacer space={10} />
        </>
      )}
      {options.options
        ? options.options.map((optionSet, i) =>
            optionSet.type !== 'color' && optionSet.type !== 'size' ? (
              <StackView key={i}>
                {optionSet.instructions ? (
                  <Typography block variant={{ bold: true }}>
                    {optionSet.instructions}
                  </Typography>
                ) : null}
                {optionSet.type === 'dropdown' ? (
                  <DropdownOptions
                    options={optionSet.options}
                    sectionKey={i}
                    sectionId={optionSet.id}
                    handleChange={checkOptionsTrackerLocal}
                  />
                ) : optionSet.type === 'half-radio' ? (
                  <HalfRadioOptions
                    options={optionSet.options}
                    sectionKey={i}
                    sectionId={optionSet.id}
                    handleChange={checkOptionsTrackerLocal}
                  />
                ) : optionSet.type === 'full-radio' ? (
                  <FullRadioOptions
                    options={optionSet.options}
                    sectionKey={i}
                    sectionId={optionSet.id}
                    handleChange={checkOptionsTrackerLocal}
                  />
                ) : null}
                <Spacer space={6} />
              </StackView>
            ) : (
              ''
            )
          )
        : ''}
    </StackView>
  );
};

export default DeviceOptions;
