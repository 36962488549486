import React, { createContext, useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CONFIRMATION } from 'constants/routes';
import { exampleOrderHistoryList } from 'context/exampleOrderHistoryData';
import { exampleOrderApprovalList } from 'context/exampleApprovalData';

export const HistoryContext = createContext(null);

export const HistoryProvider = ({ children }) => {
  const [orderHistory, setOrderHistory] = useState(
    exampleOrderHistoryList.data
  );
  const [orderHistoryHeaders, setOrderHistoryHeaders] = useState(
    exampleOrderHistoryList.headers
  );
  const [orderHistoryValues, setOrderHistoryValues] = useState([]);
  const [orderComplete, setOrderComplete] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});

  const [orderApproval, setOrderApproval] = useState(
    exampleOrderApprovalList.data
  );
  const [orderApprovalHeaders, setOrderApprovalHeaders] = useState(
    exampleOrderApprovalList.headers
  );
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    let newHistoryValues = [];
    orderHistory.map((order) => {
      order.map((data, i) => {
        if (Array.isArray(data.value)) {
          data.value.map((indValue) => {
            let newValue = indValue.toString();
            let newArrayItem = {
              header: data.id,
              value: newValue,
            };
            if (
              !newHistoryValues.some((e) => e.header === newArrayItem.header) ||
              !newHistoryValues.some((e) => e.value === newArrayItem.value)
            ) {
              newHistoryValues.push(newArrayItem);
            }
          });
        } else {
          let newValue = data.value.toString();
          let newArrayItem = {
            header: data.id,
            value: newValue,
          };
          if (
            !newHistoryValues.some((e) => e.header === newArrayItem.header) ||
            !newHistoryValues.some((e) => e.value === newArrayItem.value)
          ) {
            newHistoryValues.push(newArrayItem);
          }
        }
      });
    });
    newHistoryValues.sort((a, b) => a.value.localeCompare(b.value));
    setOrderHistoryValues(newHistoryValues);
    // console.log('autocomplete values: ', newHistoryValues);
  }, [orderHistory]);

  const initializeOrderDetails = (details) => {
    setOrderDetails(details);
    setOrderComplete(details.orderStatus === 'Complete');
  };

  const setOrderComments = (comments) => {
    let newOrderDetails = { ...orderDetails };
    newOrderDetails.comments = comments;
    setOrderDetails(newOrderDetails);
  };

  const setOrderApproverComments = (comments) => {
    let newOrderDetails = { ...orderDetails };
    newOrderDetails.approverComments = comments;
    setOrderDetails(newOrderDetails);
  };

  const setOrderPaymentDetails = (details) => {
    let newOrderDetails = { ...orderDetails };
    newOrderDetails.paymentInformation = details;
    setOrderDetails(newOrderDetails);
    console.log(newOrderDetails);
  };

  const setOrderStatus = (status) => {
    let newOrderDetails = { ...orderDetails };
    newOrderDetails.orderStatus = status;
    setOrderDetails(newOrderDetails);
  };

  const activateToast = () => {
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  // All accessible variables
  const value = useMemo(() => {
    return {
      orderHistory,
      setOrderHistory,
      orderHistoryHeaders,
      setOrderHistoryHeaders,
      orderHistoryValues,
      orderComplete,
      setOrderComplete,
      orderDetails,
      initializeOrderDetails,
      setOrderComments,
      setOrderApproverComments,
      setOrderPaymentDetails,
      orderApproval,
      setOrderApproval,
      orderApprovalHeaders,
      setOrderApprovalHeaders,
      setOrderStatus,
      toastVisible,
      activateToast,
    };
  }, [
    orderHistory,
    orderHistoryHeaders,
    orderHistoryValues,
    orderComplete,
    orderDetails,
    orderApproval,
    orderApprovalHeaders,
    toastVisible,
  ]);

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
