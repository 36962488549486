import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Divider,
  Box,
  Icon,
  TextArea,
  Modal,
  BaseModal,
} from '@telus-uds/ds-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { exampleOrderDetails } from 'context/exampleOrderHistoryData';
import { exampleCompletedOrderDetails } from 'context/exampleOrderHistoryData';
import { HistoryContext } from 'context/HistoryContext';
import OrderDetails from 'components/OrderDetails';
import { useNavigate } from 'react-router-dom';
import { ORDER_HISTORY, ORDER_RETURN } from 'constants/routes';
import OrderInformation from 'components/OrderInformation';

const OrderDetailsPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const CommentBox = () => {
  const { orderDetails, setOrderComments } = useContext(HistoryContext);
  const [boxOpen, setBoxOpen] = useState(false);

  return (
    <StackView>
      <Box vertical={5}>
        <StackWrap
          tokens={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
            Comments
          </Typography>
          <StackWrap tokens={{ alignItems: 'center' }}>
            <TextButton onPress={() => setBoxOpen(!boxOpen)}>
              {boxOpen ? (
                <Icon icon={CaretUp} variant={{ size: 'small' }} />
              ) : (
                <Icon icon={CaretDown} variant={{ size: 'small' }} />
              )}
            </TextButton>
            <Spacer space={1} direction="row" />
          </StackWrap>
        </StackWrap>
      </Box>
      {boxOpen ? (
        <Box vertical={3}>
          <FlexGrid outsideGutter={false} limitWidth={false}>
            <FlexGrid.Row>
              <FlexGrid.Col xs={12} sm={10} md={8}>
                <TextArea
                  label="Submitter's comments"
                  value={orderDetails.comments}
                  onChange={setOrderComments}
                />
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
          <Spacer space={5} />
        </Box>
      ) : (
        ''
      )}
    </StackView>
  );
};

const CancelModal = ({ order, destination }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const navigate = useNavigate();

  const handleCancel = () => {
    alert(`Cancelling order ${order.orderNumber}`);
    console.log(`Order ${order.orderNumber} cancelled`);
    console.log(`Reason: ${cancellationReason}`);
    setModalOpen(false);
    destination ? navigate(destination) : null;
  };

  return (
    <>
      <Button
        onPress={() => setModalOpen(true)}
        variant={{ priority: 'high' }}
        tokens={{ fontWeight: 400 }}
      >
        Cancel this order
      </Button>
      <BaseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Box space={3}>
          <Typography block variant={{ size: 'h1' }}>
            Cancel my order
          </Typography>
          <Box vertical={5}>
            <Typography>
              Please enter a reason & confirm the cancellations:
            </Typography>
            <Spacer space={5} />
            <TextArea
              value={cancellationReason}
              onChange={setCancellationReason}
            />
          </Box>
          <Spacer space={4} />
          <Box vertical={3}>
            <StackWrap
              space={6}
              direction="row-reverse"
              tokens={{ alignItems: 'center' }}
            >
              <Button
                variant={{ priority: 'high' }}
                onPress={() => handleCancel()}
                inactive={cancellationReason.length < 1}
              >
                Yes, cancel my order
              </Button>
              <StackView>
                <TextButton
                  variant={{ alternative: true }}
                  onPress={() => setModalOpen(false)}
                >
                  No, do not cancel my order
                </TextButton>
              </StackView>
            </StackWrap>
          </Box>
        </Box>
      </BaseModal>
    </>
  );
};

const OrderDetailsPage = () => {
  const { orderComplete, orderDetails, initializeOrderDetails, orderHistory } =
    useContext(HistoryContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      orderHistory
        .find((obj) => obj[0].value === window.location.hash.substring(1))
        .find((obj) => obj.id === 'status').value === 'Complete'
    ) {
      initializeOrderDetails(exampleCompletedOrderDetails);
    } else {
      initializeOrderDetails(exampleOrderDetails);
    }
  }, []);

  const handlePrint = () => {
    alert('Printing order details');
  };

  const handleReturn = () => {
    alert('Returning order');
    navigate(`${ORDER_RETURN}#${orderDetails.orderNumber}`);
  };

  const OrderControls = () => {
    return (
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton onPress={handlePrint} variant={{ alternative: true }}>
            Print
          </TextButton>
        </StackView>
        <Spacer space={4} direction="row" />
        {orderComplete ? (
          <Button
            onPress={handleReturn}
            variant={{ priority: 'high' }}
            tokens={{ fontWeight: 400 }}
          >
            Return items
          </Button>
        ) : (
          <CancelModal order={orderDetails} destination={ORDER_HISTORY} />
        )}
      </StackWrap>
    );
  };

  return (
    <OrderDetailsPageContainer>
      <Spacer space={8} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            {/* {window.location.hash
              ? `Order # ${window.location.hash.substring(1)}`
              : 'no order loaded'} */}
            Order # {orderDetails.orderNumber}
          </Typography>
          <OrderControls />
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <OrderInformation />
      <OrderDetails />
      {!orderComplete ? (
        <>
          <Divider />
          <CommentBox />
          <Divider />
          <Spacer space={8} />
          <StackWrap tokens={{ justifyContent: 'flex-end' }}>
            <OrderControls />
          </StackWrap>
        </>
      ) : null}
      <Spacer space={10} />
    </OrderDetailsPageContainer>
  );
};

export default OrderDetailsPage;
