import React, { useContext, useState, useEffect } from 'react';
import {
  Spacer,
  StackView,
  StackWrap,
  Typography,
  FlexGrid,
  Image,
  Button,
  TextButton,
  PriceLockup,
  QuantitySelector,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import styled from 'styled-components';
import { DeviceContext } from 'context/DeviceContext';

const AccessoryContainer = styled.div`
  position: relative;
  min-height: ${(props) =>
    props.extend && props.multiple ? '616px' : '550px'};
  height: auto;
  // width: 270px;
  margin-top: 3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MarginContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: calc(100% - 3rem);
  flex-grow: 1;
`;

const AccessoryImage = styled.div`
  display: block;
  > img {
    height: 200px;
    width: auto;
  }
`;

const AccessoryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-direction: column;
`;

const DiscountPrice = styled.div`
  > div > div > div {
    font-size: 10px !important;
    line-height: 22px !important;
    > div {
      font-size: 20px !important;
      line-height: 30px !important;
    }
  }
`;

const AccessoryCard = ({ deviceInfo, multiple }) => {
  const { orderAccessories, addAccessories, removeAccessories } =
    useContext(DeviceContext);
  const [deviceInOrder, setDeviceInOrder] = useState(false);

  useEffect(() => {
    if (orderAccessories) {
      // console.log(deviceInfo.id);
      if (orderAccessories.find((obj) => obj.id === deviceInfo.id)) {
        setDeviceInOrder(true);
      } else {
        setDeviceInOrder(false);
      }
    } else {
      setDeviceInOrder(false);
    }
  }, [orderAccessories]);

  const onsubmit = () => {
    // alert(`${deviceKey} added to order!`);
    addAccessories(deviceInfo.cost, deviceInfo.id);
    // console.log('accessories in order: ', orderAccessories);
  };

  const handleQuantityChange = (amount) => {
    let quantity = orderAccessories.find(
      (obj) => obj.id === deviceInfo.id
    ).quantity;
    if (amount > quantity) {
      addAccessories(deviceInfo.cost, deviceInfo.id);
    } else {
      removeAccessories(deviceInfo.cost, deviceInfo.id);
    }
  };

  return (
    <AccessoryContainer extend={deviceInOrder} multiple={multiple}>
      <MarginContainer>
        <FlexGrid gutter={false}>
          <FlexGrid.Row verticalAlign="bottom">
            <FlexGrid.Col xs={12} horizontalAlign="center">
              <AccessoryImage>
                <Image
                  src={`../assets${deviceInfo.image}`}
                  alt={`image of ${deviceInfo.name}`}
                  height={200}
                  width={200}
                />
              </AccessoryImage>
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
        <AccessoryInfo>
          <StackView>
            <Spacer space={2} />
            <Typography block variant={{ size: 'eyebrow' }}>
              {deviceInfo.brand}
            </Typography>
            <Typography
              block
              variant={{ size: 'large' }}
              tokens={{ color: palette.color.purpleTelus }}
            >
              {deviceInfo.name}
            </Typography>
            <Spacer space={1} />
            {deviceInfo.available ? (
              <Typography
                block
                variant={{ size: 'small' }}
                tokens={{ color: palette.color.greenAccessible }}
              >
                {deviceInfo.availabilityText}
              </Typography>
            ) : (
              <Typography
                block
                variant={{ size: 'small' }}
                tokens={{ color: palette.color.red }}
              >
                {deviceInfo.availabilityText}
              </Typography>
            )}
            <Spacer space={3} />
          </StackView>
          <StackView>
            <StackWrap>
              <PriceLockup size="small" price={deviceInfo.cost.toFixed(2)} />
              {deviceInfo.discountedFrom ? (
                <DiscountPrice>
                  <PriceLockup
                    strikeThrough
                    a11yText={`was ${deviceInfo.discountedFrom} before discount`}
                    size="small"
                    price={deviceInfo.discountedFrom.toFixed(2)}
                  />
                </DiscountPrice>
              ) : null}
            </StackWrap>
            <Spacer space={3} />
            {orderAccessories.find((obj) => obj.id === deviceInfo.id) &&
            !multiple ? null : (
              <>
                <Button
                  variant={{ priority: 'high' }}
                  inactive={deviceInOrder}
                  onPress={() => onsubmit()}
                >
                  Add to order
                </Button>
                <Spacer space={2} />
              </>
            )}
            {orderAccessories.find((obj) => obj.id === deviceInfo.id) ? (
              multiple ? (
                <QuantitySelector
                  defaultValue={
                    orderAccessories.find((obj) => obj.id === deviceInfo.id)
                      .quantity
                  }
                  onChange={(amount) => handleQuantityChange(amount)}
                  variant={{ alternative: true }}
                  maxNumber={
                    deviceInfo.stockLimit ? deviceInfo.stockLimit : null
                  }
                />
              ) : (
                <>
                  <Button onPress={() => handleQuantityChange(0)}>
                    Remove
                  </Button>
                  <Spacer space={2} />
                </>
              )
            ) : null}
            <Spacer space={1} />
          </StackView>
        </AccessoryInfo>
      </MarginContainer>
    </AccessoryContainer>
  );
};

export default AccessoryCard;
