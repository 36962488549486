export const exampleSubscriberList = [
  {
    id: 'subscriber-1',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil1' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(223) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-2',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-3',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-4',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(323) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-5',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-6',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-7',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(323) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-8',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-9',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-10',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(423) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-11',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(153) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-12',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-13',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-14',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(163) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-15',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-16',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-17',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(193) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-18',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-19',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-20',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-21',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-22',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-23',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-24',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(163) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-25',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-26',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-27',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(193) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-28',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-29',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-30',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-31',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-32',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-33',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-34',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(163) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-35',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-36',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-37',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(193) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-38',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-39',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-40',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-41',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-42',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-43',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-44',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(163) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-45',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-46',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-47',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(193) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-48',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
  {
    id: 'subscriber-49',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 57.0 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$57.00' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$57.00',
      },
    },
  },
  {
    id: 'subscriber-50',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-51',
    details: [
      { id: 'subscriber-name', value: 'Sandip Patil' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '(623) 456-0000' },
      { id: 'upgrade-fee', value: 600.45 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'Complete' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$600.45' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$600.45',
      },
    },
  },
  {
    id: 'subscriber-52',
    details: [
      { id: 'subscriber-name', value: 'Name Surname' },
      { id: 'group-name', value: 'Upgrade Group 1 Apple iPhone 14 Pro Max' },
      { id: 'phone-number', value: '' },
      { id: 'upgrade-fee', value: 399.99 },
      { id: 'upgrade-offers', value: 'Upgrade Offers' },
      { id: 'status', value: 'In progress' },
    ],
    feeBreakdown: {
      items: [
        { label: 'Easy Pay balance', value: 'NA' },
        { label: 'Device balance', value: '$399.99' },
      ],
      total: {
        label: 'Total upgrade fee',
        value: '$399.99',
      },
    },
  },
];

export const exampleSubscriberHeaders = [
  { id: 'subscriber-name', label: 'Subscriber name' },
  { id: 'group-name', label: 'Group name' },
  { id: 'phone-number', label: 'Phone number' },
  { id: 'upgrade-fee', label: 'Upgrade fee*', type: 'price' },
  {
    id: 'upgrade-offers',
    label: 'Upgrade Offers',
    tooltip: 'This is where a tooltip would go, if I had one',
    type: 'buttons',
  },
  { id: 'status', label: 'Status' },
];

export const exampleUpgradeHeaders = [
  { id: 'subscriber-name', label: 'Subscriber name' },
  { id: 'phone-number', label: 'Phone number' },
  { id: 'upgrade-fee', label: 'Upgrade fee*', type: 'price' },
  {
    id: 'upgrade-offers',
    label: 'Upgrade Offers',
    // tooltip: 'This is where a tooltip would go, if I had one',
    modal: true,
    type: 'buttons',
  },
  { id: 'status', label: 'Status' },
];

export const exampleUpgradeBundlesList = [
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle_2023-01-17 13:46:06',
    id: 'apple-iphone-01',
    type: 'upgrade',
    availability: '3-5 days',
    dueMonthly: 50,
    dueNow: 388.99,
    notes: '',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle',
    id: 'apple-iphone-02',
    type: 'upgrade',
    availability: 'Expired',
    dueMonthly: 0,
    dueNow: 0,
    notes:
      'This bundle expired because one or more items are no longer available',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle',
    id: 'apple-iphone-03',
    type: 'upgrade',
    availability: 'Expired',
    dueMonthly: 0,
    dueNow: 0,
    notes:
      'This bundle expired because one or more items are no longer available',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
];

export const exampleBundleConfiguration = {
  configurations: [
    {
      instructions: 'Select an extended warranty',
      id: 'extended-warranty',
      options: [
        {
          title: 'No',
          id: 'no-warranty',
        },
        {
          title: 'Device Care Complete',
          id: 'dc-complete-01',
          cost: 15,
          costPer: 'month',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
        {
          title: 'Device Care Complete',
          id: 'dc-complete-02',
          cost: 15,
          costPer: 'month',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
      ],
    },
  ],
  confirmation: 'I have read the information below regarding Extended Warranty',
  information: [
    'Only one Extended Warranty product can be added to a device subscriber. If you have an Extended Warranty product, we may have to cancel it to activate the new one, and will receive a cancellation notice.',
    'Existing Device Care Complete coverage will be transferred to the new device/subscriber.',
    'AppleCare + will not be added to any device subscriber that is currently covered by Device Care Complete.',
  ],
};

export const exampleMyBundlesList = [
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle_2023-01-17 13:46:06',
    id: 'apple-iphone-01',
    type: 'new',
    availability: '3-5 days',
    dueMonthly: 50,
    dueNow: 388.99,
    notes: '',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle',
    id: 'apple-iphone-02',
    type: 'new',
    availability: 'Expired',
    dueMonthly: 0,
    dueNow: 0,
    notes:
      'This bundle expired because one or more items are no longer available',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
  {
    name: 'Apple iPhone 14 Pro Max Upgrade Bundle',
    id: 'apple-iphone-03',
    type: 'upgrade',
    availability: 'Expired',
    dueMonthly: 0,
    dueNow: 0,
    notes:
      'This bundle expired because one or more items are no longer available',
    description: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
    details: [
      {
        label: 'Term',
        value: '3 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 50',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $200.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
  {
    name: 'Samsung Galaxy BYOD Bundle',
    id: 'samsung-galaxy-byod',
    type: 'byod',
    availability: 'Available',
    dueMonthly: 20,
    dueNow: 0,
    description: 'SAMSUNG GALAXY PRO MAX 32GB BLACK',
    details: [
      {
        label: 'Term',
        value: '2 year',
      },
      {
        label: 'Plan',
        value: 'Corporate Complete 75',
      },
      {
        label: 'Promotions',
        value:
          'A credit up to $100.00 will be applied to your bill upon renewal',
      },
      {
        label: 'Accessories',
        value: 'BELKIN USB-C GAN 20W WALL CHARGER WHITE',
      },
      {
        label: 'SIM',
        value: 'TELUS LTE TRI SIM',
      },
      {
        label: 'Apple DEP',
        value: 'a charge of $20 per subscriber will be applied to your bill',
      },
    ],
  },
];

export const exampleBundleFooterData = {
  dueNow: '89.99',
  dueMonthly: '57.50',
  // poolProgram: 2,
  // floatBalance: 99,
};

export const exampleNewSubscriberTemplate = {
  // fields array is for optional subscriber info to be adjusted
  // all shown as text fields
  fields: [
    { id: 'subscriber-name', label: 'Name', required: true },
    { id: 'address', label: 'Address', required: true },
    { id: 'apt-suite', label: 'Apt/Suite', required: false },
    { id: 'city', label: 'City', required: true },
    {
      id: 'province',
      label: 'Province',
      required: true,
      options: [
        { id: 'ON', label: 'Ontario' },
        { id: 'QC', label: 'Quebec' },
        { id: 'NS', label: 'Nova Scotia' },
        { id: 'NB', label: 'New Brunswick' },
        { id: 'MB', label: 'Manitoba' },
        { id: 'BC', label: 'British Columbia' },
        { id: 'PE', label: 'Prince Edward Island' },
        { id: 'SK', label: 'Saskatchewan' },
        { id: 'AB', label: 'Alberta' },
        { id: 'NL', label: 'Newfoundland and Labrador' },
        { id: 'NT', label: 'Northwest Territories' },
        { id: 'YT', label: 'Yukon' },
        { id: 'NU', label: 'Nunavut' },
      ],
    },
    { id: 'postal-code', label: 'Postal Code', required: true },
    { id: 'phone-number', label: 'Phone Number', required: true },
    {
      id: 'notes',
      label: 'Shipping notes (Company Name, Special notes, etc)',
      required: false,
    },
  ],
  // available bans for adding subscribers
  bans: ['31099749', '32089977', '30047887', '33309787', '30199479'],
  // available languages for subscribers voicemail options
  languages: ['English', 'French'],
  // available area codes for subscriber
  areaCodes: ['604', '778', '809', '998', '889'],
  // if the added subscriber will be a hierarchy customer
  // set available departments here
  departments: [
    { id: 'hr', label: 'Human resources' },
    { id: 'comm', label: 'Communications' },
    { id: 'dept3', label: 'Some third department' },
  ],
  // set data which will be applied to a new subscriber
  // not set by user
  data: [
    { id: 'upgrade-fee', value: 320.5 },
    { id: 'upgrade-offers', value: 'Upgrade Offers' },
    { id: 'status', value: 'Standby' },
    { id: 'group-name', value: 'New group' },
  ],
};
