import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Icon,
  ButtonDropdown,
  Modal,
  Box,
} from '@telus-uds/components-web';
import { ChevronRight } from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  BYOD_PLANS,
  DEVICE_SELECT,
  DEVICE_SUBSCRIBERS,
  UPGRADES,
} from 'constants/routes';
import BundleDetails from 'components/BundleDetails';
import { SubscribersContext } from 'context/SubscribersContext';
import {
  exampleBundleConfiguration,
  exampleBundleFooterData,
  exampleMyBundlesList,
  exampleSubscriberList,
} from 'context/exampleSubscriberData';
import palette from '@telus-uds/palette-allium';
import { DeviceContext } from 'context/DeviceContext';
import ChooseBundleModal from 'components/ChooseBundleModal';

const MyBundlesPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  > div:nth-child(2) {
    z-index: 20;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const DropdownButtonContainer = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  width: 218px;

  > div:first-of-type {
    width: 100% !important;

    > div {
      width: 218px;
      justify-content: space-between;
    }
  }
`;

const DropdownBox = styled.div`
  position: absolute;
  top: 100%;
  border-radius: 4px;
  border: 1px solid ${palette.color.greyShuttle};
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(26, 0, 0, 0.25);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  width: 200px;
  z-index: 20;
`;

const DropdownLink = styled.a`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const MyBundlesPage = () => {
  const {
    initializeBundleData,
    selectedBundle,
    removeSelectedBundle,
    setSubscriberData,
  } = useContext(SubscribersContext);
  const { setCartFooterData } = useContext(DeviceContext);
  const [navOpen, setNavOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initializeBundleData(exampleMyBundlesList);
  }, []);

  const handleRemove = () => {
    removeSelectedBundle();
    setModalOpen(false);
  };

  const handleChoose = () => {
    alert(`Bundle ${selectedBundle} chosen!`);
    setCartFooterData(exampleBundleFooterData);
    setSubscriberData(exampleSubscriberList);
    navigate(DEVICE_SUBSCRIBERS);
  };

  const OrderControls = () => {
    return (
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton
            onPress={() => setModalOpen(true)}
            variant={{ alternative: true }}
          >
            Remove bundle
          </TextButton>
          <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            <Typography block variant={{ size: 'h1' }}>
              Confirmation
            </Typography>
            <Spacer space={5} />
            <Typography>
              Are you sure you want to remove the selected bundle?
            </Typography>
            <Spacer space={5} />
            <Box vertical={4}>
              <StackWrap
                space={6}
                direction="row-reverse"
                tokens={{ alignItems: 'center' }}
              >
                <Button
                  variant={{ priority: 'high' }}
                  onPress={() => handleRemove()}
                >
                  Yes
                </Button>
                <StackView>
                  <TextButton
                    variant={{ alternative: true }}
                    onPress={() => setModalOpen(false)}
                  >
                    No
                  </TextButton>
                </StackView>
              </StackWrap>
            </Box>
          </Modal>
        </StackView>
        <Spacer space={4} direction="row" />
        <DropdownButtonContainer>
          <ButtonDropdown
            label="Add new bundle"
            initialValue={navOpen}
            selected={navOpen}
            onChange={setNavOpen}
          />
          {navOpen ? (
            <DropdownBox>
              <DropdownLink onClick={() => navigate(DEVICE_SELECT)}>
                <Typography>Device bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
              <DropdownLink onClick={() => navigate(BYOD_PLANS)}>
                <Typography>BYOD bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
              <DropdownLink onClick={() => navigate(UPGRADES)}>
                <Typography>Upgrade bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
            </DropdownBox>
          ) : null}
        </DropdownButtonContainer>
      </StackWrap>
    );
  };

  return (
    <MyBundlesPageContainer>
      <Spacer space={10} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            My bundles
          </Typography>
          <OrderControls />
        </StackWrap>
        <Spacer space={7} />
      </StackView>
      <BundleDetails />
      <StackWrap direction="row-reverse">
        {/* <Button
          variant={{ priority: 'high' }}
          onPress={() => handleChoose()}
          inactive={!selectedBundle}
        >
          Choose Bundle
        </Button> */}
        <ChooseBundleModal
          active={selectedBundle}
          configurationData={exampleBundleConfiguration}
          handleChoose={handleChoose}
        />
      </StackWrap>
      <Spacer space={10} />
    </MyBundlesPageContainer>
  );
};

export default MyBundlesPage;
