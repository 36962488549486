export const exampleOrderApprovalList = {
  headers: [
    { label: 'Order ID (#)', id: 'order-number' },
    { label: 'Submitted by', id: 'submitter' },
    { label: 'Date Submitted', id: 'date-submitted' },
    { label: 'Order Type', id: 'type' },
    { label: 'Status', id: 'status' },
    { label: 'Approver', id: 'approver' },
    // { label: 'Phone Number', id: 'phone-number' },
    // { label: 'IMEI', id: 'imei' },
  ],
  filters: [
    { label: 'Subscriber name', id: 'subscriber-name' },
    { label: 'Phone number', id: 'phone-number' },
    { label: 'Order number', id: 'order-number' },
    { label: 'IMEI', id: 'imei' },
    { label: 'Serial number', id: 'serial-number' },
    { label: 'PO number', id: 'po-number' },
  ],
  data: [
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870044', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1132.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1367.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1967.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99434365'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99234043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99434365'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99320043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person four', 'person five'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99877765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person four', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99587765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99823043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870423', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99412365'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99832043', '99437125'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99830043', '99437325'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['91540043', '9947665'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55299'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55539'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55119'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55339'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55519'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55349'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['56669'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55539'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55199'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55321'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544fd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cc'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544re'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4534cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['454ccd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['45hgcd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9989'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-373-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-377-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-337-7999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-377-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-373-9799'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['477-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-353-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['455-355-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-335-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-335-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/approval-view/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
      { id: 'approver', value: '-' },
    ],
  ],
};
