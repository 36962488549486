import {
  ACCESSORIES,
  DEVICE_ACCESSORIES,
  DEVICE_SUBSCRIBERS,
  UPGRADES,
} from 'constants/routes';
import {
  exampleAccessoriesState,
  exampleCartFooterData,
  exampleDeviceOptions,
  exampleExpandedDeviceOptions,
  exampleUserInfo,
} from 'context/exampleDeviceData';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const DeviceContext = createContext(null);

//Specify which pages have the footer cart ui active
const pagesWithCartFooter = [
  // DEVICE_ACCESSORIES,
  DEVICE_SUBSCRIBERS,
  // ACCESSORIES,
  UPGRADES,
];

export const DeviceProvider = ({ children }) => {
  const [deviceOptions, setDeviceOptions] = useState(exampleDeviceOptions);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [steps, setSteps] = useState([]);
  const [optionsTracker, setOptionsTracker] = useState({});
  const [deviceOptionsSet, setDeviceOptionsSet] = useState(false);
  const [cartFooterOn, setCartFooterOn] = useState(false);
  const [cartFooterLink, setCartFooterLink] = useState(null);
  const [cartFooterData, setCartFooterData] = useState(exampleCartFooterData);
  const [currentStep, setCurrentStep] = useState(0);
  const [userInfo, setUserInfo] = useState(exampleUserInfo);
  const [orderInfo, setOrderInfo] = useState(null);
  const [availableAccessories, setAvailableAccessories] = useState(
    exampleAccessoriesState
  );
  const [orderAccessories, setOrderAccessories] = useState([]);

  const location = useLocation();

  // useEffect(() => {
  //   console.log('refreshing optionsTracker');
  //   console.log('useEffect optionsTracker: ', optionsTracker);
  // }, [optionsTracker]);

  //Turn on CartFooter for appropriate pages
  useEffect(() => {
    if (pagesWithCartFooter.includes(location.pathname)) {
      setCartFooterOn(true);
      if (location.pathname === DEVICE_ACCESSORIES) {
        setCartFooterLink(DEVICE_SUBSCRIBERS);
      }
    } else {
      setCartFooterOn(false);
      setCartFooterLink(null);
    }
  }, [location]);

  // Fires anytime an option has been chosen
  // Checks if the Device Options have all been chosen
  // Checks if the Device Options need to be updated
  // Can be used to update the orderInfo state, to update costs etc
  // shown in Order Summary
  useEffect(() => {
    checkDeviceOptions();
    // console.log('check check');
    if (optionsTracker.deviceOptions) {
      if (
        optionsTracker.deviceId != 'byod-plan' &&
        optionsTracker.deviceOptions[optionsTracker.deviceOptions.length - 1]
          .selection &&
        deviceOptions !== exampleExpandedDeviceOptions
      ) {
        updateDeviceOptions(exampleExpandedDeviceOptions);
      }
    }
    // console.log('optionsTracker: ', optionsTracker);
  }, [optionsTracker]);

  //Set up the tracker for the available options
  //when a device is chosen
  const initializeOptionsTracker = (id, options) => {
    // console.log(options);
    if (options) {
      setDeviceOptions(options);
    }
    let trackOptions = options ? options : deviceOptions;
    let newId = id ? id : options.id;
    let newOptions = {
      deviceId: newId,
    };
    // console.log(trackOptions);
    // console.log(newId);
    if (trackOptions) {
      if (trackOptions.deviceOptions) {
        if (trackOptions.deviceOptions.options) {
          newOptions['deviceOptions'] = [];
          // console.log(trackOptions.deviceOptions.options);
          trackOptions.deviceOptions.options.map((option) => {
            newOptions.deviceOptions.push({ id: option.id, selection: null });
          });
        }
      }
      if (trackOptions.byodOptions) {
        if (trackOptions.byodOptions.options) {
          newOptions['byodOptions'] = [];
          trackOptions.byodOptions.options.map((option) => {
            newOptions.byodOptions.push({ id: option.id, selection: null });
          });
        }
      }
      console.log('trackOptions: ', trackOptions.additionalOptions);
      if (trackOptions.additionalOptions) {
        newOptions['additionalOptions'] = [];
        trackOptions.additionalOptions.map((option) => {
          if (option.id !== 'device-options') {
            if (option.type === 'checklist') {
              newOptions.additionalOptions.push({
                id: option.id,
                selection: [],
              });
            } else {
              newOptions.additionalOptions.push({
                id: option.id,
                selection: null,
              });
            }
          }
        });
      }
    }
    setOptionsTracker(newOptions);
  };

  //Use to update options tracked,
  //based on updates to deviceOptions
  const updateOptionsTracker = () => {
    let newOptions = { ...optionsTracker };
    if (deviceOptions) {
      if (deviceOptions.deviceOptions.options) {
        deviceOptions.deviceOptions.options.map((option, i) => {
          if (!newOptions.deviceOptions.some((el) => el.id === option.id)) {
            newOptions.deviceOptions.push({ id: option.id, selection: null });
          }
        });
      }
      if (deviceOptions.additionalOptions) {
        deviceOptions.additionalOptions.map((option, i) => {
          if (!newOptions.additionalOptions.some((el) => el.id === option.id)) {
            if (option.type === 'checklist') {
              newOptions.additionalOptions.push({
                id: option.id,
                selection: [],
              });
            } else {
              newOptions.additionalOptions.push({
                id: option.id,
                selection: null,
              });
            }
          }
        });
      }
    }
    setOptionsTracker(newOptions);
  };

  // For updating the optionsTracker
  // When the deviceOptions are updated
  const updateOptions = (options) => {
    let newOptionsTracker = { ...optionsTracker };
    // Update deviceOptions in the optionsTracker
    if (options.deviceOptions) {
      if (!newOptionsTracker.deviceOptions) {
        newOptionsTracker['deviceOptions'] = [];
      } else {
        let removalArray = [];
        newOptionsTracker.deviceOptions.map((option, key) => {
          if (
            !options.deviceOptions.options.some((obj) => obj.id === option.id)
          ) {
            console.log('removing option: ', option.id);
            removalArray.push(key);
          }
        });
        if (removalArray.length) {
          removalArray.sort((a, b) => b - a);
          removalArray.map((index) => {
            newOptionsTracker.deviceOptions.splice(index, 1);
          });
        }
      }
      options.deviceOptions.options.map((option) => {
        if (
          !newOptionsTracker.deviceOptions.some((obj) => obj.id === option.id)
        ) {
          newOptionsTracker.deviceOptions.push({
            id: option.id,
            selection: null,
          });
        }
      });
    } else {
      if (newOptionsTracker.deviceOptions) {
        newOptionsTracker.splice(newOptionsTracker.indexOf('deviceOptions'), 1);
      }
    }
    // Update additionalOptions in the optionsTracker
    if (options.additionalOptions) {
      if (!newOptionsTracker.additionalOptions) {
        newOptionsTracker['additionalOptions'] = [];
      } else {
        let removalArray = [];
        newOptionsTracker.additionalOptions.map((option, key) => {
          if (!options.additionalOptions.some((obj) => obj.id === option.id)) {
            console.log('removing option: ', option.id);
            removalArray.push(key);
          }
        });
        if (removalArray.length) {
          removalArray.sort((a, b) => b - a);
          removalArray.map((index) => {
            newOptionsTracker.additionalOptions.splice(index, 1);
          });
        }
      }
      options.additionalOptions.map((option) => {
        if (option.id !== 'device-options') {
          if (
            !newOptionsTracker.additionalOptions.some(
              (obj) => obj.id === option.id
            )
          ) {
            if (option.type === 'checklist') {
              newOptionsTracker.additionalOptions.push({
                id: option.id,
                selection: [],
              });
            } else {
              newOptionsTracker.additionalOptions.push({
                id: option.id,
                selection: null,
              });
            }
          }
        }
      });
    } else {
      if (newOptionsTracker.additionalOptions) {
        newOptionsTracker.splice(
          newOptionsTracker.indexOf('additionalOptions'),
          1
        );
      }
    }
    setOptionsTracker(newOptionsTracker);
  };

  // Use to update the device options
  // And ensures optionsTracker is also updated
  const updateDeviceOptions = (options) => {
    // console.log('updating deviceOptions to: ', options);
    // console.log(optionsTracker);
    updateOptions(options);
    setDeviceOptions(options);
  };

  //Use to set a selected option in the options tracker
  const setOptionSelection = (optionId, optionValue, option, category) => {
    // console.log('optionId: ', optionId);
    // console.log('optionValue: ', optionValue);
    // console.log('deviceOptions: ', deviceOptions);
    // console.log('option: ', option);
    // console.log('orderInfo: ', orderInfo);
    let newOptions = { ...optionsTracker };
    if (Object.keys(optionsTracker).length > 0) {
      if (
        newOptions.deviceOptions &&
        newOptions.deviceOptions.find((element) => element.id === optionId)
      ) {
        newOptions.deviceOptions.find(
          (element) => element.id === optionId
        ).selection = optionValue;
      } else if (
        newOptions.additionalOptions.find((element) => element.id === optionId)
      ) {
        newOptions.additionalOptions.find(
          (element) => element.id === optionId
        ).selection = optionValue;
      }
    }
    setOptionsTracker(newOptions);
    // console.log('option cost: ', option.cost);
    // console.log('truth check: ', option.cost !== undefined);
    // console.log('option: ', option);
    if (option.cost !== undefined) {
      if (option.costPer === 'month') {
        setMonthlyItem(
          optionId,
          category,
          window.location.hash,
          option.title,
          option.cost
        );
      } else {
        setUpfrontItem(optionId, option.title, option.cost);
      }
    }
  };

  //Use to set a selected option for an option array
  const setChecklistOption = (optionId, optionValue, option, category) => {
    // console.log('optionId: ', optionId);
    // console.log('optionValue: ', optionValue);
    // console.log('deviceOptions: ', deviceOptions);
    // console.log('option: ', option);
    // console.log('orderInfo: ', orderInfo);
    let newOptions = { ...optionsTracker };
    //check that optionsTracker is initialized
    if (Object.keys(optionsTracker).length > 0) {
      //check that the option exists in the optionsTracker
      if (
        newOptions.additionalOptions.find((element) => element.id === optionId)
      ) {
        //check if the selected option has been added already
        if (
          newOptions.additionalOptions.find(
            (element) => element.id === optionId
          ).selection &&
          newOptions.additionalOptions
            .find((element) => element.id === optionId)
            .selection.find((obj) => obj === optionValue)
        ) {
          //if so, remove it
          let index = newOptions.additionalOptions
            .find((element) => element.id === optionId)
            .selection.indexOf(optionValue);
          // console.log('newOptions: ', newOptions);
          // console.log('index: ', index);
          // console.log('id: ', optionId);
          newOptions.additionalOptions
            .find((element) => element.id === optionId)
            .selection.splice(index, 1);
          //check if there is a cost associated
          if (option.cost !== undefined) {
            //if so, remove it from the order info
            removeChecklistItem(
              category,
              option.title,
              option.cost,
              option.costPer
            );
          }
        } else {
          //if not, add it
          newOptions.additionalOptions
            .find((element) => element.id === optionId)
            .selection.push(optionValue);
          //check if there is a cost associated
          if (option.cost !== undefined) {
            //if so, add it to the order info
            addChecklistItem(
              optionId,
              category,
              window.location.hash,
              option.title,
              option.cost,
              option.costPer
            );
          }
        }
      }
    }
    setOptionsTracker(newOptions);
    // console.log('option cost: ', option.cost);
    // console.log('truth check: ', option.cost !== undefined);
    // console.log('option: ', option);
    // if (option.cost !== undefined) {
    //   if (option.costPer === 'month') {
    //     setMonthlyItem(
    //       optionId,
    //       category,
    //       window.location.hash,
    //       option.title,
    //       option.cost
    //     );
    //   } else {
    //     setUpfrontItem(optionId, option.title, option.cost);
    //   }
    // }
  };

  const addChecklistItem = (catId, category, link, label, cost, costPer) => {
    let newOrderInfo = { ...orderInfo };
    if (costPer) {
      //check if category exists
      if (newOrderInfo.monthlyCosts.find((obj) => obj.category === category)) {
        //if so, check if item is in category
        if (
          !newOrderInfo.monthlyCosts
            .find((obj) => obj.category === category)
            .items.find((obj) => obj.label === label)
        ) {
          //if not, add item to category and add cost to monthly costs
          newOrderInfo.monthlyCosts
            .find((obj) => obj.category === category)
            .items.push({ label: label, cost: cost, catId: catId });
          newOrderInfo.dueMonthly += Number(cost);
        }
      } else {
        //if not, add category with item and add cost to monthly costs
        newOrderInfo.monthlyCosts.push({
          category: category,
          link: link,
          items: [{ label: label, cost: cost, catId: catId }],
        });
        newOrderInfo.dueMonthly += Number(cost);
      }
    } else {
      //check if specific item is in upfrontCosts
      if (!newOrderInfo.upfrontCosts.find((obj) => obj.label === label)) {
        //if not, add item and add cost to upfront costs
        newOrderInfo.upfrontCosts.push({
          label: label,
          cost: cost,
          catId: catId,
        });
        newOrderInfo.dueNow += Number(cost);
      }
    }
    setOrderInfo(newOrderInfo);
  };

  const removeChecklistItem = (category, label, cost, costPer) => {
    let newOrderInfo = { ...orderInfo };
    if (costPer) {
      console.log('costPer: ', costPer);
      //check if category exists
      if (newOrderInfo.monthlyCosts.find((obj) => obj.category === category)) {
        //if so, check if item exists
        if (
          newOrderInfo.monthlyCosts
            .find((obj) => obj.category === category)
            .items.find((obj) => obj.label === label)
        ) {
          //if so, remove item, remove cost from monthly costs
          newOrderInfo.monthlyCosts
            .find((obj) => obj.category === category)
            .items.splice(
              newOrderInfo.monthlyCosts
                .find((obj) => obj.category === category)
                .items.findIndex((obj) => obj.label === label),
              1
            );
          newOrderInfo.dueMonthly -= Number(cost);
          //check if category is empty
          if (
            newOrderInfo.monthlyCosts.find((obj) => obj.category === category)
              .items.length < 1
          ) {
            //if so, remove category
            newOrderInfo.monthlyCosts.splice(
              newOrderInfo.monthlyCosts.findIndex(
                (obj) => obj.category === category
              ),
              1
            );
          }
        }
      }
    } else {
      //check if item exists
      if (newOrderInfo.upfrontCosts.find((obj) => obj.label === label)) {
        //if so, remove item, remove cost from monthly costs
        newOrderInfo.upfrontCosts.splice(
          newOrderInfo.upfrontCosts.findIndex((obj) => obj.label === label),
          1
        );
        newOrderInfo.dueNow -= Number(cost);
      }
    }
    setOrderInfo(newOrderInfo);
  };

  const checkDeviceOptions = () => {
    let deviceOptionsCheck = false;
    let additionalOptionsCheck = false;
    // console.log('deviceOptions: ', optionsTracker.deviceOptions);
    if (optionsTracker.deviceOptions) {
      // console.log('there are device options');
      if (optionsTracker.deviceOptions.every((item) => item.selection)) {
        deviceOptionsCheck = true;
      }
    } else {
      // console.log('there are not device options');
      if (optionsTracker.additionalOptions) {
        deviceOptionsCheck = true;
      }
    }
    if (optionsTracker.additionalOptions) {
      let requiredOptions = optionsTracker.additionalOptions.filter(
        (obj) =>
          obj.id !== 'warranty-options' &&
          obj.id !== 'dep-options' &&
          obj.id !== 'add-ons-options'
      );
      // console.log('requiredOptions: ', requiredOptions);

      if (requiredOptions) {
        if (requiredOptions.every((item) => item.selection)) {
          additionalOptionsCheck = true;
        }
      } else {
        if (optionsTracker.length) {
          additionalOptionsCheck = true;
        }
      }
    }
    // console.log('deviceOptionsCheck: ', deviceOptionsCheck);
    // console.log('additionalOptionsCheck: ', additionalOptionsCheck);
    if (deviceOptionsCheck && additionalOptionsCheck) {
      setDeviceOptionsSet(true);
    } else {
      setDeviceOptionsSet(false);
    }
    // console.log('deviceOptionsSet: ', deviceOptionsSet);
  };

  // Use do add accessories to the order, and update the cost shown in the footer
  const addAccessories = (cost, id) => {
    let newOrderAccessories = [...orderAccessories];
    if (newOrderAccessories.find((obj) => obj.id === id)) {
      newOrderAccessories.find((obj) => obj.id === id).quantity++;
    } else {
      newOrderAccessories.push({ id: id, quantity: 1 });
    }
    let newCartFooterData = { ...cartFooterData };
    newCartFooterData.dueNow = `${(
      parseFloat(newCartFooterData.dueNow) + cost
    ).toFixed(2)}`;
    let newOrderInfo = { ...orderInfo };
    newOrderInfo.dueNow = newOrderInfo.dueNow + cost;
    console.log(newOrderInfo);
    setOrderAccessories(newOrderAccessories);
    setCartFooterData(newCartFooterData);
    setOrderInfo(newOrderInfo);
    console.log('accessories in order: ', newOrderAccessories);
  };

  const removeAccessories = (cost, id) => {
    let newOrderAccessories = [...orderAccessories];
    if (newOrderAccessories.find((obj) => obj.id === id)) {
      if (newOrderAccessories.find((obj) => obj.id === id).quantity > 1) {
        newOrderAccessories.find((obj) => obj.id === id).quantity--;
      } else {
        newOrderAccessories.splice(
          newOrderAccessories.findIndex((obj) => obj.id === id),
          1
        );
      }
    }
    let newCartFooterData = { ...cartFooterData };
    newCartFooterData.dueNow = `${(
      parseFloat(newCartFooterData.dueNow) - cost
    ).toFixed(2)}`;
    let newOrderInfo = { ...orderInfo };
    newOrderInfo.dueNow = newOrderInfo.dueNow - cost;
    setOrderAccessories(newOrderAccessories);
    setCartFooterData(newCartFooterData);
    setOrderInfo(newOrderInfo);
  };

  const allowAccessoryQuantities = () => {
    let newAvailableAccessories = { ...availableAccessories };
    newAvailableAccessories.multipleAllowed = true;
    setAvailableAccessories(newAvailableAccessories);
  };

  const disallowAccessoryQuantities = () => {
    let newAvailableAccessories = { ...availableAccessories };
    newAvailableAccessories.multipleAllowed = false;
    setAvailableAccessories(newAvailableAccessories);
  };

  ///////////////////////test////////////////////////////////
  const setUpfrontItem = (catId, label, cost) => {
    let newOrderInfo = { ...orderInfo };
    if (newOrderInfo.upfrontCosts.find((obj) => obj.catId === catId)) {
      if (newOrderInfo.upfrontCosts.find((obj) => obj.catId === catId).cost)
        newOrderInfo.dueNow -= newOrderInfo.upfrontCosts.find(
          (obj) => obj.catId === catId
        ).cost;
      newOrderInfo.upfrontCosts.splice(
        newOrderInfo.upfrontCosts.findIndex((obj) => obj.catId === catId),
        1
      );
    }
    newOrderInfo.upfrontCosts.push({ label: label, cost: cost, catId: catId });
    newOrderInfo.dueNow += Number(cost);
    // console.log('newOrderInfo: ', newOrderInfo);
    setOrderInfo(newOrderInfo);
  };

  // const removeUpfrontItem = (catId) => {
  //   let newOrderInfo = { ...orderInfo };
  //   newOrderInfo.upfrontCosts.splice(
  //     newOrderInfo.upfrontCosts.findIndex((obj) => obj.catId === catId),
  //     1
  //   );
  //   setOrderInfo(newOrderInfo);
  // };

  const setMonthlyItem = (catId, category, link, label, cost) => {
    let newOrderInfo = { ...orderInfo };
    console.log('catId: ', catId);
    console.log('category: ', category);
    console.log('link: ', link);
    console.log('label: ', label);
    console.log('cost: ', cost);
    console.log('newOrderInfo: ', newOrderInfo);
    if (newOrderInfo.monthlyCosts.find((obj) => obj.category === category)) {
      if (
        newOrderInfo.monthlyCosts
          .find((obj) => obj.category === category)
          .items.find((obj) => obj.catId === catId)
      ) {
        newOrderInfo.dueMonthly -= newOrderInfo.monthlyCosts
          .find((obj) => obj.category === category)
          .items.find((obj) => obj.catId === catId).cost;
        newOrderInfo.monthlyCosts
          .find((obj) => obj.category === category)
          .items.splice(
            newOrderInfo.monthlyCosts
              .find((obj) => obj.category === category)
              .items.findIndex((obj) => obj.catId === catId),
            1
          );
      }
      newOrderInfo.monthlyCosts
        .find((obj) => obj.category === category)
        .items.push({ label: label, cost: cost, catId: catId });
    } else {
      newOrderInfo.monthlyCosts.push({
        category: category,
        link: link,
        items: [{ label: label, cost: cost, catId: catId }],
      });
    }
    newOrderInfo.dueMonthly += Number(cost);
    setOrderInfo(newOrderInfo);
  };

  // const removeMonthlyItem = (category, catId) => {
  //   let newOrderInfo = { ...orderInfo };
  //   newOrderInfo.monthlyCosts
  //     .find((obj) => obj.category === category)
  //     .items.splice(
  //       newOrderInfo.monthlyCosts
  //         .find((obj) => obj.category === category)
  //         .items.findIndex((obj) => obj.catId === catId),
  //       1
  //     );
  //   if (
  //     newOrderInfo.monthlyCosts.find((obj) => obj.category === category).items
  //       .length < 1
  //   ) {
  //     newOrderInfo.monthlyCosts.splice(
  //       newOrderInfo.monthlyCosts.findIndex((obj) => obj.category === category),
  //       1
  //     );
  //   }
  //   setOrderInfo(newOrderInfo);
  // };
  ///////////////////////test////////////////////////////

  //All accesible variables
  const value = useMemo(() => {
    return {
      deviceOptions,
      setDeviceOptions,
      selectedDevice,
      setSelectedDevice,
      optionsTracker,
      initializeOptionsTracker,
      updateOptionsTracker,
      updateDeviceOptions,
      setOptionSelection,
      setChecklistOption,
      deviceOptionsSet,
      cartFooterOn,
      setCartFooterOn,
      cartFooterLink,
      cartFooterData,
      setCartFooterData,
      currentStep,
      setCurrentStep,
      steps,
      setSteps,
      userInfo,
      setUserInfo,
      orderInfo,
      setOrderInfo,
      setUpfrontItem,
      setMonthlyItem,
      // removeMonthlyItem,
      availableAccessories,
      setAvailableAccessories,
      orderAccessories,
      addAccessories,
      removeAccessories,
      allowAccessoryQuantities,
      disallowAccessoryQuantities,
    };
  }, [
    deviceOptions,
    selectedDevice,
    optionsTracker,
    deviceOptionsSet,
    cartFooterOn,
    cartFooterLink,
    cartFooterData,
    currentStep,
    steps,
    userInfo,
    orderInfo,
    availableAccessories,
    orderAccessories,
  ]);

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};
