import {
  Typography,
  StackWrap,
  StackView,
  Spacer,
  Button,
  Toast,
} from '@telus-uds/components-web';
import { Box, StepTracker } from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { DeviceContext } from 'context/DeviceContext';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE, USER_DEVICES } from 'constants/routes';
import { HistoryContext } from 'context/HistoryContext';
import { useEffect } from 'react';

const SubheaderContainer = styled.div`
  max-height: 4rem;
`;

const StepLinkWrapper = styled.div`
  max-height: 4rem;
  display: block;

  > div {
    gap: 0 !important;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: 0;
  display: flex;
  flex-basis: auto;
`;

const Row = styled.div`
  display: flex;
  height: ${(props) => (props.highlight ? '3px' : '1px')};
  flex: 1 1 0%;
  min-width: 16px;
  background-color: ${(props) =>
    props.hide
      ? 'none'
      : props.highlight
      ? palette.color.purpleEminence
      : palette.color.greyShuttle};
`;

const Circle = styled.div`
  background-color: ${(props) =>
    props.past ? palette.color.purpleEminence : 'rgba(0, 0, 0, 0)'};
  border: solid
    ${(props) =>
      props.current
        ? `2px ${palette.color.purpleEminence}`
        : ' 1px rgb(103, 110, 115)'};
  border-radius: 8px;
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  border-radius: 4px;
  height: 8px;
  width: 8px;
  background-color: ${palette.color.purpleEminence};
`;

const LinkWrapper = styled.div`
  text-decoration: none;

  ${(props) =>
    props.inactive
      ? 'cursor: default; pointer-events: none;'
      : 'cursor: pointer;'}

  :focus,
  :hover,
  :active {
    ${(props) => (props.inactive ? '' : 'text-decoration: underline;')}
  }
`;

const ToastWrapper = styled.div`
  max-height: ${(props) => (props.visible ? '200px' : '0px')};
  transition-property: max-height;
  transition-timing-function: ease-in-out;
  transition-duration: 2000ms;
  overflow: hidden;
`;

const StepLinker = ({ current, steps }) => {
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  };

  console.log('---StepLinker current: ', current);

  return (
    <StepLinkWrapper>
      <StackWrap
        tokens={{ justifyContent: 'space-evenly', alignItems: 'flex-start' }}
      >
        {steps.map((step, i) => (
          <StackView tokens={{ flexGrow: 1, alignItems: 'stretch' }} key={i}>
            <Wrapper>
              <Row hide={i === 0} highlight={i <= current} />
              <LinkWrapper
                onClick={() => handleNavigate(step.link)}
                inactive={i >= current}
              >
                <Circle current={i === current} past={i < current}>
                  {current === i ? <Dot /> : null}
                </Circle>
              </LinkWrapper>
              <Row hide={i === steps.length - 1} highlight={i < current} />
            </Wrapper>
            <Spacer space={2} />
            <StackView tokens={{ alignItems: 'center' }}>
              <LinkWrapper
                onClick={() => handleNavigate(step.link)}
                inactive={i >= current}
              >
                <Typography
                  block
                  variant={
                    i === current
                      ? { size: 'small', bold: true, colour: 'brand3' }
                      : { size: 'small' }
                  }
                >
                  {step.label}
                </Typography>
              </LinkWrapper>
            </StackView>
          </StackView>
        ))}
      </StackWrap>
    </StepLinkWrapper>
  );
};

const BackButton = () => {
  return <Button onPress={() => history.back()}>Go Back</Button>;
};

const SubHeader = () => {
  const { steps, currentStep } = useContext(DeviceContext);
  const { orderDetails, toastVisible } = useContext(HistoryContext);
  const [date, setDate] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let currentdate = new Date();
    let datetime =
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      ' ' +
      currentdate.getHours() +
      ':' +
      (currentdate.getMinutes() < 10 ? '0' : '') +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds();
    setDate(datetime);
  }, [toastVisible]);

  return (
    <>
      <ToastWrapper aria-live={true} visible={toastVisible}>
        {/* {toastVisible && ( */}
        <Toast
          toast
          copy={`Status update: Order ${orderDetails.orderStatus}      ${date}`}
        />
        {/* )} */}
      </ToastWrapper>
      {location.pathname === BASE ||
      location.pathname === USER_DEVICES ? null : (
        <Box space={{ xs: 0 }}>
          <Spacer space={{ xs: 6 }} />
          {/* <StepTracker
            current={currentStep}
            steps={steps}
            tokens={{ showStepName: false }}
          /> */}
          {steps.length ? (
            <SubheaderContainer>
              <StepLinker current={currentStep} steps={steps} />
              <Spacer space={{ xs: 4 }} />
            </SubheaderContainer>
          ) : (
            <BackButton />
          )}
        </Box>
      )}
    </>
  );
};

export default SubHeader;
