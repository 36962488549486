import { FlexGrid, Spacer } from '@telus-uds/ds-allium';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OrderSummary from 'components/OrderSummary';
// import SubHeader from 'components/SubHeader';
import {
  exampleDeviceOptions,
  exampleOrderInfo,
} from 'context/exampleDeviceData';
import Accordion from 'components/Accordion';
import { DEVICE_ACCESSORIES } from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';

const OptionsPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const SummaryContainer = styled.div`
  position: relative;

  @media screen and (min-width: 1008px) {
    position: absolute;
    height: 100%;
    width: calc(100% - 2rem);
  }
`;

const PageNavContainer = styled.div`
  overflow: hidden;
`;

const OptionsPage = () => {
  const {
    deviceOptions,
    deviceOptionsSet,
    optionsTracker,
    initializeOptionsTracker,
    setCurrentStep,
    setOrderInfo,
  } = useContext(DeviceContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!deviceOptions.deviceOptions) {
      setCurrentStep(1);
    }
    if (!optionsTracker.additionalOptions) {
      initializeOptionsTracker(null, exampleDeviceOptions);
      console.log('Options Page initializing: ', optionsTracker);
    } else {
      console.log('already initialized: ', optionsTracker);
    }
    setOrderInfo(exampleOrderInfo);
  }, []);

  useEffect(() => {
    if (optionsTracker.deviceOptions) {
      if (optionsTracker.deviceOptions.every((item) => item.selection)) {
        // console.log('all device options set');
        setCurrentStep(1);
      }
    }
  }, [optionsTracker]);

  const handleOnsubmit = () => {
    console.log('Device Options Chosen: ', optionsTracker);
    navigate(DEVICE_ACCESSORIES);
    setCurrentStep(2);
  };

  return (
    <OptionsPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={6} />
      <FlexGrid limitWidth={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={5} xl={4}>
            <SummaryContainer>
              <OrderSummary
                active={deviceOptionsSet}
                onsubmit={() => handleOnsubmit()}
              />
            </SummaryContainer>
            <Spacer space={6} />
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} lg={7} xl={8}>
            <PageNavContainer>
              <Accordion
                deviceOptions={deviceOptions.deviceOptions}
                sections={deviceOptions.additionalOptions}
              />
            </PageNavContainer>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </OptionsPageContainer>
  );
};

export default OptionsPage;
