import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
  Icon,
  ButtonDropdown,
} from '@telus-uds/components-web';
import { ChevronRight } from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BYOD_PLANS, DEVICE_SELECT, UPGRADES } from 'constants/routes';
import BundleDetails from 'components/BundleDetails';
import { SubscribersContext } from 'context/SubscribersContext';
import {
  exampleBundleConfiguration,
  exampleUpgradeBundlesList,
} from 'context/exampleSubscriberData';
import palette from '@telus-uds/palette-allium';
import ChooseBundleModal from 'components/ChooseBundleModal';

const BundlesPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  > div:nth-child(2) {
    z-index: 20;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const DropdownButtonContainer = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  width: 218px;

  > div:first-of-type {
    width: 100% !important;
    padding: 0 !important;

    > div {
      width: 218px;
      justify-content: space-between;
    }
  }
`;

const DropdownBox = styled.div`
  position: absolute;
  top: 100%;
  border-radius: 4px;
  border: 1px solid ${palette.color.greyShuttle};
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(26, 0, 0, 0.25);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  width: 200px;
  z-index: 20;
`;

const DropdownLink = styled.a`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const BundlesPage = () => {
  const { initializeBundleData, selectedBundle, removeSelectedBundle } =
    useContext(SubscribersContext);
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initializeBundleData(exampleUpgradeBundlesList);
  }, []);

  const handleRemove = () => {
    removeSelectedBundle();
  };

  const OrderControls = () => {
    return (
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton onPress={handleRemove} variant={{ alternative: true }}>
            Remove bundle
          </TextButton>
        </StackView>
        <Spacer space={4} direction="row" />
        <DropdownButtonContainer>
          <ButtonDropdown
            label="Add new bundle"
            initialValue={navOpen}
            selected={navOpen}
            onChange={setNavOpen}
          />
          {navOpen ? (
            <DropdownBox>
              <DropdownLink onClick={() => navigate(DEVICE_SELECT)}>
                <Typography>Device bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
              <DropdownLink onClick={() => navigate(BYOD_PLANS)}>
                <Typography>BYOD bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
              <DropdownLink onClick={() => navigate(UPGRADES)}>
                <Typography>Upgrade bundle</Typography>
                <Icon icon={ChevronRight} variant={{ size: 'small' }} />
              </DropdownLink>
            </DropdownBox>
          ) : null}
        </DropdownButtonContainer>
      </StackWrap>
    );
  };

  return (
    <BundlesPageContainer>
      <Spacer space={10} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            My bundles
          </Typography>
          <OrderControls />
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <BundleDetails />
      <StackWrap direction="row-reverse">
        <ChooseBundleModal
          active={selectedBundle}
          configurationData={exampleBundleConfiguration}
        />
      </StackWrap>
      <Spacer space={10} />
    </BundlesPageContainer>
  );
};

export default BundlesPage;
