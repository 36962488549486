import React, { useState, useEffect } from 'react';

import { TextInput } from '@telus-uds/ds-allium';

const AddressField = ({ label, required, data, onchange }) => {
  const [validation, setValidation] = useState();
  const [newValue, setNewValue] = useState(data);
  const defaultFeedbackContent = '';
  const AddressRegex = /^\s*\S+(?:\s+\S+){2}/;

  const feedbackContent = {
    error: `The value must be a standard north american address`,
    success: '',
  };

  useEffect(() => {
    if (newValue !== undefined && newValue.length > 0) {
      if (AddressRegex.test(newValue)) {
        onchange(newValue);
        setValidation('success');
      } else {
        setValidation('error');
      }
    }
  }, [newValue]);

  const feedback =
    validation === undefined
      ? defaultFeedbackContent
      : feedbackContent[validation];

  const normalizeInput = (value, e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    if (!value) return value;
    const theNewValue = value;
    //
    //
    // What is this doing???
    if (value.length > 50) {
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    }

    return theNewValue;
  };

  const checkValue = (value, e) => {
    const formattedNumber = normalizeInput(value, e);
    setNewValue(formattedNumber);
  };

  return (
    <TextInput
      label={label}
      onChange={(value, e) => checkValue(value, e)}
      value={newValue}
      feedback={feedback}
      validation={validation}
    />
  );
};

export default AddressField;
