export const listOfDevices = {
  device1: {
    productId: 'samgs2301',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device2: {
    productId: 'appip14pm01',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device3: {
    productId: 'appip1401',
    brand: 'apple',
    name: 'iPhone 14',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1130.0,
    image: '/images/devices/device-img-iphone.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device4: {
    productId: 'samgs2302',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device5: {
    productId: 'appip14pm02',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    tags: ['pre-owned', 'good'],
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device6: {
    productId: 'appip1402',
    brand: 'apple',
    name: 'iPhone 14',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1130.0,
    image: '/images/devices/device-img-iphone.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device7: {
    productId: 'samgs2303',
    brand: 'samsung',
    name: 'Galaxy S23 (Certified pre-owned good condition)',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device8: {
    productId: 'appip14pm03',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device9: {
    productId: 'samgs2301',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device10: {
    productId: 'appip14pm01',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device11: {
    productId: 'appip1401',
    brand: 'apple',
    name: 'iPhone 14',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1130.0,
    image: '/images/devices/device-img-iphone.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device12: {
    productId: 'samgs2302',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device13: {
    productId: 'appip14pm02',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    tags: ['pre-owned', 'good'],
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device14: {
    productId: 'appip1402',
    brand: 'apple',
    name: 'iPhone 14',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1130.0,
    image: '/images/devices/device-img-iphone.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device15: {
    productId: 'samgs2303',
    brand: 'samsung',
    name: 'Galaxy S23 (Certified pre-owned good condition)',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device16: {
    productId: 'appip14pm03',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device17: {
    productId: 'samgs2301',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device18: {
    productId: 'appip14pm01',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device19: {
    productId: 'appip1401',
    brand: 'apple',
    name: 'iPhone 14',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1130.0,
    image: '/images/devices/device-img-iphone.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device20: {
    productId: 'samgs2302',
    brand: 'samsung',
    name: 'Galaxy S23',
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 1360.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
  device21: {
    productId: 'appip14pm02',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    tags: ['pre-owned', 'good'],
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
  },
};

export const exampleDeviceCategories = [
  { label: 'Recent Purchases', href: '#recent', id: 'recent' },
  { label: 'All', href: '#all', id: 'all' },
  { label: 'iPhones', href: '#iphone', id: 'iphone' },
  { label: 'Android', href: '#android', id: 'android' },
  { label: 'Mobile Phones', href: '#mobile', id: 'mobile' },
  { label: 'Tablets', href: '#tablet', id: 'tablet' },
  { label: 'Mobile Internet & SmartHUB', href: '#smarthub', id: 'smarthub' },
];

// Note options can possible include:
// title, cost, costPer, copy, greenText, checklist[], details[]
export const exampleDeviceOptions = {
  id: 'samgs2301',
  deviceOptions: {
    brand: 'Apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-iphonepro.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
    // Device options use a type to specify the option style
    // These options include:
    //    size - displays small buttons next to provided device image
    //    color - displays color dots next to provided device image
    //    dropdown - displays a dropdown list
    //    half-radio - displays a set of radio buttons, in two columns
    //    full-radio - displays radio buttons at full-width, one column
    options: [
      {
        type: 'size',
        id: 'device-size',
        options: [
          { label: '128 GB', id: '128gb' },
          { label: '256 GB', id: '256gb' },
        ],
      },
      {
        type: 'color',
        id: 'device-color',
        options: [
          { title: 'Yellow', id: 'yellow', hex: '#FFFDCA' },
          { title: 'Black', id: 'black', hex: '#2C2E30' },
          { title: 'Grey', id: 'grey', hex: '#CCBFD4' },
          { title: 'Green', id: 'green', hex: '#344533' },
        ],
      },
      {
        type: 'dropdown',
        id: 'device-ban',
        instructions: 'Select BAN',
        options: [
          {
            id: 'item1',
            text: 'Item 1',
          },
          {
            id: 'item2',
            text: 'Item 2',
          },
          {
            id: 'item3',
            text: 'Item 3',
          },
        ],
      },
      {
        type: 'dropdown',
        id: 'device-site',
        instructions: 'Select Site',
        options: [
          {
            id: 'site1',
            text: 'Site 1',
          },
          {
            id: 'site2',
            text: 'Site 2',
          },
          {
            id: 'site3',
            text: 'Site 3',
          },
        ],
      },
      {
        type: 'half-radio',
        id: 'device-porting',
        instructions: 'Will you be porting in your phone number (s)?',
        options: [
          {
            title: 'No',
            id: 'no',
          },
          {
            title: 'Yes',
            id: 'yes',
          },
        ],
      },
      {
        type: 'half-radio',
        id: 'device-sim',
        instructions: 'Select a SIM',
        options: [
          {
            title: 'TELUS LTE TRI SIM',
            id: 'telus-sim',
            cost: 0.0,
          },
          {
            title: 'Provide my own SIM',
            id: 'own-sim',
            cost: '',
          },
        ],
      },
      {
        type: 'full-radio',
        id: 'device-offer',
        instructions: 'Offers',
        options: [
          {
            title: 'Voice & Data',
            id: 'offer-2year1',
            cost: 197.0,
            copy: 'Voice & Data plans on a two year term',
            swapBold: true,
            greenText:
              '$4.00/month activation credit available for the first 5 months',
            offerDetails: {
              retailPrice: 1027.0,
              discounts: [{ label: 'Hardware discount', value: 275.0 }],
              totalSavings: 275.0,
              repaymentAmount: 752.0,
              dueToday: 0.0,
              dueMonthly: 20.89,
            },
          },
          {
            title: 'Voice & Data',
            id: 'offer-2year2',
            cost: 197.0,
            copy: 'Voice & Data plans on a two year term',
            swapBold: true,
            greenText:
              '$20.00/month activation credit available for the first 5 months',
            offerDetails: {
              retailPrice: 1027.0,
              discounts: [{ label: 'Hardware discount', value: 275.0 }],
              totalSavings: 275.0,
              repaymentAmount: 752.0,
              dueToday: 0.0,
              dueMonthly: 20.89,
            },
          },
          {
            title: 'Device only (no plan)',
            id: 'offer-noplan',
            cost: 698.0,
          },
        ],
      },
    ],
  },
  // If an initial set of device options are being included, additionalOptions
  // needs a first array object to include it
  // All other additional options will default to using a full-radio setup
  additionalOptions: [
    {
      title: 'Choose device options',
      id: 'device-options',
    },
    {
      title: 'Choose an extended warranty',
      id: 'warranty-options',
      instructions: 'Select a warranty for this rate plan',
      options: [
        {
          id: 'dc-no',
          title: 'No',
        },
        {
          id: 'dc-complete1',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
        {
          id: 'dc-complete2',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
      ],
    },
    {
      title: 'Choose to enroll in Apple DEP',
      id: 'dep-options',
      options: [
        {
          id: 'dep-no',
          title: 'No',
          cost: '',
          costPer: '',
          copy: '',
        },
        {
          id: 'dep-yes',
          title: 'Yes',
          cost: 15.0,
          costPer: 'subscriber',
          copy: 'Account # 538792',
        },
      ],
    },
  ],
};

export const exampleExpandedDeviceOptions = {
  id: 'samgs2301',
  deviceOptions: {
    brand: 'Apple',
    name: 'iPhone 14 Pro Max',
    availabilityText: 'Out of stock',
    available: false,
    cost: 1593.0,
    image: '/images/devices/device-img-galaxy.png',
    symbol: '/images/devices/symbol-5g.png',
    symbolMeaning: '5G',
    options: [
      {
        type: 'size',
        id: 'device-size',
        options: [
          { label: '128 GB', id: '128gb' },
          { label: '256 GB', id: '256gb' },
        ],
      },
      {
        type: 'color',
        id: 'device-color',
        options: [
          { title: 'Yellow', id: 'yellow', hex: '#FFFDCA' },
          { title: 'Black', id: 'black', hex: '#2C2E30' },
          { title: 'Grey', id: 'grey', hex: '#CCBFD4' },
          { title: 'Green', id: 'green', hex: '#344533' },
        ],
      },
      {
        type: 'dropdown',
        id: 'device-ban',
        instructions: 'Select BAN',
        options: [
          {
            id: 'item1',
            text: 'Item 1',
          },
          {
            id: 'item2',
            text: 'Item 2',
          },
          {
            id: 'item3',
            text: 'Item 3',
          },
        ],
      },
      {
        type: 'dropdown',
        id: 'device-site',
        instructions: 'Select Site',
        options: [
          {
            id: 'site1',
            text: 'Site 1',
          },
          {
            id: 'site2',
            text: 'Site 2',
          },
          {
            id: 'site3',
            text: 'Site 3',
          },
        ],
      },
      {
        type: 'half-radio',
        id: 'device-porting',
        instructions: 'Will you be porting in your phone number (s)?',
        options: [
          {
            title: 'No',
            id: 'no',
          },
          {
            title: 'Yes',
            id: 'yes',
          },
        ],
      },
      {
        type: 'half-radio',
        id: 'device-sim',
        instructions: 'Select a SIM',
        options: [
          {
            title: 'TELUS LTE TRI SIM',
            id: 'telus-sim',
            cost: 0.0,
          },
          {
            title: 'Provide my own SIM',
            id: 'own-sim',
            cost: '',
          },
        ],
      },
      {
        type: 'full-radio',
        id: 'device-offer',
        instructions: 'Offers',
        options: [
          {
            title: 'Voice & Data',
            id: 'offer-2year1',
            cost: 197.0,
            copy: 'Voice & Data plans on a two year term',
            swapBold: true,
            greenText:
              '$4.00/month activation credit available for the first 5 months',
            offerDetails: {
              retailPrice: 1027.0,
              discounts: [{ label: 'Hardware discount', value: 275.0 }],
              totalSavings: 275.0,
              repaymentAmount: 752.0,
              dueToday: 0.0,
              dueMonthly: 20.89,
            },
          },
          {
            title: 'Voice & Data',
            id: 'offer-2year2',
            cost: 197.0,
            copy: 'Voice & Data plans on a two year term',
            swapBold: true,
            greenText:
              '$20.00/month activation credit available for the first 5 months',
            offerDetails: {
              retailPrice: 1027.0,
              discounts: [{ label: 'Hardware discount', value: 275.0 }],
              totalSavings: 275.0,
              repaymentAmount: 752.0,
              dueToday: 0.0,
              dueMonthly: 20.89,
            },
          },
          {
            title: 'Device only (no plan)',
            id: 'offer-noplan',
            cost: 698.0,
          },
        ],
      },
    ],
  },
  additionalOptions: [
    {
      title: 'Choose device options',
      id: 'device-options',
    },
    {
      title: 'Choose your rate plan',
      id: 'rate-plan-options',
      instructions: 'Select a new rate plan',
      options: [
        {
          id: 'plan-corp50',
          title: 'Corp Advantage VD 50 1GB PDA',
          cost: 52.5,
          costPer: 'month',
          details: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
        {
          id: 'plan-corp60',
          title: 'Corp Advantage VD 60 3GB PDA',
          cost: 62.5,
          costPer: 'month',
          details: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
      ],
    },
    {
      title: 'Choose your add-ons',
      id: 'add-ons-options',
      type: 'checklist',
      instructions: 'Select add-ons for this rate plan',
      options: [
        {
          id: 'corpdata',
          title: 'Corporate Data Block',
          cost: 12.0,
          costPer: 'month',
        },
        {
          id: 'voicemail5',
          title: 'Bus Visual Voicemail 5',
          cost: 24.0,
          costPer: 'month',
        },
        {
          id: 'singlepublicstatic',
          title: 'Single Public Static IP',
          // cost: 0.0,
          costPer: 'month',
        },
        {
          id: 'voicemail25',
          title: 'Bus Visual Voicemail 25',
          cost: 10.0,
          // costPer: 'month',
        },
      ],
    },
    {
      title: 'Choose an extended warranty',
      id: 'warranty-options',
      instructions: 'Select a warranty for this rate plan',
      options: [
        {
          id: 'dc-no',
          title: 'No',
        },
        {
          id: 'dc-complete1',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
        {
          id: 'dc-complete2',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
      ],
    },
    {
      title: 'Choose to enroll in Apple DEP',
      id: 'dep-options',
      options: [
        {
          id: 'dep-no',
          title: 'No',
          cost: '',
          costPer: '',
          copy: '',
        },
        {
          id: 'dep-yes',
          title: 'Yes',
          cost: 15.0,
          costPer: 'subscriber',
          copy: 'Account # 538792',
        },
      ],
    },
  ],
};

export const exampleOrderInfo = {
  cartContents: [
    {
      deviceIcon: '/icons/device-icon.svg',
      deviceName: 'iPhone 14 Pro Max',
      deviceDetails: '128GB | Black',
      // deviceCost: '',
    },
  ],
  dueMonthly: 19.45,
  dueNow: 221.0,
  upfrontCosts: [
    // {
    //   label: 'IPHONE 14 PRO MAX 128GB BLACK',
    //   cost: 221.0,
    // },
    // {
    //   label: 'TELUS LTE TRI SIM',
    //   cost: 'free',
    // },
  ],
  monthlyCosts: [
    // {
    //   category: 'Rate plan',
    //   link: '',
    //   items: [
    //     {
    //       label: 'CoO ULNA Voice - SIM only',
    //       cost: 20,
    //     },
    //   ],
    // },
    // {
    //   category: 'Add-ons',
    //   link: '',
    //   items: [
    //     {
    //       label: 'Corporate Data Block',
    //       cost: 'free',
    //     },
    //     {
    //       label: 'Bus Visual Voicemail 5',
    //       cost: 5,
    //     },
    //   ],
    // },
  ],
  // shippingCost: 'Free',
  // salesTax: 12.0,
  estimatedTotal: 128888.0,
  poolProgram: 2,
  floatBalance: 99,
};

export const exampleAccessoriesState = {
  device: 'New Device Group 1 Apple iPhone 14',
  // multipleAllowed determines whether the user can add multiple items of one
  // accessory to their cart, or only one
  multipleAllowed: true,
  categories: [
    { label: 'Recent Accessories', href: '#recent', id: 'recent' },
    { label: 'Mounts & Stands', href: '#stands', id: 'stands' },
    { label: 'Other', href: '#other', id: 'other' },
    { label: 'Holsters', href: '#holsters', id: 'holsters' },
    { label: 'Audio', href: '#audio', id: 'audio' },
    { label: 'Screen Protectors', href: '#protectors', id: 'protectors' },
    { label: 'Charging & Power', href: '#power', id: 'power' },
    { label: 'Cases', href: '#cases', id: 'cases' },
    { label: 'SIMs', href: '#sims', id: 'sims' },
  ],
  listOfAccessories: {
    accessory1: {
      brand: 'apple',
      name: 'AirPod Pro (2nd generation) (Certified Pre-ownded Condition: Good)',
      id: 'airpod-pro-2nd',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 12,
      cost: 359.0,
      image: '/images/devices/accessory-earbuds.png',
    },
    accessory2: {
      brand: 'apple',
      name: 'Silicone Case with MagSafe (iPhone 13 Pro)',
      id: 'magsafe-case-ip13pro',
      availabilityText: 'Out of stock',
      available: false,
      stockLimit: 5,
      cost: 79.0,
      image: '/images/devices/accessory-case.png',
    },
    accessory3: {
      brand: 'apple',
      name: 'iPhone 13',
      id: 'headphone-ip13',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 12,
      cost: 39.99,
      discountedFrom: 49.99,
      image: '/images/devices/accessory-headphone.png',
    },
    accessory4: {
      brand: 'samsung',
      name: 'Galaxy S22',
      id: 'adapter-galaxys22',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 12,
      cost: 34.99,
      image: '/images/devices/accessory-adapter.png',
    },
    accessory5: {
      brand: 'apple',
      name: 'AirPod Pro (3rd generation)',
      id: 'airpod-pro-3nd',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 14,
      cost: 359.0,
      image: '/images/devices/accessory-earbuds.png',
    },
    accessory6: {
      brand: 'apple',
      name: 'Silicone Case with MagSafe (iPhone 14 Pro)',
      id: 'magsafe-case-ip14pro',
      availabilityText: 'Out of stock',
      available: false,
      stockLimit: 2,
      cost: 79.0,
      image: '/images/devices/accessory-case.png',
    },
    accessory7: {
      brand: 'apple',
      name: 'iPhone 14',
      id: 'headphone-ip14',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      cost: 39.99,
      image: '/images/devices/accessory-headphone.png',
    },
    accessory8: {
      brand: 'samsung',
      name: 'Galaxy S23',
      id: 'adapter-galaxys23',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      cost: 34.99,
      image: '/images/devices/accessory-adapter.png',
    },
  },
};

export const exampleCartFooterData = {
  dueNow: '197.99',
  dueNowTooltip: 'this is the amount due now',
  dueMonthly: '52.50',
  dueMonthlyTooltip: 'this is the amount due monthly',
  poolProgram: 2,
  floatBalance: 99,
};

export const initialSubscribersState = {
  user1: {
    name: 'Name Surname',
    province: 'ON',
    language: 'English',
    department: 'HR',
    email: 'name@email.com',
    devices: [
      {
        name: '',
        info: '',
        etc: '',
      },
      {
        name: '',
        info: '',
        etc: '',
      },
      {
        name: '',
        info: '',
        etc: '',
      },
    ],
  },
};

export const initialCartState = {
  cartItems: [
    {
      group: 'New Device Group 1 Samsung 4K HDR Smart',
      name: 'Cart Item XJ95',
      quantity: 5,
      availabilityText: 'Available',
      dueMonthly: 19.99,
      dueNow: 29.99,
      terms: '3 years',
      plan: 'LifeLabs 5GB',
      addOns: 'Corporate Data Block',
      accessories: 'Corporate Data Block',
      subscribers: '1 Telus LTE TRI SIM',
      inBundle: false,
      inOrder: false,
    },
    {
      group: 'New Device Group 1 Samsung 4K HDR Smart',
      name: 'Cart Item XJ95',
      quantity: 5,
      availabilityText: 'Not Available',
      dueMonthly: 19.99,
      dueNow: 29.99,
      terms: '3 years',
      plan: 'LifeLabs 5GB',
      addOns: 'Corporate Data Block',
      accessories: 'Corporate Data Block',
      subscribers: '1 Telus LTE TRI SIM',
      inBundle: false,
      inOrder: false,
    },
    {
      group: 'New Device Group 1 Samsung 4K HDR Smart',
      name: 'Cart Item XJ95',
      quantity: 5,
      availabilityText: 'Backorder',
      dueMonthly: 19.99,
      dueNow: 29.99,
      terms: '3 years',
      plan: 'LifeLabs 5GB',
      addOns: 'Corporate Data Block',
      accessories: 'Corporate Data Block',
      subscribers: '1 Telus LTE TRI SIM',
      inBundle: false,
      inOrder: false,
    },
  ],
};

export const exampleByodOptions = {
  id: 'byod-plan',
  deviceOptions: {
    byodPlan: true,
    options: [
      {
        type: 'dropdown',
        id: 'smartphone',
        instructions: 'Smartphone',
        options: [
          {
            id: 'device1',
            text: 'Device 1',
          },
          {
            id: 'device2',
            text: 'Device 2',
          },
          {
            id: 'device3',
            text: 'Device 3',
          },
        ],
      },
      {
        type: 'half-radio',
        id: 'device-porting',
        instructions: 'Will you be porting in your phone number (s)?',
        options: [
          {
            title: 'No',
            id: 'no',
          },
          {
            title: 'Yes',
            id: 'yes',
          },
        ],
      },
      {
        type: 'full-radio',
        id: 'device-sim',
        instructions: 'Select a SIM',
        options: [
          {
            title: 'TELUS LTE TRI SIM',
            id: 'telus-sim',
            cost: 0.0,
          },
          {
            title: 'Provide my own SIM',
            id: 'own-sim',
            cost: '',
          },
        ],
      },
    ],
  },
  additionalOptions: [
    {
      title: 'Bring your own device options',
      id: 'device-options',
    },
    {
      title: 'Choose a rate plan',
      id: 'rate-plan-options',
      instructions: 'Select a new rate plan',
      options: [
        {
          id: 'plan-corp50',
          title: 'Corp Advantage VD 50 1GB PDA',
          cost: 52.5,
          costPer: 'month',
          details: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
        {
          id: 'plan-corp60',
          title: 'Corp Advantage VD 60 3GB PDA',
          cost: 62.5,
          costPer: 'month',
          details: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
        },
      ],
    },
    {
      title: 'Choose your add-ons',
      id: 'add-ons-options',
      instructions: 'Select add-ons for this rate plan',
      options: [
        {
          id: 'corpdata',
          title: 'Corporate Data Block',
          cost: 0.0,
          costPer: 'month',
        },
        {
          id: 'voicemail5',
          title: 'Corp Adv Voice 20',
          cost: 20.0,
          costPer: 'month',
        },
        {
          id: 'corpadv-vd',
          title: 'Corp Advantage VD 50 1GB PDA',
          cost: 52.5,
          costPer: 'month',
        },
        {
          id: 'voicemail25',
          title: 'Bus Visual Voicemail 25',
          cost: 62.5,
          costPer: 'month',
        },
      ],
    },
  ],
};

export const exampleByodOrderInfo = {
  cartContents: [
    {
      deviceIcon: '/icons/device-icon.svg',
      deviceName: 'Bring your own device',
    },
  ],
  dueMonthly: 0,
  dueNow: 19.45,
  upfrontCosts: [
    // {
    //   label: 'IPHONE 14 PRO MAX 128GB BLACK',
    //   cost: 221.0,
    // },
    // {
    //   label: 'TELUS LTE TRI SIM',
    //   cost: 'free',
    // },
  ],
  monthlyCosts: [
    // {
    //   category: 'Rate plan',
    //   link: '',
    //   items: [
    //     {
    //       label: 'CoO ULNA Voice - SIM only',
    //       cost: 20,
    //     },
    //   ],
    // },
    // {
    //   category: 'Add-ons',
    //   link: '',
    //   items: [
    //     {
    //       label: 'Corporate Data Block',
    //       cost: 'free',
    //     },
    //     {
    //       label: 'Bus Visual Voicemail 5',
    //       cost: 5,
    //     },
    //   ],
    // },
  ],
  // shippingCost: 'Free',
  // salesTax: 12.0,
  estimatedTotal: 134.0,
};

export const exampleUserInfo = {
  accounts: [31099732, 31099747, 31099749],
};

export const exampleDeviceFilter = [
  {
    make: { label: 'iPhone', id: 'iphone' },
    models: [
      { label: 'Apple iPhone 14 plus', id: 'iphone-14-plus' },
      { label: 'Apple iPhone 14 pro', id: 'iphone-14-pro' },
      { label: 'Apple iPhone 13', id: 'iphone-13' },
    ],
  },
  {
    make: { label: 'Google', id: 'google' },
    models: [
      { label: 'Google Pixel 3', id: 'pixel-3' },
      { label: 'Google Pixel 4', id: 'pixel-4' },
      { label: 'Google Pixel 4 Pro', id: 'pixel-4-pro' },
    ],
  },
  {
    make: { label: 'Samsung', id: 'samsung' },
    models: [
      { label: 'Samsung Galaxy A51', id: 'galaxy=a51' },
      { label: 'Samsung Galaxy S23', id: 'galaxy-s23' },
      { label: 'Samsung Galaxy M51', id: 'galaxy-m51' },
    ],
  },
];

export const exampleFilteredAccessoriesState = {
  device: 'New Device Group 1 Apple iPhone 14',
  categories: [
    { label: 'Recent Accessories', href: '#recent', id: 'recent' },
    { label: 'Mounts & Stands', href: '#stands', id: 'stands' },
    { label: 'Other', href: '#other', id: 'other' },
    { label: 'Holsters', href: '#holsters', id: 'holsters' },
    { label: 'Audio', href: '#audio', id: 'audio' },
    { label: 'Screen Protectors', href: '#protectors', id: 'protectors' },
    { label: 'Charging & Power', href: '#power', id: 'power' },
    { label: 'Cases', href: '#cases', id: 'cases' },
    { label: 'SIMs', href: '#sims', id: 'sims' },
  ],
  listOfAccessories: {
    accessory1: {
      brand: 'apple',
      name: 'AirPod Pro (2nd generation)',
      id: 'airpod-pro-2nd',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 12,
      cost: 359.0,
      image: '/images/devices/accessory-earbuds.png',
    },
    accessory2: {
      brand: 'apple',
      name: 'iPhone 14',
      id: 'headphone-ip14',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 4,
      cost: 39.99,
      image: '/images/devices/accessory-headphone.png',
    },
    accessory3: {
      brand: 'apple',
      name: 'AirPod Pro (3rd generation)',
      id: 'airpod-pro-3nd',
      availabilityText: 'Available (Your order will ship in 3-5 days)',
      available: true,
      stockLimit: 12,
      cost: 359.0,
      image: '/images/devices/accessory-earbuds.png',
    },
  },
};

export const exampleEnduserPaymentOptions = {
  id: 'enduser-payment',
  ban: ['31097479', '30087449', '31145678', '30998152'],
  additionalOptions: [
    {
      title: 'Choose an extended warranty',
      id: 'warranty-options',
      // instructions: 'Select a warranty for this rate plan',
      options: [
        {
          id: 'dc-no',
          title: 'No',
        },
        {
          id: 'dc-complete1',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
        {
          id: 'dc-complete2',
          title: 'Device Care Complete',
          cost: 15.0,
          costPer: 'month',
          copy: '',
          greenText: '',
          checklist: [
            'Complete protection for accidental damage and lost or stolen devices',
            'Same day smartphone repairs',
            'Unlimited cracked screen and battery repairs',
            'Up to 2 device replacements every 12 months',
          ],
          // details: [],
        },
      ],
    },
    {
      title: 'Choose to enroll in Apple DEP',
      id: 'dep-options',
      options: [
        {
          id: 'dep-no',
          title: 'No',
          cost: '',
          costPer: '',
          copy: '',
        },
        {
          id: 'dep-yes',
          title: 'Yes',
          cost: 15.0,
          costPer: 'subscriber',
          copy: 'Account # 538792',
        },
      ],
    },
    // {
    //   title: 'Payment options',
    //   id: 'payment-options',
    //   options: [
    //     {
    //       id: 'dep-no',
    //       title: 'No',
    //       cost: '',
    //       costPer: '',
    //       copy: '',
    //     },
    //     {
    //       id: 'dep-yes',
    //       title: 'Yes',
    //       cost: 15.0,
    //       costPer: 'subscriber',
    //       copy: 'Account # 538792',
    //     },
    //   ],
    // },
  ],
  paymentOptions: {
    title: 'Select an account for payment',
    type: 'select',
    id: 'payment-options',
    options: [
      {
        id: 'ban-31097479',
        title: 'Charge to BAN - 31097479',
      },
      {
        id: 'credit-card',
        title: 'Charge to Employee Credit Card',
      },
    ],
  },
};
