import React, { useContext } from 'react';
import {
  Spacer,
  StackView,
  StackWrap,
  Typography,
  FlexGrid,
  Image,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import styled from 'styled-components';
import { Button } from '@telus-uds/components-web';

const SelectDeviceContainer = styled.div`
  position: relative;
  height: auto;
  width: 270px;
  margin-top: 3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
`;

const MarginContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 3rem);
`;

const DeviceImage = styled.div`
  display: block;
  > img {
    height: 200px;
    width: auto;
  }
`;

const SelectDeviceCard = ({ deviceInfo, optionSelect, selected }) => {
  return (
    <SelectDeviceContainer>
      <MarginContainer>
        <StackView>
          <FlexGrid gutter={false}>
            <FlexGrid.Row verticalAlign="bottom">
              <FlexGrid.Col xs={8}>
                <DeviceImage>
                  <Image
                    src={`../assets${deviceInfo.image}`}
                    alt={`image of ${deviceInfo.name}`}
                    height={200}
                    width={150}
                  />
                </DeviceImage>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
          <Spacer space={6} />
          <Typography block variant={{ size: 'eyebrow', colour: 'dark' }}>
            {deviceInfo.brand}
          </Typography>
          <Typography
            block
            variant={{ size: 'large' }}
            tokens={{ color: palette.color.purpleTelus }}
          >
            {deviceInfo.name}
          </Typography>
          <Spacer space={5} />
          <StackWrap gap={3} tokens={{ justifyContent: 'space-between' }}>
            {deviceInfo.options.map((option, i) => (
              <Button
                onPress={() => optionSelect(option.id)}
                key={i}
                variant={selected[option.id] ? { priority: 'high' } : {}}
                tokens={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  minWidth: '108px',
                }}
              >
                {option.label}
              </Button>
            ))}
          </StackWrap>
        </StackView>
      </MarginContainer>
    </SelectDeviceContainer>
  );
};

export default SelectDeviceCard;
