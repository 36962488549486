import React from 'react';
import {
  Spacer,
  StackView,
  StackWrap,
  Typography,
  FlexGrid,
  Image,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import styled from 'styled-components';
import { Box } from '@telus-uds/components-web';

const DeviceContainer = styled.div`
  position: relative;
  min-height: 460px;
  height: auto;
  width: 270px;
  margin-top: 3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MarginContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: calc(100% - 3rem);
  flex-grow: 1;
`;

const DeviceImage = styled.div`
  display: block;
  > img {
    height: 200px;
    width: auto;
  }
`;

const DeviceCard = ({ deviceInfo, deviceKey, deviceClick }) => {
  return (
    <DeviceContainer onClick={() => deviceClick(deviceKey, deviceInfo.name)}>
      <MarginContainer>
        <FlexGrid gutter={false}>
          <FlexGrid.Row verticalAlign="bottom">
            <FlexGrid.Col xs={8}>
              <DeviceImage>
                <Image
                  src={`../assets${deviceInfo.image}`}
                  alt={`image of ${deviceInfo.name}`}
                  height={200}
                  width={150}
                />
              </DeviceImage>
            </FlexGrid.Col>
            {deviceInfo.symbol ? (
              <FlexGrid.Col xs={4} horizontalAlign={'right'}>
                <Image
                  src={`../assets${deviceInfo.symbol}`}
                  alt={deviceInfo.symbolMeaning}
                  width={50}
                  height={50}
                />
              </FlexGrid.Col>
            ) : (
              ''
            )}
          </FlexGrid.Row>
        </FlexGrid>
        <StackView>
          <Spacer space={4} />
          <Typography block variant={{ size: 'eyebrow' }}>
            {deviceInfo.brand}
          </Typography>
          <Typography
            block
            variant={{ size: 'large' }}
            tokens={{ color: palette.color.purpleTelus }}
          >
            {deviceInfo.name}
          </Typography>
          {deviceInfo.tags ? (
            <>
              <StackWrap space={2}>
                {deviceInfo.tags.map((tag, i) => (
                  <Box
                    key={i}
                    variant={{ background: 'light' }}
                    space={1}
                    tokens={{
                      borderWidth: '1px',
                      borderColor: `${palette.color.greyMystic}`,
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                    }}
                  >
                    <Typography>{tag}</Typography>
                  </Box>
                ))}
              </StackWrap>
              <Spacer space={1} />
            </>
          ) : null}
          {deviceInfo.available ? (
            <Typography
              block
              variant={{ size: 'small' }}
              tokens={{ color: palette.color.greenAccessible }}
            >
              {deviceInfo.availabilityText}
            </Typography>
          ) : (
            <Typography
              block
              variant={{ size: 'small' }}
              tokens={{ color: palette.color.red }}
            >
              {deviceInfo.availabilityText}
            </Typography>
          )}
          <Spacer space={3} />
          <Typography block variant={{ size: 'small' }}>
            Starting from
          </Typography>
          <StackWrap>
            <Typography>$</Typography>
            <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
              {deviceInfo.cost.toFixed(2)}
            </Typography>
          </StackWrap>
        </StackView>
      </MarginContainer>
    </DeviceContainer>
  );
};

export default DeviceCard;
