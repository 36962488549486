import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Modal,
  Box,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import { SubscribersContext } from 'context/SubscribersContext';
import { Select, TextInput } from '@telus-uds/components-web';
import styled from 'styled-components';
import { DeviceContext } from 'context/DeviceContext';
import AddressField from 'elements/AddressField';
import EmailField from 'elements/EmailField';
import PhoneField from 'elements/PhoneField';
import PostalField from 'elements/PostalField';
import SelectField from 'elements/SelectField';
import TextField from 'elements/TextField';

const HalfWidth = styled.div`
  width: 45%;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const SelectPlaceHolder = 'Please select...';

const AddSubscriber = () => {
  const { optionsTracker } = useContext(DeviceContext);
  const { newSubscriber, addSubscriber } = useContext(SubscribersContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriberData, setSubscriberData] = useState(null);
  const [portingData, setPortingData] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [showPhonePorting, setShowPhonePorting] = useState(false);

  useEffect(() => {
    initializeSubscriberData();
    // console.log('deviceOptions: ', deviceOptions);
    // console.log('optionsTracker: ', optionsTracker);
  }, []);

  useEffect(() => {
    if (subscriberData) {
      if (subscriberData.some((data) => data.required && data.value === '')) {
        setFormComplete(false);
      } else if (portingData) {
        if (
          getPortingValue('port-number') &&
          (getPortingValue('account-number') || getPortingValue('imei'))
        ) {
          setFormComplete(true);
        } else {
          setFormComplete(false);
        }
      } else {
        setFormComplete(true);
      }
    }
  }, [subscriberData, portingData]);

  const initializeSubscriberData = () => {
    const newSubscriberData = [...newSubscriber.data];
    newSubscriber.fields.map((field) => {
      const isRequired = !(
        field.id.includes('apt') || field.id.includes('notes')
      );
      newSubscriberData.push({ id: field.id, value: '', required: isRequired });
    });
    newSubscriberData.push({
      id: 'ban',
      value: newSubscriber.bans[0],
      required: true,
    });
    newSubscriberData.push({
      id: 'language',
      value: newSubscriber.languages[0],
      required: true,
    });
    setShowPhonePorting(false);
    if (optionsTracker.deviceOptions) {
      if (
        optionsTracker.deviceOptions.find(
          (option) => option.id === 'device-porting'
        ).selection === 'yes'
      ) {
        console.log('setting up phone porting options');
        const newPortingData = [];
        newPortingData.push({
          id: 'account-number',
          value: '',
          required: true,
        });
        newPortingData.push({ id: 'imei', value: '', required: true });
        newPortingData.push({ id: 'port-number', value: '', required: true });
        setPortingData(newPortingData);
        setShowPhonePorting(true);
      } else {
        newSubscriberData.push({
          id: 'calling-city',
          value: '',
          required: true,
        });
        newSubscriberData.push({ id: 'area-code', value: '', required: true });
      }
    } else {
      newSubscriberData.push({ id: 'calling-city', value: '', required: true });
      newSubscriberData.push({
        id: 'area-code',
        value: newSubscriber.areaCodes[0],
        required: true,
      });
    }
    if (newSubscriber.departments) {
      newSubscriberData.push({ id: 'department', value: '', required: true });
      newSubscriberData.push({
        id: 'employee-email',
        value: '',
        required: true,
      });
    }
    // Add function to check for group name, and set 'group-name' to it if there is
    setSubscriberData(newSubscriberData);
  };

  // const handleCancel = () => {
  //   let newSubscriberData = [...subscriberData];
  //   newSubscriber.fields.map((field) => {
  //     newSubscriberData.find((data) => data.id === field.id).value = '';
  //   });
  //   setSubscriberData(newSubscriberData);
  //   setModalOpen(false);
  // };

  const handleAdd = () => {
    const newId = subscriberData
      .find((data) => data.id === newSubscriber.fields[0].id)
      .value.replace(/\s+/g, '-')
      .toLowerCase();
    // alert(`adding subscriber ${newId}`);
    // console.log(subscriberData);
    addSubscriber(newId, subscriberData);
    initializeSubscriberData();
    setModalOpen(false);
  };

  const handleSaveAndAdd = () => {
    const newId = subscriberData
      .find((data) => data.id === newSubscriber.fields[0].id)
      .value.replace(/\s+/g, '-')
      .toLowerCase();
    // alert('adding subscriber');
    // console.log(subscriberData);
    addSubscriber(newId, subscriberData);
    initializeSubscriberData();
    // handleCancel();
  };

  const setValue = (id, value) => {
    const newSubscriberData = [...subscriberData];
    newSubscriberData.find((data) => data.id === id).value = value;
    setSubscriberData(newSubscriberData);
  };

  const getValue = (id) => {
    // console.log('id: ', id);
    // console.log('subscriberData: ', subscriberData);
    return subscriberData.find((data) => data.id === id).value;
  };

  const getPortingValue = (id) => {
    // console.log('id: ', id);
    // console.log('subscriberData: ', subscriberData);
    return portingData.find((data) => data.id === id).value;
  };

  const setPortingValue = (id, value) => {
    const newPortingData = [...portingData];
    newPortingData.find((data) => data.id === id).value = value;
    setPortingData(newPortingData);
  };
  //
  const GetFieldsMarkup = (fields) => {
    //
    return fields.map((field, i) => {
      let FieldMarkup = (
        <TextField
          label={field.label}
          value={getValue(field.id)}
          required={field.required}
          onchange={(value) => setValue(field.id, value)}
        />
      );
      if (field.id.includes('phone')) {
        FieldMarkup = (
          <PhoneField
            label={field.label}
            data={getValue(field.id)}
            onchange={(value) => setValue(field.id, value)}
          />
        );
      } else if (field.id.includes('address')) {
        FieldMarkup = (
          <AddressField
            label={field.label}
            data={getValue(field.id)}
            onchange={(value) => setValue(field.id, value)}
          />
        );
      } else if (field.id.includes('postal')) {
        FieldMarkup = (
          <PostalField
            label={field.label}
            data={getValue(field.id)}
            onchange={(value) => setValue(field.id, value)}
          />
        );
      } else if (field.id.includes('province')) {
        FieldMarkup = (
          <SelectField
            label={field.label}
            options={field.options}
            placeholder={SelectPlaceHolder}
            onchange={(value) => setValue(field.id, value)}
          />
        );
      } else if (field.id.includes('email')) {
        FieldMarkup = (
          <EmailField
            label={field.label}
            value={getValue(field.id)}
            onchange={(value) => setValue(field.id, value)}
          />
        );
      }
      //
      // return column width
      //
      if (field.id.includes('notes')) {
        return (
          <FullWidth key={i}>
            {FieldMarkup}
            <Spacer space={4} />
          </FullWidth>
        );
      }
      return (
        <HalfWidth key={i}>
          {FieldMarkup}
          <Spacer space={4} />
        </HalfWidth>
      );
    });
    //
  };
  //
  return (
    <>
      <Button variant={{ priority: 'high' }} onPress={() => setModalOpen(true)}>
        Add subscribers
      </Button>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Typography block variant={{ size: 'h1' }}>
          Add subscriber
        </Typography>
        {subscriberData ? (
          <>
            <Box vertical={4}>
              <HalfWidth>
                <SelectField
                  label="BAN"
                  options={newSubscriber.bans}
                  value={getValue('ban')}
                  placeholder={SelectPlaceHolder}
                  onchange={(value) => setValue('ban', value)}
                />
              </HalfWidth>
              <Spacer space={4} />
              <StackWrap tokens={{ justifyContent: 'space-between' }}>
                {GetFieldsMarkup(newSubscriber.fields)}
              </StackWrap>
              {!showPhonePorting ? (
                <StackWrap tokens={{ justifyContent: 'space-between' }}>
                  <HalfWidth>
                    <TextField
                      label="Calling city"
                      value={getValue('calling-city')}
                      required
                      onchange={(value) => setValue('calling-city', value)}
                    />
                    <Spacer space={4} />
                  </HalfWidth>
                  <HalfWidth>
                    <SelectField
                      label="Preferred area code"
                      options={newSubscriber.areaCodes}
                      value={getValue('area-code')}
                      placeholder={SelectPlaceHolder}
                      onchange={(value) => setValue('area-code', value)}
                    />
                    <Spacer space={4} />
                  </HalfWidth>
                </StackWrap>
              ) : null}
              <HalfWidth>
                <SelectField
                  label="Voicemail language"
                  options={newSubscriber.languages}
                  value={getValue('language')}
                  placeholder={SelectPlaceHolder}
                  onchange={(value) => setValue('language', value)}
                />
              </HalfWidth>
            </Box>
            {showPhonePorting ? (
              <>
                <Spacer space={3} />
                <Box space={4} variant={{ background: 'light' }}>
                  <Typography block variant={{ size: 'h2' }}>
                    Port in phone number
                  </Typography>
                  <Spacer space={4} />
                  <StackWrap tokens={{ justifyContent: 'space-between' }}>
                    <HalfWidth>
                      <TextField
                        label="Account number"
                        value={getPortingValue('account-number')}
                        required
                        onchange={(value) =>
                          setPortingValue('account-number', value)
                        }
                        validation={
                          getPortingValue('imei') ||
                          getPortingValue('account-number')
                            ? null
                            : 'error'
                        }
                        feedback={
                          getPortingValue('imei') ||
                          getPortingValue('account-number')
                            ? null
                            : 'Please enter an account number or IMEI'
                        }
                        tooltip="Tooltip about account number"
                      />
                    </HalfWidth>
                    <StackView>
                      <Spacer space={6} />
                      <Typography>OR</Typography>
                    </StackView>
                    <HalfWidth>
                      <TextField
                        label="IMEI"
                        value={getPortingValue('imei')}
                        required
                        onchange={(value) => setPortingValue('imei', value)}
                        validation={
                          getPortingValue('imei') ||
                          getPortingValue('account-number')
                            ? null
                            : 'error'
                        }
                        feedback={
                          getPortingValue('imei') ||
                          getPortingValue('account-number')
                            ? null
                            : 'Please enter an account number or IMEI'
                        }
                      />
                    </HalfWidth>
                  </StackWrap>
                  <Spacer space={4} />
                  <PhoneField
                    label="Phone number NEW"
                    data={getPortingValue('port-number')}
                    onchange={(value) => setPortingValue('port-number', value)}
                  />
                </Box>
              </>
            ) : null}
            {newSubscriber.departments ? (
              <>
                <Spacer space={4} />
                <Box space={4} variant={{ background: 'light' }}>
                  <Typography block variant={{ size: 'h2' }}>
                    Apply subscriber options
                  </Typography>
                  <Spacer space={4} />
                  <SelectField
                    label="Employee department"
                    value={
                      getValue('department')
                        ? getValue('department')
                        : undefined
                    }
                    options={newSubscriber.departments}
                    placeholder={SelectPlaceHolder}
                    onchange={(value) => setValue('department', value)}
                  />
                  <Spacer space={4} />
                  <EmailField
                    label="Employee email"
                    value={getValue('employee-email')}
                    required
                    onchange={(value) => setValue('employee-email', value)}
                  />
                </Box>
              </>
            ) : null}
          </>
        ) : null}
        <Box vertical={4}>
          <StackWrap
            space={6}
            direction="row-reverse"
            tokens={{ alignItems: 'center' }}
          >
            <Button
              variant={{ priority: 'high' }}
              onPress={() => handleAdd()}
              inactive={!formComplete}
            >
              Add subscriber
            </Button>
            <StackView>
              {/* <TextButton
                variant={{ alternative: true }}
                onPress={() => handleCancel()}
              >
                Cancel
              </TextButton> */}
              <TextButton
                variant={{ alternative: true }}
                onPress={() => handleSaveAndAdd()}
              >
                Save and add another
              </TextButton>
            </StackView>
          </StackWrap>
        </Box>
      </Modal>
    </>
  );
};

export default AddSubscriber;
