import {
  Divider,
  FlexGrid,
  Spacer,
  StackView,
  Typography,
  Box,
  StackWrap,
  TextButton,
  Icon,
  Table,
  Checkbox,
  Span,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import React from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  table {
    width: 100%;

    thead {
      height: 5rem;
      tr th {
        background-color: #fff;
        box-shadow: none;
      }
    }

    tbody {
      tr {
        height: 5rem;
        > td {
          background-color: ${palette.color.greyAthens};
          box-shadow: none;
        }

        &:nth-child(even) {
          > td {
            background-color: #fff;
          }
        }
      }
    }
    td,
    th {
      max-width: 250px;
      padding: 12px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 74px;
  min-width: 24px;
  width: 8%;
`;

const OrderItem = ({
  itemOverflow,
  itemDetails,
  itemNumber,
  setDetailsOpen,
  setSubscribersOpen,
  multipleItems,
  checkControl,
  handleCheck,
}) => {
  return (
    <StackView space={0}>
      <Spacer space={5} />
      <FlexGrid limitWidth={false} gutter={false}>
        <FlexGrid.Row>
          {checkControl ? (
            <CheckboxWrapper>
              <Checkbox
                checked={checkControl[itemNumber - 1].returning}
                onChange={() => handleCheck(itemNumber - 1)}
              />
            </CheckboxWrapper>
          ) : null}
          <FlexGrid.Col>
            <FlexGrid.Row>
              {multipleItems ? (
                <FlexGrid.Col xs={1}>
                  <Box horizontal={3}>
                    <Typography variant={{ bold: true }}>
                      {itemNumber}
                    </Typography>
                  </Box>
                </FlexGrid.Col>
              ) : null}
              <FlexGrid.Col xs={multipleItems ? 11 : 12}>
                <FlexGrid.Row>
                  <FlexGrid.Col xs={5}>
                    <Typography variant={{ bold: true }}>
                      {itemDetails.itemName}
                    </Typography>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={7}>
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={3}>
                        <Typography variant={{ bold: true }}>
                          {itemDetails.quantity}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography variant={{ bold: true }}>
                          ${itemDetails.dueMonthly.toFixed(2)}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography variant={{ bold: true }}>
                          ${itemDetails.dueNow.toFixed(2)}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                          {itemOverflow ? (
                            <TextButton onPress={setDetailsOpen}>
                              {itemOverflow.detailsOpen ? (
                                <Icon
                                  icon={CaretUp}
                                  variant={{ size: 'small' }}
                                />
                              ) : (
                                <Icon
                                  icon={CaretDown}
                                  variant={{ size: 'small' }}
                                />
                              )}
                            </TextButton>
                          ) : (
                            ''
                          )}
                          <Spacer space={1} direction="row" />
                        </StackWrap>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
      <Spacer space={5} />
      {itemOverflow.detailsOpen ? (
        <FlexGrid limitWidth={false} gutter={false}>
          <FlexGrid.Row>
            {checkControl ? <CheckboxWrapper /> : null}
            <FlexGrid.Col>
              <FlexGrid.Row>
                <FlexGrid.Col xs={4} xsOffset={multipleItems ? 1 : 0}>
                  <StackView>
                    {itemDetails.detailsTitle ? (
                      <Typography>{itemDetails.detailsTitle}</Typography>
                    ) : (
                      ''
                    )}
                    <Spacer space={5} />
                    {itemDetails.details.length > 0
                      ? itemDetails.details.map((detail, i) => (
                          <Typography key={i}>
                            <Span variant={{ bold: true }}>
                              {detail.title}:
                            </Span>{' '}
                            {detail.value}
                          </Typography>
                        ))
                      : ''}
                    <Spacer space={5} />
                  </StackView>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid.Col>
          </FlexGrid.Row>
          <Divider variant={{ decorative: true }} />
        </FlexGrid>
      ) : (
        ''
      )}
      <Box
        variant={itemOverflow.subscribersOpen ? {} : { background: 'light' }}
        vertical={5}
      >
        <StackWrap tokens={{ justifyContent: 'flex-end' }}>
          <TextButton onPress={setSubscribersOpen}>
            {itemOverflow.subscribersOpen ? (
              <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                <Typography>Hide subscriber details</Typography>
                <Spacer space={2} direction="row" />
                <Icon icon={CaretUp} variant={{ size: 'small' }} />
                <Spacer space={1} direction="row" />
              </StackWrap>
            ) : (
              <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                <Typography>Show subscriber details</Typography>
                <Spacer space={2} direction="row" />
                <Icon icon={CaretDown} variant={{ size: 'small' }} />
                <Spacer space={1} direction="row" />
              </StackWrap>
            )}
          </TextButton>
        </StackWrap>
      </Box>
      {itemOverflow.subscribersOpen ? (
        <TableContainer>
          <Table fullWidth>
            <Table.Header>
              {checkControl ? (
                <Table.Cell>
                  <CheckboxWrapper />
                </Table.Cell>
              ) : (
                <></>
              )}
              {itemDetails.subscriberHeaders.map((header, i) => (
                <Table.Cell key={i}>
                  <Typography tokens={{ color: palette.color.greyShuttle }}>
                    {header.label}
                  </Typography>
                </Table.Cell>
              ))}
            </Table.Header>
            <Table.Body>
              {itemDetails.subscribers.map((subscriber, i) => (
                <Table.Row key={i}>
                  {checkControl ? (
                    <Table.Cell>
                      <CheckboxWrapper>
                        <Checkbox
                          checked={
                            checkControl[itemNumber - 1].subscribers[i]
                              .returning
                          }
                          onChange={() => handleCheck(itemNumber - 1, i)}
                        />
                      </CheckboxWrapper>
                    </Table.Cell>
                  ) : (
                    <></>
                  )}
                  {subscriber.details.map((info, i) => (
                    <Table.Cell key={i}>
                      <Typography variant={i === 0 ? { bold: true } : {}}>
                        {info}
                      </Typography>
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TableContainer>
      ) : (
        ''
      )}
      <Divider />
    </StackView>
  );
};

export default OrderItem;
