import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
  Box,
  Button,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { exampleEnduserApprovalOrderDetails } from 'context/exampleOrderHistoryData';
import { ORDER_APPROVAL, ORDER_PAYMENT, ORDER_SUBMIT } from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';
import { HistoryContext } from 'context/HistoryContext';
import OrderDetails from 'components/OrderDetails';
import OrderInformation from 'components/OrderInformation';

const OrderDetailsPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const ApproveButtons = ({ order }) => {
  const navigate = useNavigate();

  const handleRejectPress = () => {
    alert(`Order ${order.orderNumber} rejected`);
    // setReject(true);
  };

  const handleApprove = () => {
    // alert(`Order ${order.orderNumber} approved`);
    // setReject(false);
    navigate(ORDER_PAYMENT);
  };

  return (
    <StackWrap tokens={{ justifyContent: 'flex-end' }}>
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton
            onPress={handleRejectPress}
            variant={{ alternative: true }}
          >
            Reject
          </TextButton>
        </StackView>
        <Spacer space={4} direction="row" />
        <Button
          onPress={() => handleApprove()}
          variant={{ priority: 'high' }}
          tokens={{ fontWeight: 400 }}
        >
          Approve
        </Button>
      </StackWrap>
    </StackWrap>
  );
};

const CommentBox = () => {
  const { orderDetails } = useContext(HistoryContext);

  return (
    <Box vertical={5} horizontal={4} variant={{ background: 'light' }}>
      <StackView>
        <Typography variant={{ bold: true }}>Submitter comments</Typography>
        <Spacer space={5} />
        <Typography>{orderDetails.comments}</Typography>
      </StackView>
    </Box>
  );
};

const EnduserApprovalPage = () => {
  const { setCurrentStep, setSteps } = useContext(DeviceContext);
  const { orderDetails, initializeOrderDetails } = useContext(HistoryContext);

  useEffect(() => {
    setSteps([
      { label: 'Review order request', link: ORDER_APPROVAL },
      { label: 'Order details, payment and terms', link: ORDER_PAYMENT },
      { label: 'Submit order', link: ORDER_SUBMIT },
    ]);
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    initializeOrderDetails(exampleEnduserApprovalOrderDetails);
  }, [initializeOrderDetails]);

  return (
    <OrderDetailsPageContainer>
      <Spacer space={8} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            {/* {window.location.hash
                ? `Order # ${window.location.hash.substring(1)}`
                : 'no order loaded'} */}
            Order # {orderDetails.orderNumber}
          </Typography>
          <ApproveButtons order={orderDetails} />
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <OrderInformation />
      {orderDetails.comments ? <CommentBox /> : null}
      <OrderDetails />
      <Spacer space={8} />
      <ApproveButtons order={orderDetails} />
      <Spacer space={10} />
    </OrderDetailsPageContainer>
  );
};

export default EnduserApprovalPage;
