export const exampleOrderHistoryList = {
  headers: [
    { label: 'Order ID (#)', id: 'order-number' },
    { label: 'Channel', id: 'channel' },
    { label: 'Submitter', id: 'submitter' },
    { label: 'Date Submitted', id: 'date-submitted' },
    { label: 'Type', id: 'type' },
    { label: 'Status', id: 'status' },
    { label: 'Order Total', id: 'order-total' },
  ],
  filters: [
    { label: 'Subscriber name', id: 'subscriber-name' },
    { label: 'Phone number', id: 'phone-number' },
    { label: 'Order number', id: 'order-number' },
    { label: 'IMEI', id: 'imei' },
    { label: 'Serial number', id: 'serial-number' },
    { label: 'PO number', id: 'po-number' },
  ],
  data: [
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870044', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1132.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1367.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'Comp IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1967.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99434365'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99234043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person three'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99434365'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99320043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person four', 'person five'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99877765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person four', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99587765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99823043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870423', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99412365'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99832043', '99437125'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99830043', '99437325'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['91540043', '9947665'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55299'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55539'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55119'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55339'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55519'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55349'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['56669'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55539'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55199'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55321'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544fd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cc'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544re'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4534cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['454ccd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['45hgcd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9989'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-373-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-377-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-337-7999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-377-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-373-9799'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['477-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-353-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['455-355-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-335-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-335-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34581577',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34676981',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-12-13' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Cancelled by TELUS' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34625434',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-23' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Rejected' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34965248',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-11-05' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335698',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-21' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34115264',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-10-06' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Complete' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
    [
      {
        type: 'link',
        id: 'order-number',
        value: 'TL34335268',
        link: '/order-history/order',
      },
      { id: 'channel', value: 'Admin' },
      { id: 'submitter', value: 'TIQ Laird IQ Purchaser' },
      { id: 'date-submitted', type: 'date', value: '2022-09-10' },
      { id: 'type', value: 'Purchase' },
      { id: 'status', value: 'Pending Approval' },
      { id: 'order-total', type: 'price', value: 1167.0 },
      { id: 'subscriber-name', value: ['person one', 'person two'] },
      { id: 'phone-number', value: ['444-333-9999'] },
      { id: 'imei', value: ['4544cd'] },
      { id: 'serial-number', value: ['99870043', '99437765'] },
      { id: 'po-number', value: ['55599'] },
    ],
  ],
};

export const exampleOrderDetails = {
  orderNumber: 'TL34490002',
  orderStatus: 'Pending Approval',
  orderStatusDetails: [
    { title: 'Submitted on', value: 'Oct 12, 2022 10:03 EST' },
    { title: 'Submitted by', value: 'TIQ Laird IQ Purchaser' },
    { title: 'Apple DEP status', value: 'In progress' },
    { title: 'Samsung KME status', value: 'In progress' },
    {
      title: 'Estimated arrival',
      value: 'Your order will ship in 5-7 days from date approval',
    },
  ],
  paymentInformation: [
    { title: 'Account', value: '31099749' },
    { title: 'Hardware payment method', value: 'BAN - 31099749' },
  ],
  shippingInformation: {
    name: 'Sandip Patil',
    address: '25 York Street',
    suite: '',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2V5',
    phone: '(123) 456-0000',
    email: 'email@email.com',
    notes: 'TIQ_Laird (non corp hierarchy.org)',
  },
  devicesAndPlans: [
    {
      itemId: 'item-1',
      itemName: 'New Device Group 1 Apple iPhone 14 Pro Max',
      quantity: 1,
      dueMonthly: 50.0,
      dueNow: 388.99,
      detailsTitle: 'APPLE IPHONE 14 PRO MAX 64GB BLACK',
      details: [
        { title: 'Term', value: '3 year' },
        { title: 'Plan', value: 'Corporate Complete 50' },
        {
          title: 'Promotions',
          value:
            'A credit up to $200.00 will be applied to your bill upon renewal',
        },
        { title: 'Warranty', value: 'Device Care Complete $15/month' },
        { title: 'SIM', value: 'TELUS LTE TRI SIM' },
        { title: 'SITE', value: 'Site 1' },
        { title: 'Location', value: 'Location A' },
      ],
      subscriberHeaders: [
        { label: 'Subscriber name', id: 'subscriber-name', weight: 3 },
        { label: 'Porting', id: 'porting', weight: 1 },
        { label: 'Details', id: 'details', weight: 5 },
        { label: 'Apple DEP status', id: 'dep-status', weight: 3 },
      ],
      subscribers: [
        {
          id: 'subscriber-1',
          details: [
            'Sandip Patil',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            'In progress',
          ],
        },
        {
          id: 'subscriber-2',
          details: [
            'Name Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            'In progress',
          ],
        },
        {
          id: 'subscriber-3',
          details: [
            'Name Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            'In progress',
          ],
        },
      ],
    },
    {
      itemId: 'item-2',
      itemName: 'New Device Group 2 Samsung Galaxy S22',
      quantity: 1,
      dueMonthly: 50.0,
      dueNow: 388.99,
      detailsTitle: 'SAMSUNG GALAXY S22 128GB PURPLE',
      details: [
        { title: 'Term', value: '3 year' },
        { title: 'Plan', value: 'Corporate Complete 50' },
        {
          title: 'Promotions',
          value:
            '$4.00/month activation credit available for the first 5 months',
        },
        { title: 'Warranty', value: 'Device Care Complete $15/month' },
        { title: 'SIM', value: 'TELUS LTE TRI SIM' },
        { title: 'SITE', value: 'Site 1' },
        { title: 'Location', value: 'Location A' },
      ],
      subscriberHeaders: [
        { label: 'Subscriber name', id: 'subscriber-name', weight: 3 },
        { label: 'Porting', id: 'porting', weight: 1 },
        { label: 'Details', id: 'details', weight: 5 },
        { label: 'Samsung KME status', id: 'kme-status', weight: 3 },
      ],
      subscribers: [
        {
          id: 'subscriber-1',
          details: [
            'Name Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            'In progress',
          ],
        },
      ],
    },
  ],
  subtotal: 1099.0,
  shipping: 0,
  hst: 10.99,
  total: 1099.0,
  comments: 'Textarea',
  approverComments: '',
};

export const exampleCompletedOrderDetails = {
  orderNumber: 'TL34490001',
  orderStatus: 'Complete',
  orderStatusDetails: [
    { title: 'Submitted on', value: 'Jan 05, 2022 10:03 EST' },
    { title: 'Shipped on', value: 'Jan 05, 2022 11:01 EST' },
    {
      title: 'Shipment tracking',
      value: 'UPS 123111',
    },
  ],
  paymentInformation: [
    { title: 'Account', value: '31099749' },
    { title: 'Hardware payment method', value: 'BAN - 31099749' },
  ],
  shippingInformation: {
    name: 'Sandip Patil',
    address: '25 York Street',
    suite: '',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2V5',
    phone: '(123) 456-0000',
    email: 'email@email.com',
    notes: 'TIQ_Laird (non corp hierarchy.org)',
  },
  devicesAndPlans: [
    {
      itemId: 'item-1',
      itemName: 'New Device Group 2 Samsung Galaxy S22',
      quantity: 1,
      dueMonthly: 50.0,
      dueNow: 388.99,
      detailsTitle: 'SAMSUNG GALAXY S22 128GB PURPLE',
      details: [
        { title: 'Term', value: '3 year' },
        { title: 'Plan', value: 'Corporate Complete 50' },
        {
          title: 'Promotions',
          value:
            '$4.00/month activation credit available for the first 5 months',
        },
        { title: 'Warranty', value: 'Device Care Complete $15/month' },
        { title: 'SIM', value: 'TELUS LTE TRI SIM' },
        { title: 'SITE', value: 'Site 1' },
        { title: 'Location', value: 'Location A' },
      ],
      subscriberHeaders: [
        { label: 'Subscriber name', id: 'subscriber-name', weight: 2 },
        { label: 'Porting', id: 'porting', weight: 1 },
        { label: 'Details', id: 'details', weight: 2 },
        { label: 'Phone number', id: 'phone-number', weight: 1 },
        { label: 'IMEI', id: 'imei', weight: 2 },
        { label: 'SIM #', id: 'sim-number', weight: 2 },
        { label: 'Port status', id: 'port-status', weight: 1 },
      ],
      subscribers: [
        {
          id: 'subscriber-1',
          details: [
            'Name Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            '(123) 456-0000',
            '900973778366004',
            '8912543658996632532',
            'Successful',
          ],
        },
        {
          id: 'subscriber-2',
          details: [
            'Namette Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            '(123) 456-0000',
            '900973778366004',
            '8912543658996632532',
            'Successful',
          ],
        },
      ],
    },
  ],
  subtotal: 1099.0,
  shipping: 0,
  hst: 0,
  total: 1099.0,
  comments: 'Textarea',
  approverComments: '',
};

export const exampleReturningItems = {
  orderNumber: 'TL34490001',
  items: [
    {
      itemName: 'SAMSUNG GALAXY S22 128GB BLUE',
      details: [
        {
          name: 'IMEI',
          value: '900973778366004',
        },
      ],
      reasons: [
        {
          reasonId: 'reason-1',
          reasonText: 'Device is damaged',
        },
        {
          reasonId: 'reason-2',
          reasonText: 'Device is faulty',
        },
        {
          reasonId: 'reason-3',
          reasonText: 'Device is broken',
        },
      ],
      note: 'Note: Exchanges for different model or same model but a different colour is not currently supported in TELUS IQ.',
      returnDue: 815.0,
    },
    {
      itemName: 'SAMSUNG GALAXY S23 128GB PURPLE',
      details: [
        {
          name: 'IMEI',
          value: '900973778366005',
        },
        {
          name: 'PIN',
          value: 'something12345',
        },
      ],
      reasons: [
        {
          reasonId: 'reason-1',
          reasonText: 'Device is damaged',
        },
        {
          reasonId: 'reason-2',
          reasonText: 'Device is faulty',
        },
        {
          reasonId: 'reason-3',
          reasonText: 'Device is broken',
        },
      ],
      note: 'Note: This is a different note just to make sure this is working properly.',
      returnDue: 425.0,
    },
  ],
  paymentMethod: 'Airtime Account - 70745254',
  status: 'Submitted',
  shippingInfo: ['email@email.com'],
  subtotal: 815.0,
  shipping: 0,
  total: 815.0,
};

export const exampleEnduserApprovalOrderDetails = {
  orderNumber: 'TL34490001',
  orderStatus: 'Complete',
  orderStatusDetails: [
    { title: 'Submitted on', value: 'Jan 05, 2022 10:03 EST' },
    { title: 'Shipped on', value: 'Jan 05, 2022 11:01 EST' },
    {
      title: 'Shipment tracking',
      value: 'UPS 123111',
    },
  ],
  paymentInformation: [],
  shippingInformation: {
    name: 'Sandip Patil',
    address: '25 York Street',
    suite: '',
    city: 'Toronto',
    province: 'ON',
    postalCode: 'M5J 2V5',
    phone: '(123) 456-0000',
    email: 'email@email.com',
    notes: 'TIQ_Laird (non corp hierarchy.org)',
  },
  devicesAndPlans: [
    {
      itemId: 'item-1',
      itemName: 'New Device Group 2 Samsung Galaxy S22',
      quantity: 1,
      dueMonthly: 50.0,
      dueNow: 388.99,
      detailsTitle: 'SAMSUNG GALAXY S22 128GB PURPLE',
      details: [
        { title: 'Term', value: '3 year' },
        { title: 'Plan', value: 'Corporate Complete 50' },
        {
          title: 'Promotions',
          value:
            '$4.00/month activation credit available for the first 5 months',
        },
        { title: 'Warranty', value: 'Device Care Complete $15/month' },
        { title: 'SIM', value: 'TELUS LTE TRI SIM' },
        { title: 'SITE', value: 'Site 1' },
        { title: 'Location', value: 'Location A' },
      ],
      subscriberHeaders: [
        { label: 'Subscriber name', id: 'subscriber-name', weight: 2 },
        { label: 'Porting', id: 'porting', weight: 1 },
        { label: 'Details', id: 'details', weight: 2 },
        { label: 'Phone number', id: 'phone-number', weight: 1 },
        { label: 'IMEI', id: 'imei', weight: 2 },
        { label: 'SIM #', id: 'sim-number', weight: 2 },
        { label: 'Port status', id: 'port-status', weight: 1 },
      ],
      subscribers: [
        {
          id: 'subscriber-1',
          details: [
            'Name Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            '(123) 456-0000',
            '900973778366004',
            '8912543658996632532',
            'Successful',
          ],
        },
        {
          id: 'subscriber-2',
          details: [
            'Namette Surname',
            'No',
            'City: Toronto | Prefered area code: 123 | Voicemail in English | HR | email@email.com',
            '(123) 456-0000',
            '900973778366004',
            '8912543658996632532',
            'Successful',
          ],
        },
      ],
    },
  ],
  subtotal: 1099.0,
  shipping: 0,
  hst: 0,
  total: 1099.0,
  comments: 'Textarea - submitter comments',
  approverComments: '',
};
