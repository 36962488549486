import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
  Box,
  Button,
  BaseModal,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { exampleEnduserApprovalOrderDetails } from 'context/exampleOrderHistoryData';
import { APPROVAL_VIEW_ENDUSER, ORDER_SUBMIT } from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';
import { HistoryContext } from 'context/HistoryContext';
import OrderDetails from 'components/OrderDetails';
import OrderInformation from 'components/OrderInformation';

const OrderDetailsPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const ApproveModal = ({ order }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleCancelPress = () => {
    alert('cancelling order submission');
    navigate(APPROVAL_VIEW_ENDUSER);
  };

  const handleSubmit = () => {
    alert(`Order ${order.orderNumber} approved`);
    setModalOpen(false);
    navigate(APPROVAL_VIEW_ENDUSER);
  };

  return (
    <StackWrap tokens={{ justifyContent: 'flex-end' }}>
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          <TextButton
            onPress={handleCancelPress}
            variant={{ alternative: true }}
          >
            Cancel
          </TextButton>
        </StackView>
        <Spacer space={4} direction="row" />
        <Button
          onPress={() => setModalOpen(true)}
          variant={{ priority: 'high' }}
          tokens={{ fontWeight: 400 }}
        >
          Submit order
        </Button>
        <BaseModal
          isOpen={modalOpen}
          onClose={handleCancel}
          variant={{ width: 'xl' }}
        >
          <Box space={3}>
            <Typography block variant={{ size: 'h1' }}>
              Confirmation
            </Typography>
            <Box vertical={5}>
              <Typography>Are you sure you want to submit order?</Typography>
            </Box>
            <Spacer space={4} />
            <Box vertical={3}>
              <StackView
                direction="row"
                tokens={{ alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <StackView>
                  <TextButton
                    variant={{ alternative: true }}
                    onPress={handleCancel}
                  >
                    Cancel
                  </TextButton>
                </StackView>
                <Spacer space={4} direction="row" />
                <Button variant={{ priority: 'high' }} onPress={handleSubmit}>
                  Submit order
                </Button>
              </StackView>
            </Box>
          </Box>
        </BaseModal>
      </StackWrap>
    </StackWrap>
  );
};

const SubmitterCommentBox = () => {
  const { orderDetails } = useContext(HistoryContext);

  return (
    <Box vertical={5} horizontal={4} variant={{ background: 'light' }}>
      <StackView>
        <Typography variant={{ bold: true }}>Submitter comments</Typography>
        <Spacer space={5} />
        <Typography>{orderDetails.comments}</Typography>
      </StackView>
    </Box>
  );
};

const ApproverCommentBox = () => {
  const { orderDetails } = useContext(HistoryContext);

  return (
    <Box vertical={5} horizontal={4} variant={{ background: 'light' }}>
      <StackView>
        <Typography variant={{ bold: true }}>Approver comments</Typography>
        <Spacer space={5} />
        <Typography>{orderDetails.approverComments}</Typography>
      </StackView>
    </Box>
  );
};

const EnduserSubmitPage = () => {
  const { setCurrentStep, steps } = useContext(DeviceContext);
  const { orderDetails, initializeOrderDetails } = useContext(HistoryContext);

  useEffect(() => {
    if (steps) {
      let thisStep = parseInt(
        Object.keys(steps).find((key) => steps[key].link === ORDER_SUBMIT)
      );
      setCurrentStep(thisStep);
    }
  }, []);

  useEffect(() => {
    if (!orderDetails.length) {
      initializeOrderDetails(exampleEnduserApprovalOrderDetails);
    }
  }, [initializeOrderDetails]);

  return (
    <OrderDetailsPageContainer>
      <Spacer space={8} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            {/* {window.location.hash
                ? `Order # ${window.location.hash.substring(1)}`
                : 'no order loaded'} */}
            Order # {orderDetails.orderNumber}
          </Typography>
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <OrderInformation />
      <OrderDetails />
      <Divider />
      <Spacer space={5} />

      <Typography block variant={{ size: 'h3' }} tokens={{ fontSize: 20 }}>
        Comments
      </Typography>
      <Spacer space={4} />
      {orderDetails.comments ? <SubmitterCommentBox /> : null}
      <Spacer space={6} />
      {orderDetails.comments ? <ApproverCommentBox /> : null}
      <Spacer space={8} />
      <ApproveModal order={orderDetails} />
      <Spacer space={10} />
    </OrderDetailsPageContainer>
  );
};

export default EnduserSubmitPage;
