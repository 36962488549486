import {
  Typography,
  FlexGrid,
  Spacer,
  Tabs,
  Spinner,
} from '@telus-uds/ds-allium';
// import SubHeader from 'components/SubHeader';
import React, { useContext, useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import {
  listOfDevices,
  exampleDeviceCategories,
} from 'context/exampleDeviceData';
import DeviceCard from 'components/DeviceCard';
import { useNavigate } from 'react-router-dom';
import {
  DEVICE_ACCESSORIES,
  DEVICE_OPTIONS,
  DEVICE_SELECT,
  DEVICE_SUBSCRIBERS,
} from 'constants/routes';
import { DeviceContext } from 'context/DeviceContext';

const DevicesPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const TableFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 20px 24px;
`;

const LoadMoreContainer = styled.div`
  justify-self: center;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: $color-accessible-green;
    font-weight: 600;
    cursor: pointer;

    img {
      margin-right: 8px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Entries = styled.div`
  font-weight: 700;
`;

const DEVICES_PER_BATCH = 8;

const DevicesPage = () => {
  const {
    initializeOptionsTracker,
    setSteps,
    setCurrentStep,
    setSelectedDevice,
  } = useContext(DeviceContext);
  const [category, setCategory] = useState(null);
  const navigate = useNavigate();

  ///////////////////////////////////////////////////

  const [devices, setDevices] = useState(listOfDevices);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [visibleDevices, setVisibleDevices] = useState({});
  const [numLoaded, setNumLoaded] = useState(0);

  useEffect(() => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat) {
      setCategory(urlcat);
    } else {
      setCategory('all');
    }
    setSteps([
      { label: 'Device', link: DEVICE_SELECT },
      { label: 'Plans & add-ons', link: DEVICE_OPTIONS },
      { label: 'Accessories', link: DEVICE_ACCESSORIES },
      { label: 'Subscriber information', link: DEVICE_SUBSCRIBERS },
    ]);
    setCurrentStep(0);
    setTimeout(() => {
      let newVisibleDevices = Object.keys(devices)
        .slice(0, DEVICES_PER_BATCH)
        .reduce((result, key) => {
          result[key] = devices[key];

          return result;
        }, {});
      setVisibleDevices(newVisibleDevices);
      setNumLoaded(DEVICES_PER_BATCH);
      setShowLoadMore(false);
    }, 1000);
  }, []);

  useEffect(() => {
    console.log('visible devices: ', visibleDevices);
  }, [visibleDevices]);

  const loadMore = () => {
    console.log('loading more');
    if (showLoadMore) return;

    if (Object.keys(visibleDevices).length < Object.keys(devices).length) {
      setShowLoadMore(true);
      setTimeout(supplyDevices, 3000);
    } else {
      setShowLoadMore(false);
    }
  };

  const supplyDevices = () => {
    console.log('supplying devices');
    let newVisibleDevices = { ...visibleDevices };
    console.log('newVisibleDevices: ', newVisibleDevices);
    Object.keys(devices)
      .slice(numLoaded, numLoaded + DEVICES_PER_BATCH)
      .map((result, key) => {
        newVisibleDevices[result] = devices[result];
      });
    console.log('updated newVisibleDevices: ', newVisibleDevices);
    setVisibleDevices(newVisibleDevices);
    setNumLoaded(numLoaded + DEVICES_PER_BATCH);
    setShowLoadMore(false);
  };

  const handleDeviceClick = (deviceKey, deviceName) => {
    alert(`chosen device id ${deviceKey}`);
    setSelectedDevice(deviceName);
    initializeOptionsTracker(deviceKey);
    navigate(DEVICE_OPTIONS);
  };

  const handleCategoryChange = () => {
    let urlcat = window.location.hash.split('#')[1];
    if (urlcat !== category) {
      setCategory(urlcat);
      alert(`Device category changed to ${urlcat}`);
    }
  };

  return (
    <DevicesPageContainer>
      {/* <SubHeader /> */}
      <Spacer space={6} />
      <FlexGrid limitWidth={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={12}>
            <Typography block variant={{ size: 'h1' }}>
              Select a Device for New Device Group
            </Typography>
            <Spacer space={4} />
            <Typography block>
              Choose from the brands below to find your next device
            </Typography>
            <Spacer space={6} />
            <Tabs
              items={exampleDeviceCategories}
              itemTokens={{ maxWidth: '300px' }}
              initialValue={category}
              onChange={() => handleCategoryChange()}
            />
            <Spacer space={2} />
            <FlexGrid.Row>
              {Object.keys(visibleDevices).map((keyName, keyIndex) => (
                <FlexGrid.Col
                  xs={12}
                  sm={6}
                  lg={4}
                  xl={3}
                  key={keyIndex}
                  flex={true}
                >
                  <DeviceCard
                    deviceInfo={visibleDevices[keyName]}
                    deviceKey={visibleDevices[keyName].productId}
                    deviceClick={handleDeviceClick}
                  />
                </FlexGrid.Col>
              ))}
            </FlexGrid.Row>
            <Spacer space={6} />
            <TableFooter>
              <Entries>
                <p>
                  <span>{`Showing 1-${Object.keys(visibleDevices).length} of ${
                    Object.keys(devices).length
                  }`}</span>
                </p>
              </Entries>
              {Object.keys(visibleDevices).length <
                Object.keys(devices).length && (
                <LoadMoreContainer>
                  {showLoadMore ? (
                    <button disabled type="button">
                      <Spinner
                        label=""
                        show={true}
                        variant={{ size: 'small' }}
                      />
                      loading
                    </button>
                  ) : (
                    <Waypoint onEnter={() => loadMore()}>
                      {/* <button type="button" onClick={loadMore}>
                      <img
                        src="../../assets/icons/chev-down-green.svg"
                        alt=""
                      />
                      Load more
                    </button> */}
                    </Waypoint>
                  )}
                </LoadMoreContainer>
              )}
            </TableFooter>
            <Spacer space={10} />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </DevicesPageContainer>
  );
};

export default DevicesPage;
