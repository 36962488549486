export const BASE = '/';
export const USER_SETTINGS = `${BASE}user-settings/device-options`;
export const USER_DEVICES = `${BASE}user-settings/device-display`;
export const USER_ACCESSORIES = `${BASE}user-settings/accessory-display`;
export const DEVICE_SELECT = `${BASE}device-selection`;
export const DEVICE_OPTIONS = `${BASE}device-options`;
export const DEVICE_ACCESSORIES = `${BASE}device-accessories`;
export const DEVICE_SUBSCRIBERS = `${BASE}device-subscribers`;
export const ORDER_HISTORY = `${BASE}order-history`;
export const ORDER_DETAILS = `${ORDER_HISTORY}/order`;
export const ORDER_RETURN = `${ORDER_HISTORY}/order-return`;
export const BYOD_PLANS = `${BASE}byod-plans`;
export const ACCESSORIES = `${BASE}accessories`;
export const UPGRADES = `${BASE}upgrade-subscriptions`;
export const UPGRADE_BUNDLES = `${UPGRADES}/bundles`;
export const MY_BUNDLES = `${BASE}my-bundles`;
export const APPROVAL_VIEW_ADMIN = `${BASE}approval-view-admin`;
export const APPROVAL_VIEW_ENDUSER = `${BASE}approval-view-enduser`;
export const ORDER_APPROVAL = `${BASE}approval-view/order`;
export const ORDER_PAYMENT = `${BASE}approval-view/payment`;
export const ORDER_SUBMIT = `${BASE}approval-view/submit`;
