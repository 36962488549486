import React, { useContext } from 'react';
import {
  Spacer,
  StackView,
  StackWrap,
  Typography,
  FlexGrid,
  Image,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import styled from 'styled-components';
import { Button } from '@telus-uds/components-web';

const SelectAccessoryContainer = styled.div`
  position: relative;
  height: auto;
  width: 270px;
  margin-top: 3rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
`;

const MarginContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 3rem);
`;

const AccessoryImage = styled.div`
  display: block;
  > img {
    height: 200px;
    width: auto;
  }
`;

const SelectAccessoryCard = ({ accessoryInfo, optionSelect, selected }) => {
  // const { selectedAccessories } = useContext(UserContext);

  return (
    <SelectAccessoryContainer>
      <MarginContainer>
        <StackView tokens={{ justifyContent: 'space-between', flexGrow: 1 }}>
          <FlexGrid gutter={false}>
            <FlexGrid.Row verticalAlign="bottom">
              <FlexGrid.Col xs={8}>
                <AccessoryImage>
                  <Image
                    src={`../assets${accessoryInfo.image}`}
                    alt={`image of ${accessoryInfo.name}`}
                    height={200}
                    width={150}
                  />
                </AccessoryImage>
              </FlexGrid.Col>
            </FlexGrid.Row>
            <FlexGrid.Row>
              <FlexGrid.Col>
                <Spacer space={6} />
                <Typography block variant={{ size: 'eyebrow', colour: 'dark' }}>
                  {accessoryInfo.brand}
                </Typography>
                <Typography
                  block
                  variant={{ size: 'large' }}
                  tokens={{ color: palette.color.purpleTelus }}
                >
                  {accessoryInfo.name}
                </Typography>
                <Spacer space={5} />
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid>
          <StackWrap gap={3} tokens={{ justifyContent: 'space-between' }}>
            <Button
              onPress={() => optionSelect(accessoryInfo.id)}
              variant={selected ? { priority: 'high' } : {}}
              tokens={{
                paddingLeft: 12,
                paddingRight: 12,
                minWidth: '108px',
              }}
            >
              Display
            </Button>
          </StackWrap>
        </StackView>
      </MarginContainer>
    </SelectAccessoryContainer>
  );
};

export default SelectAccessoryCard;
