import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
  Icon,
} from '@telus-uds/ds-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import palette from '@telus-uds/palette-allium';
import BundleItem from 'components/BundleItem';
import styled from 'styled-components';
import { SubscribersContext } from 'context/SubscribersContext';
import { Tabs } from '@telus-uds/components-web';

const BundleDetailsWrapper = styled.div`
  > div > div {
    flex-wrap: nowrap;
  }
`;

const BundleSection = ({ bundleData, title }) => {
  const { selectedBundle, setSelectedBundle } = useContext(SubscribersContext);
  const [overflowControl, setOverflowControl] = useState([]);

  useEffect(() => {
    let newOverflowControl = [];
    bundleData
      ? bundleData.map((bundle) => {
          newOverflowControl.push({
            detailsOpen: false,
            subscribersOpen: false,
          });
        })
      : '';
    // console.log(newOverflowControl);
    setOverflowControl(newOverflowControl);
    // console.log('overflow control: ', overflowControl);
  }, [bundleData]);

  const toggleDetails = (i) => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl[i].detailsOpen = !newOverflowControl[i].detailsOpen;
    setOverflowControl(newOverflowControl);
  };

  const expandAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = true;
      control.subscribersOpen = true;
    });
    setOverflowControl(newOverflowControl);
  };

  const collapseAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = false;
      control.subscribersOpen = false;
    });
    setOverflowControl(newOverflowControl);
  };

  const selectBundle = (id) => {
    setSelectedBundle(id);
  };

  return (
    <StackView>
      <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
        {title}
      </Typography>
      <Spacer space={8} />
      <FlexGrid limitWidth={false} gutter={false}>
        <FlexGrid.Row>
          <FlexGrid.Col xs={11} xsOffset={1}>
            <FlexGrid.Row>
              <FlexGrid.Col xs={5}>
                <Typography tokens={{ color: palette.color.greyShuttle }}>
                  Item
                </Typography>
              </FlexGrid.Col>
              <FlexGrid.Col xs={7}>
                <FlexGrid.Row>
                  <FlexGrid.Col xs={3}>
                    <Typography tokens={{ color: palette.color.greyShuttle }}>
                      Availability
                    </Typography>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={3}>
                    <Typography tokens={{ color: palette.color.greyShuttle }}>
                      Due Monthly
                    </Typography>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={3}>
                    <Typography tokens={{ color: palette.color.greyShuttle }}>
                      Due Now
                    </Typography>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={3}>
                    <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                      {overflowControl.some(
                        (obj) => obj.detailsOpen === true
                      ) ||
                      overflowControl.some(
                        (obj) => obj.subscribersOpen === true
                      ) ? (
                        <TextButton onPress={collapseAll}>
                          <StackWrap>
                            <Typography
                              tokens={{
                                color: palette.color.greyShuttle,
                              }}
                            >
                              Collapse all
                            </Typography>
                            <Spacer space={2} direction="row" />
                            <StackView tokens={{ justifyContent: 'center' }}>
                              <Icon
                                icon={CaretUp}
                                variant={{ size: 'small' }}
                              />
                            </StackView>
                            <Spacer space={1} direction="row" />
                          </StackWrap>
                        </TextButton>
                      ) : (
                        <TextButton onPress={expandAll}>
                          <StackWrap>
                            <Typography
                              tokens={{
                                color: palette.color.greyShuttle,
                              }}
                            >
                              Expand all
                            </Typography>
                            <Spacer space={2} direction="row" />
                            <Icon
                              icon={CaretDown}
                              variant={{ size: 'small' }}
                            />
                            <Spacer space={1} direction="row" />
                          </StackWrap>
                        </TextButton>
                      )}
                    </StackWrap>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid.Col>
        </FlexGrid.Row>
        <Spacer space={3} />
        <Divider />
        {bundleData
          ? bundleData.map((bundle, i) =>
              overflowControl[i] ? (
                <BundleItem
                  itemOverflow={overflowControl[i]}
                  itemDetails={bundle}
                  setDetailsOpen={() => toggleDetails(i)}
                  key={i}
                  selectedBundle={selectedBundle}
                  bundleSelector={selectBundle}
                />
              ) : null
            )
          : null}
      </FlexGrid>
      <Spacer space={8} />
    </StackView>
  );
};

const BundleDetails = () => {
  const { bundleData } = useContext(SubscribersContext);

  const [newBundles, setNewBundles] = useState(null);
  const [upgradeBundles, setUpgradeBundles] = useState(null);
  const [byodBundles, setByodBundles] = useState(null);

  const [bundleOptions, setBundleOptions] = useState([]);
  const [chosenOption, setChosenOption] = useState('all');

  useEffect(() => {
    sortBundleData();
  }, [bundleData]);

  const sortBundleData = () => {
    let newBundleOptions = [{ label: 'All bundles', id: 'all' }];
    if (bundleData.some((data) => data.type === 'new')) {
      console.log('some new');
      let newNewBundles = bundleData.filter((data) => data.type === 'new');
      setNewBundles(newNewBundles);
      newBundleOptions.push({
        label: 'New device bundles',
        id: 'new',
      });
    } else {
      setNewBundles(null);
    }
    if (bundleData.some((data) => data.type === 'upgrade')) {
      console.log('some upgrade');
      let newUpgradeBundles = bundleData.filter(
        (data) => data.type === 'upgrade'
      );
      setUpgradeBundles(newUpgradeBundles);
      newBundleOptions.push({
        label: 'Upgrade bundles',
        id: 'upgrade',
      });
    } else {
      setUpgradeBundles(null);
    }
    if (bundleData.some((data) => data.type === 'byod')) {
      console.log('some byod');
      let newByodBundles = bundleData.filter((data) => data.type === 'byod');
      setByodBundles(newByodBundles);
      newBundleOptions.push({
        label: 'BYOD bundles',
        id: 'byod',
      });
    } else {
      setByodBundles(null);
    }
    setBundleOptions(newBundleOptions);
  };

  return (
    <BundleDetailsWrapper>
      {bundleOptions.length > 2 ? (
        <>
          <Tabs
            items={bundleOptions}
            value={chosenOption}
            onChange={setChosenOption}
          />
          <Spacer space={8} />
        </>
      ) : null}
      {newBundles && (chosenOption === 'new' || chosenOption === 'all') ? (
        <BundleSection bundleData={newBundles} title="New device bundles" />
      ) : null}
      {upgradeBundles &&
      (chosenOption === 'upgrade' || chosenOption === 'all') ? (
        <BundleSection bundleData={upgradeBundles} title="Upgrade bundles" />
      ) : null}
      {byodBundles && (chosenOption === 'byod' || chosenOption === 'all') ? (
        <BundleSection bundleData={byodBundles} title="BYOD bundles" />
      ) : null}
    </BundleDetailsWrapper>
  );
};

export default BundleDetails;
