import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Divider,
  Modal,
  Box,
  Search,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { exampleAccessoriesState } from 'context/exampleDeviceData';
import { DeviceContext } from 'context/DeviceContext';
import palette from '@telus-uds/palette-allium';
import { SubscribersContext } from 'context/SubscribersContext';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import {
  DEVICE_SELECT,
  DEVICE_SUBSCRIBERS,
  MY_BUNDLES,
} from 'constants/routes';
import AddSubscriber from 'components/AddSubscriber';
// import UpdateSubscriber from 'components/UpdateSubscriber';
import {
  exampleSubscriberHeaders,
  // exampleSubscriberList,
} from 'context/exampleSubscriberData';
import { TextInput } from '@telus-uds/components-web';
import EditSubscriber from 'components/EditSubscriber';

const SubscribersPageContainer = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1008px) {
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImportModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    console.log('file: ', file);
    alert('uploading file');
    setFile();
    setModalOpen(false);
  };

  const handleSearchClick = () => {
    document.activeElement.blur();
    document.getElementById('subscriber-file-input').click();
  };

  return (
    <StackView tokens={{ justifyContent: 'center' }}>
      <TextButton
        tokens={{ color: palette.color.greyCharcoal }}
        onPress={() => setModalOpen(true)}
      >
        Advanced import
      </TextButton>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Typography block variant={{ size: 'h1' }}>
          Advanced import
        </Typography>
        <Box vertical={4}>
          <Typography>
            Save time by uploading all your subscriber details in one file
          </Typography>
        </Box>
        <Box vertical={3}>
          <Typography>
            1.{' '}
            <TextButton onPress={() => alert('downloading excel template')}>
              Download excel template
            </TextButton>
          </Typography>
          <Typography>
            2. Enter subscriber details into your Excel file
          </Typography>
          <Typography>3. Upload your completed Excel file</Typography>
        </Box>
        <Box vertical={6}>
          <Search
            // placeholder="Browse files"
            value={file ? `${file.name}` : 'Browse files'}
            readOnly={true}
            onChange={() => handleSearchClick()}
            onClear={() => setFile()}
            onSubmit={() => handleSearchClick()}
            onFocus={() => handleSearchClick()}
          />
          <div>
            <HiddenInput
              type="file"
              onChange={handleFileChange}
              id="subscriber-file-input"
            />
            {/* <div>{file && `${file.name} - ${file.type}`}</div> */}
            {/* <button onClick={handleUploadClick}>Upload</button> */}
          </div>
        </Box>
        <Box vertical={4}>
          <StackWrap
            space={6}
            direction="row-reverse"
            tokens={{ alignItems: 'center' }}
          >
            <Button
              variant={{ priority: 'high' }}
              onPress={() => handleUploadClick()}
              inactive={!file}
            >
              Upload Excel
            </Button>
            <StackView>
              <TextButton
                variant={{ alternative: true }}
                onPress={() => setModalOpen(false)}
              >
                Cancel
              </TextButton>
            </StackView>
          </StackWrap>
        </Box>
      </Modal>
    </StackView>
  );
};

const SubscribersPage = () => {
  const { setCurrentStep, steps } = useContext(DeviceContext);
  const {
    tableHeaders,
    setTableHeaders,
    subscriberData,
    selectedSubscribers,
    toggleSubscriber,
    removeSelectedSubscribers,
    groupName,
    setGroupName,
    duplicateSubscribers,
  } = useContext(SubscribersContext);
  const [formattedData, setFormattedData] = useState([]);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTableHeaders(exampleSubscriberHeaders);
  }, []);

  useEffect(() => {
    if (tableHeaders) {
      let data = [];
      for (let i = 0; i < subscriberData.length; i++) {
        let subscriberObject = [...subscriberData[i].details];
        subscriberObject.push({
          id: 'subscriber-id',
          value: subscriberData[i].id,
        });
        // Reset incomplete status
        if (subscriberObject.find((obj) => obj.incomplete)) {
          subscriberObject.find((obj) => obj.incomplete).incomplete = false;
        }
        // Check for missing field values (based on headers)
        for (let i = 0; i < tableHeaders.length; i++) {
          if (
            subscriberObject.find(
              (object) => object.id === tableHeaders[i].id
            ) === undefined ||
            !subscriberObject.find((object) => object.id === tableHeaders[i].id)
              .value
          ) {
            if (subscriberObject.find((obj) => obj.incomplete)) {
              subscriberObject.find((obj) => obj.incomplete).incomplete = true;
            } else {
              subscriberObject.push({ incomplete: true });
            }
          }
        }
        data.push(subscriberObject);
      }
      setFormattedData(data);
      if (steps) {
        let thisStep = parseInt(
          Object.keys(steps).find(
            (key) => steps[key].link === DEVICE_SUBSCRIBERS
          )
        );
        setCurrentStep(thisStep);
      }
    }
  }, [tableHeaders, subscriberData]);

  const upgradeButtons = (id) => {
    return (
      <StackWrap>
        <Button
          onPress={() => alert(`upgrading ${id}`)}
          variant={{ size: 'small' }}
        >
          Device upgrade
        </Button>
      </StackWrap>
    );
  };

  // const handleAddDevices = () => {
  //   alert('Add devices');
  //   navigate(DEVICE_SELECT);
  // };

  // const handleChooseBundle = () => {
  //   alert('Choose bundle');
  //   navigate(MY_BUNDLES);
  // };

  const handleRemove = () => {
    // alert('Remove');
    removeSelectedSubscribers();
    setRemoveModalOpen(false);
    // navigate(DEVICE_SELECT);
  };

  const handleEditSubscriber = () => {
    alert('Edit');
  };

  const handleDuplicateSubscriber = () => {
    duplicateSubscribers();
    setDuplicateModalOpen(false);
  };

  const FeeModal = ({ children, id }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const feeData = subscriberData.find((obj) => obj.id === id)
      ? subscriberData.find((obj) => obj.id === id).feeBreakdown
      : null;

    return (
      <>
        {feeData ? (
          <>
            <TextButton
              variant={{ alternative: true }}
              onPress={() => setModalOpen(true)}
              tokens={{ alignSelf: false }}
            >
              {children}
            </TextButton>
            <Modal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              variant={{ width: 'xl' }}
            >
              <Box space={4}>
                <Typography block variant={{ size: 'h1' }}>
                  Here's how it all breaks down
                </Typography>
                <Spacer space={8} />
                {feeData.items.map((data, i) => (
                  <StackWrap
                    tokens={{ justifyContent: 'space-between', flexGrow: 1 }}
                    key={i}
                    space={4}
                  >
                    <Typography variant={{ size: 'large' }}>
                      {data.label}
                    </Typography>
                    <Typography variant={{ size: 'large' }}>
                      {data.value}
                    </Typography>
                  </StackWrap>
                ))}
                <Divider variant={{ decorative: true }} space={3} />
                <StackWrap
                  tokens={{ justifyContent: 'space-between', flexGrow: 1 }}
                >
                  <Typography variant={{ size: 'large' }}>
                    {feeData.total.label}
                  </Typography>
                  <Typography variant={{ size: 'large' }}>
                    {feeData.total.value}
                  </Typography>
                </StackWrap>
                <Spacer space={6} />
                <StackWrap tokens={{ justifyContent: 'center', flexGrow: 1 }}>
                  <Button
                    variant={{ priority: 'high' }}
                    onPress={() => setModalOpen(false)}
                    tokens={{ borderRadius: '4px' }}
                  >
                    Ok
                  </Button>
                </StackWrap>
              </Box>
            </Modal>
          </>
        ) : null}
      </>
    );
  };

  const EditNameModal = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [nameValue, setNameValue] = useState(groupName);

    const handleUpdate = () => {
      setGroupName(nameValue);
      setModalOpen(false);
    };

    const handleCancel = () => {
      setNameValue(groupName);
      setModalOpen(false);
    };

    return (
      <>
        <TextButton onPress={() => setModalOpen(true)}>Edit name</TextButton>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          variant={{ width: 'xl' }}
        >
          <Box space={4}>
            <Typography block variant={{ size: 'h1' }}>
              Edit group name
            </Typography>
            <TextInput value={nameValue} onChange={setNameValue} />
            <Spacer space={8} />
            <StackWrap
              space={6}
              direction="row-reverse"
              tokens={{ alignItems: 'center' }}
            >
              <Button variant={{ priority: 'high' }} onPress={handleUpdate}>
                Update
              </Button>
              <StackView>
                <TextButton
                  variant={{ alternative: true }}
                  onPress={handleCancel}
                >
                  Cancel
                </TextButton>
              </StackView>
            </StackWrap>
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <SubscribersPageContainer>
      <Spacer space={6} />
      <FlexGrid limitWidth={false} gutter={false}>
        <FlexGrid.Row lgReverse={true}>
          <FlexGrid.Col xs={12} lg={12}>
            <Typography block variant={{ size: 'h1' }}>
              Select information for {groupName}
            </Typography>
            <Spacer space={2} />
            <EditNameModal />
            <Spacer space={8} />
            <FlexGrid.Row verticalAlign="middle">
              <FlexGrid.Col xs={6}>
                {subscriberData ? (
                  <StackWrap>
                    {/* <TextButton
                      tokens={{ color: palette.color.greyCharcoal }}
                      onPress={() => handleAddDevices()}
                    >
                      Add devices & plan
                    </TextButton>
                    <Spacer space={5} direction="row" />
                    <TextButton
                      tokens={{ color: palette.color.greyCharcoal }}
                      onPress={() => handleChooseBundle()}
                    >
                      Choose bundle
                    </TextButton> */}
                    {/* {selectedSubscribers.filter((obj) => obj.selected)
                      .length === 1 ? (
                      <TextButton
                        tokens={{ color: palette.color.greyCharcoal }}
                        onPress={() => handleEditSubscriber()}
                      >
                        Edit
                      </TextButton>
                    ) : (
                      <Typography tokens={{ color: palette.color.greyCloud }}>
                        Edit
                      </Typography>
                    )} */}
                    <EditSubscriber />
                    <Spacer space={5} direction="row" />
                    {selectedSubscribers.every((obj) => !obj.selected) ? (
                      <Typography tokens={{ color: palette.color.greyCloud }}>
                        Duplicate
                      </Typography>
                    ) : (
                      <TextButton
                        tokens={{ color: palette.color.greyCharcoal }}
                        onPress={() => setDuplicateModalOpen(true)}
                      >
                        Duplicate
                      </TextButton>
                    )}
                    <Spacer space={5} direction="row" />
                    {selectedSubscribers.every((obj) => !obj.selected) ? (
                      <Typography tokens={{ color: palette.color.greyCloud }}>
                        Remove
                      </Typography>
                    ) : (
                      <TextButton
                        tokens={{ color: palette.color.greyCharcoal }}
                        onPress={() => setRemoveModalOpen(true)}
                      >
                        Remove
                      </TextButton>
                    )}
                  </StackWrap>
                ) : (
                  <Spacer space={3} />
                )}
                <Modal
                  isOpen={removeModalOpen}
                  onClose={() => setRemoveModalOpen(false)}
                >
                  <Typography block variant={{ size: 'h1' }}>
                    Confirmation
                  </Typography>
                  <Spacer space={5} />
                  <Typography>
                    Are you sure you want to remove the selected subscribers?
                  </Typography>
                  <Spacer space={5} />
                  <Box vertical={4}>
                    <StackWrap
                      space={6}
                      direction="row-reverse"
                      tokens={{ alignItems: 'center' }}
                    >
                      <Button
                        variant={{ priority: 'high' }}
                        onPress={() => handleRemove()}
                      >
                        Yes
                      </Button>
                      <StackView>
                        <TextButton
                          variant={{ alternative: true }}
                          onPress={() => setRemoveModalOpen(false)}
                        >
                          No
                        </TextButton>
                      </StackView>
                    </StackWrap>
                  </Box>
                </Modal>
                <Modal
                  isOpen={duplicateModalOpen}
                  onClose={() => setDuplicateModalOpen(false)}
                >
                  <Typography block variant={{ size: 'h1' }}>
                    Confirmation
                  </Typography>
                  <Spacer space={5} />
                  <Typography>
                    Are you sure you want to duplicate the selected subscribers?
                  </Typography>
                  <Spacer space={5} />
                  <Box vertical={4}>
                    <StackWrap
                      space={6}
                      direction="row-reverse"
                      tokens={{ alignItems: 'center' }}
                    >
                      <Button
                        variant={{ priority: 'high' }}
                        onPress={handleDuplicateSubscriber}
                      >
                        Yes
                      </Button>
                      <StackView>
                        <TextButton
                          variant={{ alternative: true }}
                          onPress={() => setDuplicateModalOpen(false)}
                        >
                          No
                        </TextButton>
                      </StackView>
                    </StackWrap>
                  </Box>
                </Modal>
              </FlexGrid.Col>
              <FlexGrid.Col xs={6} horizontalAlign="right">
                <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                  <ImportModal />
                  <Spacer space={5} direction="row" />
                  <AddSubscriber />
                </StackWrap>
              </FlexGrid.Col>
            </FlexGrid.Row>
            <Divider space={4} />
            <FlexGrid.Row>
              {formattedData.length ? (
                <DynamicTable
                  headers={tableHeaders}
                  data={formattedData}
                  originalData={subscriberData}
                  selection={selectedSubscribers}
                  // toggleAll={toggleAll}
                  toggleRow={toggleSubscriber}
                  buttons={upgradeButtons}
                  feeModal={FeeModal}
                  noScroll={true}
                />
              ) : (
                <StackView tokens={{ flexGrow: 1 }}>
                  <Spacer space={11} />
                  <StackWrap
                    tokens={{
                      justifyContent: 'center',
                    }}
                  >
                    <Typography block variant={{ size: 'h3' }}>
                      No Subscribers have been added yet
                    </Typography>
                    <Spacer space={6} />
                  </StackWrap>
                </StackView>
              )}
            </FlexGrid.Row>
            <FlexGrid.Row>
              <StackView>
                <Spacer space={4} />
                {formattedData.length > 50 ? (
                  <Box variant={{ background: 'danger' }} space={3}>
                    <Typography>
                      There is a 50 subscriber maximum per group
                    </Typography>
                  </Box>
                ) : null}
                <Spacer space={4} />
                <Typography block variant={{ size: 'small' }}>
                  *Upgrade fee shown is as of this date time, fee may be less at
                  time of billing
                </Typography>
              </StackView>
            </FlexGrid.Row>
            <Spacer space={10} />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </SubscribersPageContainer>
  );
};

export default SubscribersPage;
