import React, { useState, useEffect } from 'react';
import { Select } from '@telus-uds/components-web';

const SelectField = ({ label, options, value, placeholder, onchange }) => {
  const [validation, setValidation] = useState();
  const [newValue, setNewValue] = useState(value);
  const defaultFeedbackContent = '';
  let optionsArray = options;
  //
  //
  if (
    options[0].id === undefined &&
    (typeof options[0] === 'string' || options[0] instanceof String)
  ) {
    optionsArray = options.map((option) => {
      return {
        id: option,
        label: option,
      };
    });
  }
  //
  const feedbackContent = {
    error: `A value must be selected`,
    success: '',
  };

  useEffect(() => {
    console.log('THIS IS THE VALUE : ', newValue);
    console.log('THESRE ARE THE OPTIONS : ', optionsArray);
    if (optionsArray.some((e) => e.id === newValue)) {
      onchange(newValue);
      setValidation('success');
    } else if (newValue !== undefined) {
      setValidation('error');
    }
  }, [newValue]);

  const feedback =
    validation === undefined
      ? defaultFeedbackContent
      : feedbackContent[validation];

  const checkValue = (val) => {
    //
    setNewValue(val);
  };

  return (
    <Select
      label={label}
      placeholder={newValue || placeholder}
      onChange={(val) => checkValue(val)}
      feedback={feedback}
      validation={validation}
    >
      {optionsArray.map((option) => (
        <Select.Item value={option.id} key={option.label}>
          {option.label}
        </Select.Item>
      ))}
    </Select>
  );
};

export default SelectField;
