import React from 'react';

const Chevron = ({ direction, color, width, height, className }) => {
  const viewbox = `0 0 ${width} ${height}`;
  let path = null;
  path = (
    <path
      fill={color}
      d="M0.2,0.2C0.6-0.1,0.9,0,1.1,0.2L6,4.7l4.9-4.5c0.2-0.2,0.6-0.4,1-0.1s0.1,0.7-0.1,0.9L6.4,6.9 C6.2,7,5.9,7,5.7,6.9c0,0-5.5-5.8-5.5-5.8C0,0.9-0.1,0.5,0.2,0.2z"
    />
  );
  const styleString = `new ${viewbox}`;
  const style = {
    enableBackground: styleString,
    transform: `rotate(${direction === 'up' ? '180deg' : '0deg'})`,
  };

  return (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewbox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {path}
    </svg>
  );
};

export default Chevron;
