import { Radio, TextInput } from '@telus-uds/components-web';
import {
  Divider,
  BaseModal,
  FlexGrid,
  Spacer,
  StackView,
  Typography,
  StackWrap,
  TextButton,
  Icon,
  Span,
  Button,
  Box,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import { SubscribersContext } from 'context/SubscribersContext';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';

const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 1.75rem;
  align-items: center;
`;

const BundleItem = ({
  itemOverflow,
  itemDetails,
  setDetailsOpen,
  selectedBundle,
  bundleSelector,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  let availabilityColor = {};

  const handleNameChange = () => {
    setModalOpen(true);
  };

  // if (item.availability === 'Available') {
  //   availabilityColor = { color: palette.color.greenAccessible };
  // }

  // if (item.availability === 'Limited') {
  //   availabilityColor = { color: palette.color.purpleTelus };
  // }

  if (itemDetails.availability === 'Expired') {
    // boxColor = { background: 'light' };
    availabilityColor = { color: palette.color.redDark };
    // textColor = { color: palette.color.greyShuttle };
    // setInfoOpen(false);
  }

  const NameModal = () => {
    const { updateBundleName } = useContext(SubscribersContext);
    const [name, setName] = useState(itemDetails.name);

    const handleSave = () => {
      updateBundleName(itemDetails.id, name);
      setModalOpen(false);
    };

    return (
      <BaseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        variant={{ width: 'xl' }}
      >
        <Typography variant={{ size: 'h2' }}>Edit bundle name</Typography>
        <Box>
          <Box vertical={4}>
            <TextInput value={name} onChange={setName} />
            <Spacer space={4} />
            <StackWrap
              space={6}
              direction="row-reverse"
              tokens={{ alignItems: 'center' }}
            >
              <Button
                variant={{ priority: 'high' }}
                onPress={() => handleSave()}
              >
                Save
              </Button>
              <StackView>
                <TextButton
                  variant={{ alternative: true }}
                  onPress={() => setModalOpen(false)}
                >
                  Cancel
                </TextButton>
              </StackView>
            </StackWrap>
          </Box>
        </Box>
      </BaseModal>
    );
  };

  return (
    <StackView space={0}>
      <Spacer space={5} />
      <FlexGrid limitWidth={false} gutter={false}>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <FlexGrid.Row>
              <FlexGrid.Col xs={1}>
                <RadioWrapper>
                  <Radio
                    checked={selectedBundle === itemDetails.id}
                    id={itemDetails.id}
                    onChange={() => bundleSelector(itemDetails.id)}
                    name="bundle-select"
                  />
                </RadioWrapper>
              </FlexGrid.Col>
              <FlexGrid.Col xs={11}>
                <FlexGrid.Row>
                  <FlexGrid.Col xs={5}>
                    <Typography variant={{ bold: true }}>
                      {itemDetails.name}
                      <Spacer space={3} direction="row" />
                      <TextButton
                        onPress={handleNameChange}
                        variant={{ alternative: true }}
                      >
                        Edit name
                      </TextButton>
                      <NameModal />
                    </Typography>
                  </FlexGrid.Col>
                  <FlexGrid.Col xs={7}>
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={3}>
                        <Typography
                          variant={{ bold: true }}
                          tokens={availabilityColor}
                        >
                          {itemDetails.availability}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography variant={{ bold: true }}>
                          ${itemDetails.dueMonthly.toFixed(2)}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <Typography variant={{ bold: true }}>
                          ${itemDetails.dueNow.toFixed(2)}
                        </Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={3}>
                        <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                          {itemOverflow ? (
                            <TextButton onPress={setDetailsOpen}>
                              {itemOverflow.detailsOpen ? (
                                <Icon
                                  icon={CaretUp}
                                  variant={{ size: 'small' }}
                                />
                              ) : (
                                <Icon
                                  icon={CaretDown}
                                  variant={{ size: 'small' }}
                                />
                              )}
                            </TextButton>
                          ) : (
                            ''
                          )}
                          <Spacer space={1} direction="row" />
                        </StackWrap>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
      <Spacer space={5} />
      {itemOverflow.detailsOpen ? (
        <FlexGrid limitWidth={false} gutter={false}>
          <FlexGrid.Row>
            <FlexGrid.Col>
              <FlexGrid.Row>
                <FlexGrid.Col xs={4} xsOffset={1}>
                  <StackView>
                    {itemDetails.notes ? (
                      <>
                        <Typography tokens={availabilityColor}>
                          {itemDetails.notes}
                        </Typography>
                        <Spacer space={2} />
                      </>
                    ) : (
                      ''
                    )}
                    {itemDetails.description ? (
                      <>
                        <Typography>{itemDetails.description}</Typography>
                        <Spacer space={4} />
                      </>
                    ) : (
                      ''
                    )}
                    {itemDetails.details.length > 0
                      ? itemDetails.details.map((detail, i) => (
                          <Typography key={i}>
                            <Span variant={{ bold: true }}>
                              {detail.label}:
                            </Span>{' '}
                            {detail.value}
                          </Typography>
                        ))
                      : ''}
                    <Spacer space={5} />
                  </StackView>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
      ) : (
        ''
      )}
      <Divider variant={{ decorative: true }} />
    </StackView>
  );
};

export default BundleItem;
