import React, { useState, useEffect } from 'react';

import { TextInput } from '@telus-uds/ds-allium';

const PostalField = ({ label, data, onchange }) => {
  const [validation, setValidation] = useState();
  const [newValue, setNewValue] = useState(data);
  const defaultFeedbackContent = '';
  const PostalRegex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

  const feedbackContent = {
    error: `The value must be a Canadian postal code`,
    success: '',
  };

  useEffect(() => {
    if (newValue !== undefined && newValue.length > 0) {
      if (PostalRegex.test(newValue)) {
        onchange(newValue);
        setValidation('success');
      } else {
        setValidation('error');
      }
    }
  }, [newValue]);

  const feedback =
    validation === undefined
      ? defaultFeedbackContent
      : feedbackContent[validation];

  const normalizeInput = (value, e) => {
    const caret = e.target.selectionStart;
    const element = e.target;
    if (!value) return value;
    const theNewValue = value
      .toUpperCase()
      .replace(/\W/g, '')
      .replace(/(...)/, '$1 ');
    //
    //
    // What is this doing???
    if (value.length > 50) {
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    }

    return theNewValue;
  };

  const checkValue = (value, e) => {
    const formattedNumber = normalizeInput(value, e);
    setNewValue(formattedNumber);
  };

  return (
    <TextInput
      label={label}
      onChange={(value, e) => checkValue(value, e)}
      value={newValue}
      feedback={feedback}
      validation={validation}
    />
  );
};

export default PostalField;
