import { exampleAccessoriesState } from 'context/exampleDeviceData';
import {
  exampleUpgradeBundlesList,
  exampleUpgradeSubscriberList,
  exampleSubscriberList,
  exampleNewSubscriberTemplate,
} from 'context/exampleSubscriberData';
import React, { createContext, useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { CONFIRMATION } from 'constants/routes';

export const SubscribersContext = createContext(null);

export const SubscribersProvider = ({ children }) => {
  // const [subscriberData, setSubscriberData] = useState(
  //   exampleUpgradeSubscriberList
  // );
  const [tableHeaders, setTableHeaders] = useState(null);
  const [subscriberData, setSubscriberData] = useState([
    ...exampleSubscriberList,
  ]);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [newSubscriber, setNewSubscriber] = useState(
    exampleNewSubscriberTemplate
  );
  const [groupName, setGroupName] = useState(exampleAccessoriesState.device);

  useEffect(() => {
    if (subscriberData) {
      resetSelectedSubscribers();
    }
    // console.log('subscriberData update: ', subscriberData);
  }, [subscriberData]);

  const resetSelectedSubscribers = () => {
    let newSelectedSubscribers = [];
    subscriberData.map((subscriber) => {
      newSelectedSubscribers.push({ id: subscriber.id, selected: false });
    });
    setSelectedSubscribers(newSelectedSubscribers);
  };

  const toggleAll = (list) => {
    let newSelectedSubscribers = [...selectedSubscribers];
    let visibleSubscribers = [];
    list.map((subscriber) => {
      visibleSubscribers.push(
        newSelectedSubscribers.find(
          (obj) =>
            obj.id ===
            subscriber.find((obj) => obj.id === 'subscriber-id').value
        )
      );
    });
    if (visibleSubscribers.every((obj) => obj.selected)) {
      // newSelectedSubscribers.map((subscriber) => {
      //   subscriber.selected = false;
      // });
      visibleSubscribers.map((subscriber) => {
        newSelectedSubscribers.find(
          (obj) => obj.id === subscriber.id
        ).selected = false;
      });
    } else {
      // newSelectedSubscribers.map((subscriber) => {
      //   subscriber.selected = true;
      // });
      visibleSubscribers.map((subscriber) => {
        newSelectedSubscribers.find(
          (obj) => obj.id === subscriber.id
        ).selected = true;
      });
    }
    setSelectedSubscribers(newSelectedSubscribers);
  };

  // look at updating this
  const toggleSubscriber = (index) => {
    let newSelectedSubscribers = [...selectedSubscribers];
    // console.log('newSelectedSubscribers: ', newSelectedSubscribers);
    newSelectedSubscribers.find((obj) => obj.id === index).selected =
      !newSelectedSubscribers.find((obj) => obj.id === index).selected;
    setSelectedSubscribers(newSelectedSubscribers);
    // console.log('selecting subscriber: ', index);
  };

  const removeSelectedSubscribers = () => {
    let newSubscriberData = [...subscriberData];
    console.log('subscriberData: ', subscriberData);
    let subscribersToRemove = selectedSubscribers.filter(
      (subscriber) => subscriber.selected
    );
    console.log('subscribersToRemove: ', subscribersToRemove);
    subscribersToRemove.map((subscriber) => {
      let index = newSubscriberData.findIndex(
        (obj) => obj.id === subscriber.id
      );
      newSubscriberData.splice(index, 1);
    });
    console.log('newSubscriberData: ', newSubscriberData);
    setSubscriberData(newSubscriberData);
    // resetSelectedSubscribers();
  };

  const addSubscriber = (id, data) => {
    console.log('passed data: ', data);
    console.log('initial subscriberData: ', subscriberData);
    let newSubscriberData = [...subscriberData];
    console.log('initialized newSubscriberData: ', newSubscriberData);
    newSubscriberData.push({ id: id, details: data });
    console.log('updated newSubscriberData: ', newSubscriberData);
    setSubscriberData(newSubscriberData);
    console.log('updated subscriberData: ', subscriberData);
  };

  const duplicateSubscribers = () => {
    let newSubscriberData = [...subscriberData];
    let duplicateSubscriberIds = [
      ...selectedSubscribers.filter((obj) => obj.selected),
    ];
    // let duplicateSubscribers = [];
    duplicateSubscriberIds.map((id) => {
      let newSubscriber = {
        ...newSubscriberData.find((obj) => obj.id === id.id),
      };
      newSubscriber.id = newSubscriber.id + '-2';
      newSubscriberData.push(newSubscriber);
    });
    console.log('newSubscriberData: ', newSubscriberData);
    // console.log('duplicateSubscribers: ', duplicateSubscribers);
    setSubscriberData(newSubscriberData);
  };

  const initializeBundleData = (bundle) => {
    setBundleData(bundle);
  };

  const removeSelectedBundle = () => {
    let newBundleData = [...bundleData];
    newBundleData = newBundleData.filter((obj) => obj.id !== selectedBundle);
    setBundleData(newBundleData);
    setSelectedBundle(null);
  };

  const updateBundleName = (id, name) => {
    let newBundleData = [...bundleData];
    newBundleData.find((data) => data.id === id).name = name;
    setBundleData(newBundleData);
  };

  // All accessible variables
  const value = useMemo(() => {
    return {
      tableHeaders,
      setTableHeaders,
      subscriberData,
      setSubscriberData,
      selectedSubscribers,
      toggleAll,
      toggleSubscriber,
      removeSelectedSubscribers,
      bundleData,
      initializeBundleData,
      removeSelectedBundle,
      selectedBundle,
      setSelectedBundle,
      updateBundleName,
      newSubscriber,
      setNewSubscriber,
      addSubscriber,
      duplicateSubscribers,
      groupName,
      setGroupName,
    };
  }, [
    tableHeaders,
    subscriberData,
    selectedSubscribers,
    bundleData,
    selectedBundle,
    newSubscriber,
    groupName,
  ]);

  return (
    <SubscribersContext.Provider value={value}>
      {children}
    </SubscribersContext.Provider>
  );
};
