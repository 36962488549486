export const exampleUserSettings = {
  restrictDevices: false,
  displayedDevices: null,
  displayedAccessories: null,
};

export const exampleAvailableDevices = [
  {
    productId: 'samgs2301',
    brand: 'samsung',
    name: 'Galaxy S23',
    image: '/images/devices/device-img-galaxy.png',
    options: [
      { id: 'samgs2301-128', label: '128 GB', selected: false },
      { id: 'samgs2301-256', label: '256 GB', selected: false },
      { id: 'samgs2301-512', label: '512 GB', selected: false },
    ],
  },
  {
    productId: 'appip14pm01',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    image: '/images/devices/device-img-iphonepro.png',
    options: [
      { id: 'appip14pm01-128', label: '128 GB', selected: false },
      { id: 'appip14pm01-256', label: '256 GB', selected: false },
    ],
  },
  {
    productId: 'appip1401',
    brand: 'apple',
    name: 'iPhone 14',
    image: '/images/devices/device-img-iphone.png',
    options: [{ id: 'appip1401-128', label: '128 GB', selected: false }],
  },
  {
    productId: 'samgs2302',
    brand: 'samsung',
    name: 'Galaxy S23',
    image: '/images/devices/device-img-galaxy.png',
    options: [{ id: 'samgs2302-128', label: '128 GB', selected: false }],
  },
  {
    productId: 'appip14pm02',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    image: '/images/devices/device-img-iphonepro.png',
    options: [{ id: 'appip14pm02-128', label: '128 GB', selected: false }],
  },
  {
    productId: 'appip1402',
    brand: 'apple',
    name: 'iPhone 14',
    image: '/images/devices/device-img-iphone.png',
    options: [{ id: 'appip1402-128', label: '128 GB', selected: false }],
  },
  {
    productId: 'samgs2303',
    brand: 'samsung',
    name: 'Galaxy S23',
    image: '/images/devices/device-img-galaxy.png',
    options: [{ id: 'samgs2303-128', label: '128 GB', selected: false }],
  },
  {
    productId: 'appip14pm03',
    brand: 'apple',
    name: 'iPhone 14 Pro Max',
    image: '/images/devices/device-img-iphonepro.png',
    options: [{ id: 'appip14pm03-128', label: '128 GB', selected: false }],
  },
];

export const exampleAvailableAccessories = [
  {
    brand: 'apple',
    name: 'AirPod Pro (2nd generation) (Certified Pre-ownded Condition: Good)',
    id: 'airpod-pro-2nd',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    stockLimit: 12,
    cost: 359.0,
    image: '/images/devices/accessory-earbuds.png',
  },
  {
    brand: 'apple',
    name: 'Silicone Case with MagSafe (iPhone 13 Pro)',
    id: 'magsafe-case-ip13pro',
    selected: false,
    availabilityText: 'Out of stock',
    available: false,
    stockLimit: 5,
    cost: 79.0,
    image: '/images/devices/accessory-case.png',
  },
  {
    brand: 'apple',
    name: 'iPhone 13',
    id: 'headphone-ip13',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    stockLimit: 12,
    cost: 39.99,
    discountedFrom: 49.99,
    image: '/images/devices/accessory-headphone.png',
  },
  {
    brand: 'samsung',
    name: 'Galaxy S22',
    id: 'adapter-galaxys22',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    stockLimit: 12,
    cost: 34.99,
    image: '/images/devices/accessory-adapter.png',
  },
  {
    brand: 'apple',
    name: 'AirPod Pro (3rd generation)',
    id: 'airpod-pro-3nd',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    stockLimit: 14,
    cost: 359.0,
    image: '/images/devices/accessory-earbuds.png',
  },
  {
    brand: 'apple',
    name: 'Silicone Case with MagSafe (iPhone 14 Pro)',
    id: 'magsafe-case-ip14pro',
    selected: false,
    availabilityText: 'Out of stock',
    available: false,
    stockLimit: 2,
    cost: 79.0,
    image: '/images/devices/accessory-case.png',
  },
  {
    brand: 'apple',
    name: 'iPhone 14',
    id: 'headphone-ip14',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 39.99,
    image: '/images/devices/accessory-headphone.png',
  },
  {
    brand: 'samsung',
    name: 'Galaxy S23',
    id: 'adapter-galaxys23',
    selected: false,
    availabilityText: 'Available (Your order will ship in 3-5 days)',
    available: true,
    cost: 34.99,
    image: '/images/devices/accessory-adapter.png',
  },
];

export const exampleTermsAndConditions = [
  '1. Indexed content',
  '2. Indexed content',
  '3. Indexed content',
  '4. Indexed content',
  '5. Indexed content',
  '6. Indexed content',
  '7. Indexed content',
  '8. Indexed content',
  '9. Indexed content',
  '10. Indexed content',
  '11. Indexed content',
  '12. Indexed content',
  '13. Indexed content',
  '14. Indexed content',
  '15. Indexed content',
  '16. Indexed content',
  'The following applies to all terms and conditions above',
  "The following content applies to the whole page and doesn't have a corresponding superscript",
];
