import { FlexGrid, TextButton, Icon } from '@telus-uds/components-web';
import {
  Box,
  StackView,
  Typography,
  Divider,
  Spacer,
  StackWrap,
  Image,
  Link,
  Button,
} from '@telus-uds/ds-allium';
import palette from '@telus-uds/palette-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import { DeviceContext } from 'context/DeviceContext';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const SummaryContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #676e73;
  position: sticky;
  top: 1rem;
`;

const OrderSummary = ({ active, onsubmit }) => {
  const { orderInfo } = useContext(DeviceContext);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <SummaryContainer>
      {orderInfo ? (
        <Box space={4}>
          <StackView space={1}>
            <Spacer space={2} />
            <Typography variant={{ size: 'h2' }}>
              {orderInfo.orderName ? orderInfo.orderName : `Order Summary:`}
            </Typography>
            <Spacer space={3} />
            {orderInfo.cartContents.length ? (
              orderInfo.cartContents.map((device, i) => (
                <StackView direction="row" key={i}>
                  {/* <StackWrap> */}
                  <Image
                    src={`../assets${device.deviceIcon}`}
                    alt={device.deviceName}
                    width={24}
                    height={24}
                  />
                  <Spacer space={4} direction="row" />
                  <StackView tokens={{ flexShrink: 1 }}>
                    {device.deviceName ? (
                      <Typography tokens={{ fontWeight: '500' }}>
                        {device.deviceName}
                      </Typography>
                    ) : null}
                    {device.deviceDetails ? (
                      <Typography>{device.deviceDetails}</Typography>
                    ) : null}
                  </StackView>
                  {/* </StackWrap> */}
                  {/* {device.deviceCost ? (
                    <Typography>{device.deviceCost}</Typography>
                  ) : (
                    ''
                  )} */}
                </StackView>
              ))
            ) : (
              <Spacer space={2} />
            )}
            <Divider space={2} variant={{ decorative: true }} />
            <StackWrap
              tokens={{
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Typography tokens={{ fontWeight: '500' }}>
                Upfront fee:
              </Typography>
              <Typography
                variant={{ size: 'large' }}
                tokens={{ fontWeight: '500', lineHeight: '24px' }}
              >
                {orderInfo.dueNow
                  ? typeof orderInfo.dueNow === 'string'
                    ? `${orderInfo.dueNow}`
                    : `$ ${orderInfo.dueNow.toFixed(2)}`
                  : '$ 0.00'}
              </Typography>
            </StackWrap>
            <Divider space={2} variant={{ decorative: true }} />
            {orderInfo.upfrontCosts ? (
              <FlexGrid outsideGutter={false} limitWidth={false}>
                {orderInfo.upfrontCosts.map((cost, i) => (
                  <React.Fragment key={i}>
                    <FlexGrid.Row verticalAlign="bottom">
                      <FlexGrid.Col xs={8} lg={7} xl={8}>
                        <Typography>{cost.label}</Typography>
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={4} lg={5} xl={4}>
                        <StackWrap
                          tokens={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography
                            variant={{ size: 'large' }}
                            tokens={{ fontWeight: '500', lineHeight: '24px' }}
                          >
                            {cost.cost
                              ? typeof cost.cost === 'string'
                                ? `${cost.cost.toUpperCase()}`
                                : `$ ${cost.cost.toFixed(2)}`
                              : 'FREE'}
                          </Typography>
                        </StackWrap>
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                    <Spacer space={3} />
                  </React.Fragment>
                ))}
              </FlexGrid>
            ) : null}
            <StackWrap
              tokens={{
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Typography tokens={{ fontWeight: '500' }}>
                Due monthly:
              </Typography>
              <Typography
                variant={{ size: 'large' }}
                tokens={{ fontWeight: '500', lineHeight: '24px' }}
              >
                {orderInfo.dueMonthly
                  ? typeof orderInfo.dueMonthly === 'string'
                    ? `${orderInfo.dueMonthly}`
                    : `$ ${orderInfo.dueMonthly.toFixed(2)}`
                  : '$ 0.00'}
              </Typography>
            </StackWrap>
            <Divider space={2} variant={{ decorative: true }} />
            {orderInfo.monthlyCosts ? (
              <StackView>
                {showDetails ? (
                  <FlexGrid gutter={false} limitWidth={false}>
                    {orderInfo.monthlyCosts.map((category, i) => (
                      <React.Fragment key={i}>
                        <FlexGrid.Row>
                          <FlexGrid.Col xs={8} lg={7} xl={8}>
                            <StackWrap tokens={{ alignItems: 'center' }}>
                              <Typography block variant={{ size: 'small' }}>
                                {category.category}
                              </Typography>
                              <Spacer space={2} direction="row" />
                              {category.link ? (
                                <Link
                                  block
                                  href={category.link}
                                  tokens={{ textLine: 'none' }}
                                  variant={{ size: 'small' }}
                                >
                                  Change
                                </Link>
                              ) : null}
                            </StackWrap>
                          </FlexGrid.Col>
                        </FlexGrid.Row>
                        <Spacer space={2} />
                        {category.items.map((item, i) => (
                          <React.Fragment key={i}>
                            <FlexGrid.Row>
                              <FlexGrid.Col xs={7} xsOffset={1}>
                                <Typography
                                  block
                                  variant={{ size: 'small' }}
                                  tokens={{
                                    lineHeight: '24px',
                                  }}
                                >
                                  {item.label}
                                </Typography>
                              </FlexGrid.Col>
                              <FlexGrid.Col xs={4} lg={5} xl={4}>
                                <StackWrap
                                  tokens={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'baseline',
                                  }}
                                >
                                  <Typography>
                                    {item.cost
                                      ? typeof item.cost === 'string'
                                        ? `${item.cost.toUpperCase()}`
                                        : `$ ${item.cost.toFixed(2)}`
                                      : '$ 0.00'}
                                  </Typography>
                                  {typeof item.cost !== 'string' ? (
                                    <Typography variant={{ size: 'micro' }}>
                                      /mo
                                    </Typography>
                                  ) : null}
                                </StackWrap>
                              </FlexGrid.Col>
                            </FlexGrid.Row>
                            <Spacer space={2} />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </FlexGrid>
                ) : null}
                {orderInfo.monthlyCosts.length ? (
                  <TextButton
                    onPress={() => setShowDetails(!showDetails)}
                    variant={{ alternative: true }}
                    tokens={{ textLine: 'none' }}
                  >
                    {showDetails ? 'Hide detail' : 'Show detail'}
                    {'    '}
                    {showDetails ? (
                      <Icon
                        icon={CaretUp}
                        variant={{ size: 'small', rank: 'primary' }}
                        tokens={{ translateY: '4' }}
                      />
                    ) : (
                      <Icon
                        icon={CaretDown}
                        variant={{ size: 'small', rank: 'primary' }}
                        tokens={{ translateY: '4' }}
                      />
                    )}
                  </TextButton>
                ) : null}
              </StackView>
            ) : null}
            {/* <>
              <StackWrap
                tokens={{
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <Typography>Shipping:</Typography>
                <Typography
                  variant={{ size: 'large' }}
                  tokens={{ fontWeight: '500' }}
                >
                  {orderInfo.shippingCost
                    ? typeof orderInfo.shippingCost === 'string'
                      ? `${orderInfo.shippingCost}`
                      : `$${orderInfo.shippingCost.toFixed(2)}`
                    : '-'}
                </Typography>
              </StackWrap>
              <StackWrap
                tokens={{
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <Typography>Sales Tax:</Typography>
                <Typography
                  variant={{ size: 'large' }}
                  tokens={{ fontWeight: '500' }}
                >
                  {orderInfo.salesTax
                    ? typeof orderInfo.salesTax === 'string'
                      ? `${orderInfo.salesTax}`
                      : `$${orderInfo.salesTax.toFixed(2)}`
                    : '-'}
                </Typography>
              </StackWrap>
            </> */}
          </StackView>
          <Divider space={3} variant={{ decorative: true }} />
          <StackWrap tokens={{ justifyContent: 'space-between' }}>
            <Typography variant={{ size: 'h3', colour: 'dark' }}>
              Estimated Total:
            </Typography>
            <StackWrap tokens={{ flexGrow: 1, justifyContent: 'end' }}>
              <Typography variant={{ size: 'h3', colour: 'dark' }}>
                {orderInfo.estimatedTotal
                  ? typeof orderInfo.estimatedTotal === 'string'
                    ? `${orderInfo.estimatedTotal}`
                    : `$${orderInfo.estimatedTotal.toFixed(2)}`
                  : '-'}
              </Typography>
            </StackWrap>
          </StackWrap>
          {onsubmit ? (
            <>
              <Spacer space={4} />
              <Button
                variant={{ priority: 'high' }}
                tokens={{ width: '100%' }}
                disabled={!active}
                onPress={() => onsubmit()}
              >
                Add to cart
              </Button>
            </>
          ) : null}
          <Spacer space={3} />
          {/* <StackWrap tokens={{ justifyContent: 'flex-end' }}>
            <Link href="#go-to-cart">View Cart</Link>
          </StackWrap>
          <Divider space={3} variant={{ decorative: true }} /> */}
          {/* <Spacer space={3} /> */}
          {orderInfo.poolProgram || orderInfo.floatBalance ? (
            <Box vertical={2}>
              <Box variant={{ background: 'light' }} space={2}>
                <StackWrap tokens={{ justifyContent: 'center' }}>
                  <Typography
                    tokens={{
                      color: palette.color.purpleTelus,
                      fontWeight: '700',
                    }}
                  >
                    {orderInfo.poolProgram
                      ? orderInfo.poolProgram
                      : orderInfo.floatBalance}
                  </Typography>
                  <Typography tokens={{ color: palette.color.purpleTelus }}>
                    {orderInfo.poolProgram
                      ? ' - Hardware pool program'
                      : ' - Float balance'}
                  </Typography>
                </StackWrap>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Spacer space={11} />
      )}
    </SummaryContainer>
  );
};

export default OrderSummary;
