import {
  Typography,
  FlexGrid,
  Spacer,
  StackView,
  Span,
} from '@telus-uds/ds-allium';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import palette from '@telus-uds/palette-allium';
import { HistoryContext } from 'context/HistoryContext';

const OrderDetailsWrapper = styled.div`
  > div > div > div:not(:last-child) {
    border-bottom: 1px solid ${palette.color.greyShuttle};
    margin-bottom: 1rem;

    @media screen and (min-width: 785px) {
      border-bottom: 0px;
      margin-bottom: 0px;
      border-right: 1px solid ${palette.color.greyShuttle};
    }
  }
`;

const ShippingInfo = ({ info }) => {
  const Line = ({ line }) => {
    return line ? (
      <Typography variant={{ size: 'small' }}>{line}</Typography>
    ) : null;
  };

  return (
    <StackView>
      <Typography block variant={{ size: 'h3' }} tokens={{ fontSize: 20 }}>
        Shipping information
      </Typography>
      <Spacer space={4} />
      {info.name ? (
        <Typography variant={{ size: 'small', bold: true }}>
          {info.name}
        </Typography>
      ) : null}
      <Spacer space={2} />
      <Line line={info.address} />
      <Line line={`${info.city}, ${info.province}`} />
      <Line line={info.postalCode} />
      <Line line={info.phone} />
      <Line line={info.email} />
      {info.notes ? (
        <>
          <Spacer space={2} />
          <Typography variant={{ size: 'small' }}>
            Shipping Notes: {info.notes}
          </Typography>
        </>
      ) : null}
      <Spacer space={3} />
    </StackView>
  );
};

const OrderInformation = () => {
  const { orderDetails } = useContext(HistoryContext);
  const [overflowControl, setOverflowControl] = useState([]);

  console.log(orderDetails);

  useEffect(() => {
    let newOverflowControl = [];
    orderDetails.devicesAndPlans
      ? orderDetails.devicesAndPlans.map((device) => {
          // console.log(device);
          newOverflowControl.push({
            detailsOpen: false,
            subscribersOpen: false,
          });
        })
      : '';
    // console.log(newOverflowControl);
    setOverflowControl(newOverflowControl);
    // console.log('overflow control: ', overflowControl);
  }, [orderDetails]);

  const toggleDetails = (i) => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl[i].detailsOpen = !newOverflowControl[i].detailsOpen;
    setOverflowControl(newOverflowControl);
  };

  const toggleSubscribers = (i) => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl[i].subscribersOpen =
      !newOverflowControl[i].subscribersOpen;
    setOverflowControl(newOverflowControl);
  };

  const expandAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = true;
      control.subscribersOpen = true;
    });
    setOverflowControl(newOverflowControl);
  };

  const collapseAll = () => {
    let newOverflowControl = [...overflowControl];
    newOverflowControl.map((control) => {
      control.detailsOpen = false;
      control.subscribersOpen = false;
    });
    setOverflowControl(newOverflowControl);
  };

  return (
    <OrderDetailsWrapper>
      <FlexGrid outsideGutter={false} limitWidth={false}>
        <FlexGrid.Row>
          <FlexGrid.Col xs={12} md={4}>
            <StackView>
              <Typography
                block
                variant={{ size: 'h3' }}
                tokens={{ fontSize: 20 }}
              >
                Status: {orderDetails.orderStatus}
              </Typography>
              <Spacer space={4} />
              {orderDetails.orderStatusDetails
                ? orderDetails.orderStatusDetails.map((detail, i) => (
                    <Typography variant={{ size: 'small' }} key={i}>
                      <Span variant={{ bold: true }}>{detail.title}:</Span>{' '}
                      {detail.value}
                    </Typography>
                  ))
                : ''}
              <Spacer space={3} />
            </StackView>
          </FlexGrid.Col>
          {orderDetails.paymentInformation ? (
            orderDetails.paymentInformation.length ? (
              <FlexGrid.Col xs={12} md={4}>
                <StackView>
                  <Typography
                    block
                    variant={{ size: 'h3' }}
                    tokens={{ fontSize: 20 }}
                  >
                    Payment information
                  </Typography>
                  <Spacer space={4} />
                  {orderDetails.paymentInformation
                    ? orderDetails.paymentInformation.map((detail, i) => (
                        <Typography variant={{ size: 'small' }} key={i}>
                          <Span variant={{ bold: true }}>{detail.title}:</Span>{' '}
                          {detail.value}
                        </Typography>
                      ))
                    : ''}
                  <Spacer space={3} />
                </StackView>
              </FlexGrid.Col>
            ) : null
          ) : null}
          {orderDetails.shippingInformation ? (
            <FlexGrid.Col xs={12} md={4}>
              <ShippingInfo info={orderDetails.shippingInformation} />
            </FlexGrid.Col>
          ) : (
            <FlexGrid.Col xs={12} md={4}>
              <Typography
                block
                variant={{ size: 'h3' }}
                tokens={{ fontSize: 20 }}
              >
                Shipping information
              </Typography>
            </FlexGrid.Col>
          )}
        </FlexGrid.Row>
      </FlexGrid>
      <Spacer space={4} />
      <Spacer space={6} />
    </OrderDetailsWrapper>
  );
};

export default OrderInformation;
