import React from 'react';
import styled from 'styled-components';
import { FlexGrid as Flex } from '@telus-uds/ds-allium';

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px;
`;

export const Content = styled(({ children, ...props }) => (
  <div {...props}>
    <Flex limitWidth={false}>{children}</Flex>
  </div>
))`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
`;
