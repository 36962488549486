import {
  StackView,
  StackWrap,
  Spacer,
  Typography,
  Divider,
  Checkbox,
  Box,
  Card,
  Radio,
  TextButton,
  Button,
  Modal,
} from '@telus-uds/components-web';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { USER_ACCESSORIES, USER_DEVICES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/UserContext';

const DeviceOptionsPageContainer = styled.div`
  // width: 100%;
  padding-right: 0;
  position: relative;

  @media screen and (min-width: 1008px) {
    padding-right: 30%;
  }
`;

const DeviceOptionsPage = () => {
  const { userSettings, setUserSettings } = useContext(UserContext);
  const [restrictOptions, setRestrictOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [restrictModalOpen, setRestrictModalOpen] = useState(false);
  const [holdAction, setHoldAction] = useState([]);
  const [confirmationText, setConfirmationText] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setRestrictOptions(userSettings.restrictDevices);
  }, [userSettings]);

  const handleCheck = () => {
    let newSettings = { ...userSettings };
    if (newSettings.restrictDevices) {
      setRestrictOptions(false);
      newSettings.restrictDevices = !newSettings.restrictDevices;
      setUserSettings(newSettings);
    } else {
      setRestrictModalOpen(true);
    }
  };

  const handleRestrict = () => {
    let newSettings = { ...userSettings };
    newSettings.displayedDevices = 'display-specific';
    newSettings.displayedAccessories = 'display-specific';
    setRestrictOptions(true);
    newSettings.restrictDevices = !newSettings.restrictDevices;
    setUserSettings(newSettings);
    setRestrictModalOpen(false);
  };

  const handleConfirm = (key, id, text) => {
    setHoldAction([key, id]);
    setConfirmationText(text);
    setModalOpen(true);
  };

  const handleYes = () => {
    handleRadio(holdAction[0], holdAction[1]);
    setHoldAction([]);
    setConfirmationText(null);
    setModalOpen(false);
  };

  const handleNo = () => {
    setHoldAction([]);
    setConfirmationText(null);
    setModalOpen(false);
  };

  const handleRadio = (key, id) => {
    let newSettings = { ...userSettings };
    newSettings[key] = id;
    setUserSettings(newSettings);
  };

  const handleDeviceLink = () => {
    navigate(USER_DEVICES);
  };

  const handleAccessoryLink = () => {
    navigate(USER_ACCESSORIES);
  };

  return (
    <DeviceOptionsPageContainer>
      <Spacer space={10} />
      {userSettings ? (
        <StackView>
          <StackWrap tokens={{ justifyContent: 'space-between' }}>
            <Typography block variant={{ size: 'h1' }}>
              Choose device options
            </Typography>
          </StackWrap>
          <Spacer space={7} />
          <Divider />
          <Spacer space={5} />
          <Typography variant={{ bold: true }}>Permissions:</Typography>
          <Spacer space={3} />
          <Typography>
            As a Super Admin, you will be able to view and select all devices,
            accessories and add-ons
          </Typography>
          <Spacer space={5} />
          <Checkbox
            label="I want to restrict my available devices, accessories, and add-ons to the custom catalogue settings"
            checked={userSettings.restrictDevices}
            onChange={handleCheck}
          />
          <Box horizontal={5} vertical={3}>
            <Typography>
              <em>
                Please note: Switching your display will prevent you from
                ordering devices or accessories outside of your custom catalogue
              </em>
            </Typography>
          </Box>
          <Spacer space={5} />
          <Card
            tokens={{
              borderColor: '#676E73',
              paddingBottom: 30,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
            }}
          >
            <Typography block variant={{ size: 'large', colour: 'brand2' }}>
              Display my selected devices
            </Typography>
            <Spacer space={5} />
            <Radio
              label="Display all devices"
              checked={userSettings.displayedDevices === 'display-all'}
              onChange={() =>
                handleConfirm(
                  'displayedDevices',
                  'display-all',
                  'Are you sure you want to display all devices?'
                )
              }
              inactive={restrictOptions}
            />
            <Spacer space={5} />
            <Radio
              label="Display specific devices"
              checked={userSettings.displayedDevices === 'display-specific'}
              onChange={() =>
                handleRadio('displayedDevices', 'display-specific')
              }
              inactive={restrictOptions}
            />
            <Box horizontal={5} vertical={2}>
              <Typography variant={{ size: 'small' }}>
                Your catalogue currently display specific devices, to view and
                change them{' '}
                <TextButton
                  onPress={handleDeviceLink}
                  variant={{ alternative: true, size: 'small' }}
                >
                  click here
                </TextButton>
              </Typography>
            </Box>
            <Spacer space={5} />
            <Radio
              label="Do not display any devices"
              checked={userSettings.displayedDevices === 'display-none'}
              onChange={() =>
                handleConfirm(
                  'displayedDevices',
                  'display-none',
                  'Are you sure you want to not display devices?'
                )
              }
              inactive={restrictOptions}
            />
          </Card>
          <Spacer space={5} />
          <Card
            tokens={{
              borderColor: '#676E73',
              paddingBottom: 30,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
            }}
          >
            <Typography block variant={{ size: 'large', colour: 'brand2' }}>
              Display my selected accessories
            </Typography>
            <Spacer space={5} />
            <Radio
              label="Display all accessories"
              checked={userSettings.displayedAccessories === 'display-all'}
              onChange={() =>
                handleRadio('displayedAccessories', 'display-all')
              }
              inactive={restrictOptions}
            />
            <Spacer space={5} />
            <Radio
              label="Display specific accessories"
              checked={userSettings.displayedAccessories === 'display-specific'}
              onChange={() =>
                handleRadio('displayedAccessories', 'display-specific')
              }
              inactive={restrictOptions}
            />
            <Box horizontal={5} vertical={2}>
              <Typography variant={{ size: 'small' }}>
                Your catalogue currently displays specific accessories, to view
                and change them{' '}
                <TextButton
                  onPress={handleAccessoryLink}
                  variant={{ alternative: true, size: 'small' }}
                >
                  click here
                </TextButton>
              </Typography>
            </Box>
            <Spacer space={5} />
            <Radio
              label="Do not display any accessories"
              checked={userSettings.displayedAccessories === 'display-none'}
              onChange={() =>
                handleRadio('displayedAccessories', 'display-none')
              }
              inactive={restrictOptions}
            />
          </Card>
          <Spacer space={10} />
        </StackView>
      ) : null}
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Typography block variant={{ size: 'h1' }}>
          Confirmation
        </Typography>
        <Spacer space={5} />
        <Typography>{confirmationText}</Typography>
        <Spacer space={5} />
        <Box vertical={4}>
          <StackWrap
            space={6}
            direction="row-reverse"
            tokens={{ alignItems: 'center' }}
          >
            <Button variant={{ priority: 'high' }} onPress={() => handleYes()}>
              Yes
            </Button>
            <StackView>
              <TextButton
                variant={{ alternative: true }}
                onPress={() => handleNo()}
              >
                No
              </TextButton>
            </StackView>
          </StackWrap>
        </Box>
      </Modal>
      <Modal
        isOpen={restrictModalOpen}
        onClose={() => setRestrictModalOpen(false)}
      >
        <Typography block variant={{ size: 'h1' }}>
          Confirmation
        </Typography>
        <Spacer space={5} />
        <Typography>
          Are you sure you want to restrict devices, accessories, and add-ons?
        </Typography>
        <Spacer space={5} />
        <Box vertical={4}>
          <StackWrap
            space={6}
            direction="row-reverse"
            tokens={{ alignItems: 'center' }}
          >
            <Button
              variant={{ priority: 'high' }}
              onPress={() => handleRestrict()}
            >
              Yes
            </Button>
            <StackView>
              <TextButton
                variant={{ alternative: true }}
                onPress={() => setRestrictModalOpen(false)}
              >
                No
              </TextButton>
            </StackView>
          </StackWrap>
        </Box>
      </Modal>
    </DeviceOptionsPageContainer>
  );
};

export default DeviceOptionsPage;
