import React, { useState, useEffect } from 'react';

import { TextInput } from '@telus-uds/ds-allium';

const EmailField = ({
  label,
  value,
  required,
  onchange,
  tooltip,
  feedback,
  validation,
}) => {
  const [valid, setValidation] = useState(validation);
  const [newValue, setNewValue] = useState(value);
  const [touched, setTouched] = useState(false);
  const defaultFeedbackContent = '';
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const feedbackContent = {
    error: `The value must a valid email address.`,
    success: '',
  };

  useEffect(() => {
    if (
      !validation &&
      required &&
      newValue !== undefined &&
      newValue.length > 0
    ) {
      if (EmailRegex.test(newValue)) {
        onchange(newValue);
        setValidation('success');
      } else {
        setValidation('error');
      }
    }
  }, [newValue]);

  useEffect(() => {
    if (
      !required &&
      touched &&
      !validation &&
      (newValue === undefined || newValue.length === 0)
    ) {
      onchange('');
      setValidation('success');
    }
  }, [touched]);

  const getFeedback = () => {
    if (!validation && !feedback) {
      return valid ? feedbackContent[valid] : defaultFeedbackContent;
    }
    return feedback;
  };

  const checkValue = (val, e) => {
    // DO WE NEED TO FORMAT THE EMAIL STRING AS THE USER TYPES???
    // const tempValue = val.toLowerCase().replace(/^[a-zA-Z0-9@-]*$/, '');
    setNewValue(val);
  };

  return (
    <TextInput
      label={label}
      onChange={(val, e) => checkValue(val, e)}
      value={newValue}
      feedback={getFeedback()}
      validation={valid}
      tooltip={tooltip}
      onBlur={() => setTouched(true)}
    />
  );
};

export default EmailField;
