import {
  Typography,
  Spacer,
  StackWrap,
  StackView,
  Divider,
} from '@telus-uds/ds-allium';
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { exampleOrderApprovalList } from 'context/exampleApprovalData';
import DynamicTable from 'components/DynamicTable';
import { HistoryContext } from 'context/HistoryContext';
import SearchBar from 'components/SearchBar';
import { UserContext } from 'context/UserContext';
// import { useNavigate } from 'react-router-dom';

const SubscribersPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 20;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const ROWS_PER_BATCH = 15;

const ApprovalViewPage = ({ user }) => {
  const { orderApproval, orderApprovalHeaders, orderApprovalValues } =
    useContext(HistoryContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [autoCompleteItems, setAutoCompleteItems] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [data, setData] = useState(orderApproval);

  ///////////////////////////////////////////////////

  const [showLoadMore, setShowLoadMore] = useState(true);
  const [visibleData, setVisibleData] = useState([]);
  const [numLoaded, setNumLoaded] = useState(0);

  const [lastSorted, setLastSorted] = useState(-1);
  const [order, setOrder] = useState(false);

  const { userType, setUserType } = useContext(UserContext);
  // const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setVisibleData(data.slice(0, ROWS_PER_BATCH));
      setNumLoaded(ROWS_PER_BATCH);
      setShowLoadMore(false);
    }, 3000);
    setUserType(user);
  }, []);

  const loadMore = () => {
    if (showLoadMore) return;

    if (visibleData.length < data.length) {
      setShowLoadMore(true);
      setTimeout(supplyRows, 3000);
    } else {
      setShowLoadMore(false);
    }
  };

  const supplyRows = () => {
    setVisibleData(
      visibleData.concat(data.slice(numLoaded, numLoaded + ROWS_PER_BATCH))
    );
    setNumLoaded(numLoaded + ROWS_PER_BATCH);
    setShowLoadMore(false);
  };

  const sortColumn = (index) => {
    const newOrder = lastSorted === index ? !order : true;
    const coef = newOrder ? 1 : -1;
    const tableId = orderApprovalHeaders[index].id;

    const newRows = data.sort((row1, row2) => {
      const comp1 = row1.find((obj) => obj.id === tableId).value
        ? row1.find((obj) => obj.id === tableId).value
        : row1.find((obj) => obj.id === tableId);
      const comp2 = row2.find((obj) => obj.id === tableId).value
        ? row2.find((obj) => obj.id === tableId).value
        : row2.find((obj) => obj.id === tableId);
      if (comp1 === comp2) return 0;
      return coef * (comp1 > comp2 ? 1 : -1);
    });

    setOrder(newOrder);
    setData(newRows);
    setVisibleData(newRows.slice(0, numLoaded));
    setLastSorted(index);
    // supplyRows();
    // console.log(lastSorted);
    // console.log(order);
  };

  //////////////////////////////////////////////////

  const updateSearchTerm = (value) => {
    setSearchTerm(value);
    let items = [];
    if (value.length > 1) {
      if (searchFilter) {
        items = orderApprovalValues.filter((obj) => {
          if (obj.header === searchFilter) {
            return (
              obj.value.slice(0, value.length).toLowerCase() ===
              value.toLowerCase()
            );
          }
        });
      } else {
        items = orderApprovalValues
          .filter((obj) => {
            return (
              obj.value.slice(0, value.length).toLowerCase() ===
              value.toLowerCase()
            );
          })
          .slice(0, 10);
      }
      setAutoCompleteItems(items);
    } else {
      setAutoCompleteItems([]);
    }
  };

  const onAutoCompletedItemSelected = (item) => {
    alert(`${item} from auto-complete list selected`);
    setSearchTerm(item);
  };

  ////////////////////////
  const startSearch = () => {
    if (searchTerm.length === 0) {
      setData(orderApproval);
      return;
    }
    console.log('search term: ', searchTerm);
    console.log('filter: ', searchFilter);
    let newData = [];
    if (searchFilter) {
      console.log('searching with filter');
      newData = orderApproval.filter((obj) => {
        return obj
          .filter((property) => {
            return property.id === searchFilter;
          })
          .some((item) =>
            Array.isArray(item.value)
              ? item.value.some(
                  (indValue) =>
                    indValue.toString().slice(0, searchTerm.length) ===
                    searchTerm
                )
              : item.value.toString().slice(0, searchTerm.length) === searchTerm
          );
      });
    } else {
      newData = orderApproval.filter((obj) => {
        return obj.some((item) =>
          Array.isArray(item.value)
            ? item.value.some(
                (indValue) =>
                  indValue.toString().slice(0, searchTerm.length) === searchTerm
              )
            : item.value.toString().slice(0, searchTerm.length) === searchTerm
        );
      });
    }
    console.log('newData: ', newData);
    // alert('Start searching ...');
    setData(newData);
  };
  ///////////////////////////

  const filterUpdate = (filter) => {
    setSearchFilter(filter);
  };
  ////////////////////////////

  return (
    <SubscribersPageContainer>
      <Spacer space={8} />
      <StackView>
        <Typography block variant={{ size: 'h1' }}>
          Approval view{userType ? ` - ${userType}` : null}
        </Typography>
        <Divider space={7} />
        <StackWrap>
          <SearchBar
            filterOptions={exampleOrderApprovalList.filters}
            searchTerm={searchTerm}
            searchTermUpdated={updateSearchTerm}
            autoCompleteItems={autoCompleteItems}
            onAutoCompleteSelected={onAutoCompletedItemSelected}
            onSearch={startSearch}
            chosenFilter={searchFilter}
            updateFilter={filterUpdate}
            approvalStyle={true}
          />
        </StackWrap>
        <Spacer space={4} />
        <Divider space={7} />
        <DynamicTable
          headers={orderApprovalHeaders}
          data={visibleData}
          originalData={data}
          showLoadMore={showLoadMore}
          cbLoadMore={loadMore}
          sortColumn={sortColumn}
          lastSorted={lastSorted}
          order={order}
          // dynamicHeight="calc(100vh - 405px)"
          dynamicHeight="calc(100vh - 450px)"
        />
      </StackView>
    </SubscribersPageContainer>
  );
};

export default ApprovalViewPage;
