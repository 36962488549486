import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Button,
  Divider,
  Box,
  Icon,
  Select,
  TextInput,
  RadioCardGroup,
  Radio,
  Span,
} from '@telus-uds/ds-allium';
import {
  Clipboard,
  Receipt,
  Email,
  Devices,
} from '@telus-uds/palette-allium/build/web/icons';
import palette from '@telus-uds/palette-allium';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { exampleReturningItems } from 'context/exampleOrderHistoryData';
import { exampleCompletedOrderDetails } from 'context/exampleOrderHistoryData';
import { HistoryContext } from 'context/HistoryContext';
import OrderDetails from 'components/OrderDetails';
import { useNavigate } from 'react-router-dom';
import { ORDER_DETAILS, ORDER_HISTORY } from 'constants/routes';
// import SubHeader from 'components/SubHeader';

const OrderReturnPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }
`;

const ReturnItemFlow = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`;

const ReturnItemReel = styled.div`
  display: flex;
  width: 300%;
  margin-left: ${(props) =>
    props.currentStep ? `${-100 * props.currentStep}%` : '0px'};
  transition: margin 500ms;
`;

const ReturnItemFrame = styled.div`
  display: block;
  width: calc(100% / 3);
`;

const HalfWidthBox = styled.div`
  width: 100%;

  @media screen and (min-width: 500px) {
    width: 75%;
  }

  @media screen and (min-width: 683px) {
    width: 48%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  background-color: ${palette.color.greyAthens};
  border-radius: 100%;
  margin: auto;
`;

const TextWrapper = styled.div`
  display: block;
  max-width: 80%;
  text-align: left;
`;

const NowrapWrapper = styled.div`
  > div {
    flex-wrap: nowrap;
  }
`;

const ReturnStep = ({ icon, number, text }) => {
  return (
    <>
      <IconWrapper>
        <Icon icon={icon} variant={{ size: 'large', rank: 'primary' }} />
      </IconWrapper>
      <Spacer space={6} />
      <StackWrap tokens={{ justifyContent: 'center' }}>
        <Typography>{number}. </Typography>
        <TextWrapper>
          <Typography>{text}</Typography>
        </TextWrapper>
      </StackWrap>
    </>
  );
};

const OrderReturnPage = () => {
  const { orderDetails, initializeOrderDetails } = useContext(HistoryContext);
  const [stepTracker, setStepTracker] = useState(0);
  const [checkControl, setCheckControl] = useState([]);
  const [buttonInactive, setButtonInactive] = useState(true);
  const [returnInfo, setReturnInfo] = useState(null);
  const [returnDetails, setReturnDetails] = useState([]);
  const [itemRadioControl, setItemRadioControl] = useState(0);
  const [nextInactive, setNextInactive] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!orderDetails.length) {
      initializeOrderDetails(exampleCompletedOrderDetails);
    }
  }, []);

  useEffect(() => {
    if (orderDetails.devicesAndPlans) {
      let newCheckControl = [];
      orderDetails.devicesAndPlans.map((plan) => {
        let arrayItem = {
          planId: plan.itemId,
          returning: false,
          subscribers: [],
        };
        plan.subscribers.map((subscriber) => {
          let subscriberItem = {
            subscriberId: subscriber.id,
            returning: false,
          };
          arrayItem.subscribers.push(subscriberItem);
        });
        newCheckControl.push(arrayItem);
      });
      setCheckControl(newCheckControl);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (checkControl.some((item) => item.returning === true)) {
      setButtonInactive(false);
    } else if (
      checkControl.some((item) =>
        item.subscribers.some((subscriber) => subscriber.returning === true)
      )
    ) {
      setButtonInactive(false);
    } else {
      setButtonInactive(true);
    }
  }, [checkControl]);

  useEffect(() => {
    let newReturnDetails = [];
    if (returnInfo) {
      returnInfo.items.map((item) => {
        newReturnDetails.push({
          itemName: item.itemName,
          details: item.details,
          returnDue: item.returnDue,
          reason: '',
          reasonText: '',
          reasonDetails: '',
          email: '',
          cancelSubscriber: 'no',
        });
      });
    }
    setReturnDetails(newReturnDetails);
  }, [returnInfo]);

  useEffect(() => {
    let setInactive = false;
    returnDetails.map((item) => {
      if (
        item.reason.length === 0 ||
        item.reasonDetails.length === 0 ||
        item.email.length === 0
      ) {
        setInactive = true;
      }
    });
    setNextInactive(setInactive);
  }, [returnDetails]);

  const handleCheck = (arr1, arr2) => {
    // console.log('arr1: ', arr1);
    // console.log('arr2: ', arr2);
    let newCheckControl = [...checkControl];
    if (arr2 >= 0) {
      // console.log('setting specific');
      newCheckControl[arr1].subscribers[arr2].returning =
        !newCheckControl[arr1].subscribers[arr2].returning;
    } else {
      newCheckControl[arr1].returning = !newCheckControl[arr1].returning;
      newCheckControl[arr1].subscribers.map((subscriber) => {
        subscriber.returning = newCheckControl[arr1].returning;
      });
    }
    setCheckControl(newCheckControl);
  };

  const handleCancel = () => {
    alert('Cancelling order return');
    navigate(`${ORDER_DETAILS}#${orderDetails.orderNumber}`);
  };

  const handleReturn = () => {
    alert('Returning order');
    setReturnInfo(exampleReturningItems);
    setStepTracker(1);
  };

  const updateReturnReason = (reason, key) => {
    let newReturnDetails = [...returnDetails];
    newReturnDetails[key].reason = reason;
    newReturnDetails[key].reasonText = returnInfo.items[key].reasons.find(
      (obj) => obj.reasonId === reason
    ).reasonText;
    setReturnDetails(newReturnDetails);
  };

  const updateReturnDetails = (details, key) => {
    let newReturnDetails = [...returnDetails];
    newReturnDetails[key].reasonDetails = details;
    setReturnDetails(newReturnDetails);
  };

  const updateReturnEmail = (email, key) => {
    let newReturnDetails = [...returnDetails];
    newReturnDetails[key].email = email;
    setReturnDetails(newReturnDetails);
  };

  const updateCancellation = (id, key) => {
    let newReturnDetails = [...returnDetails];
    newReturnDetails[key].cancelSubscriber = id;
    setReturnDetails(newReturnDetails);
  };

  const handleSubmit = () => {
    alert(`Submitting return on order #${returnInfo.orderNumber}`);
    console.log('Return details: ', returnInfo, returnDetails);
    navigate(ORDER_HISTORY);
  };

  return (
    <OrderReturnPageContainer>
      {/* <SubHeader /> */}
      <ReturnItemFlow>
        <Spacer space={8} />
        <ReturnItemReel currentStep={stepTracker}>
          <ReturnItemFrame>
            <StackView>
              <StackWrap tokens={{ justifyContent: 'space-between' }}>
                <Typography block variant={{ size: 'h1' }}>
                  Select items you'd like to return
                </Typography>
                <StackWrap>
                  <StackView tokens={{ justifyContent: 'center' }}>
                    <TextButton
                      onPress={handleCancel}
                      variant={{ alternative: true }}
                    >
                      Cancel
                    </TextButton>
                  </StackView>
                  <Spacer space={4} direction="row" />
                  <Button
                    onPress={handleReturn}
                    variant={{ priority: 'high' }}
                    tokens={{ fontWeight: 400 }}
                    inactive={buttonInactive}
                  >
                    Return items
                  </Button>
                  <Spacer space={1} direction="row" />
                </StackWrap>
              </StackWrap>
              <Spacer space={5} />
              <HalfWidthBox>
                <Typography>
                  NOTE: Checking a whole group will automatically include all
                  subscribers within the group.
                </Typography>
              </HalfWidthBox>
              <Spacer space={5} />
              <OrderDetails
                checkControl={checkControl}
                handleCheck={handleCheck}
              />
            </StackView>
          </ReturnItemFrame>
          <ReturnItemFrame>
            {returnInfo ? (
              <StackView>
                <StackWrap tokens={{ justifyContent: 'space-between' }}>
                  <Typography block variant={{ size: 'h1' }}>
                    Items from order #{returnInfo.orderNumber}
                  </Typography>
                </StackWrap>
                <Divider space={7} variant={{ decorative: true }} />
                <FlexGrid>
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={3} horizontalAlign="center">
                      <ReturnStep
                        icon={Clipboard}
                        number="1"
                        text="Complete the form"
                      />
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={3} horizontalAlign="center">
                      <ReturnStep
                        icon={Email}
                        number="2"
                        text="We will send an email with return instructions"
                      />
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={3} horizontalAlign="center">
                      <ReturnStep
                        icon={Devices}
                        number="3"
                        text="Mail us your return items"
                      />
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={3} horizontalAlign="center">
                      <ReturnStep
                        icon={Receipt}
                        number="4"
                        text="We will inspect and confirm item damage. A credit will
                      be applied to your next invoice"
                      />
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </FlexGrid>
                <Spacer space={4} />
                <Divider space={7} variant={{ decorative: true }} />
                {returnDetails.length > 0 ? (
                  <NowrapWrapper>
                    <StackWrap>
                      <StackView>
                        <Typography
                          block
                          variant={{ size: 'h3' }}
                          tokens={{ fontSize: 20 }}
                        >
                          Devices:
                        </Typography>
                        <Spacer space={6} />
                        {returnDetails.length > 1 ? (
                          returnDetails.map((item, i) => (
                            <StackView key={i}>
                              <StackWrap>
                                <Spacer space={1} direction="row" />
                                <StackView>
                                  <Radio
                                    checked={itemRadioControl === i}
                                    id={`item-${i}`}
                                    onChange={() => setItemRadioControl(i)}
                                    name="item-control"
                                  />
                                </StackView>
                                <Spacer space={1} direction="row" />
                                <StackView>
                                  <Typography variant={{ bold: true }}>
                                    {item.itemName}
                                  </Typography>
                                  <Spacer space={2} />
                                  {item.details
                                    ? item.details.map((detail, i) => (
                                        <StackView key={i}>
                                          <Typography key={i}>
                                            <Span variant={{ bold: true }}>
                                              {detail.name}:
                                            </Span>{' '}
                                            {detail.value}
                                          </Typography>
                                          <Spacer space={2} />
                                        </StackView>
                                      ))
                                    : ''}
                                </StackView>
                              </StackWrap>
                              <Spacer space={6} />
                            </StackView>
                          ))
                        ) : (
                          <StackView>
                            <Typography variant={{ bold: true }}>
                              {returnDetails[0].itemName}
                            </Typography>
                            <Spacer space={2} />
                            {returnDetails[0].details
                              ? returnDetails[0].details.map((detail, i) => (
                                  <StackView key={i}>
                                    <Typography>
                                      <Span variant={{ bold: true }}>
                                        {detail.name}:
                                      </Span>{' '}
                                      {detail.value}
                                    </Typography>
                                    <Spacer space={2} />
                                  </StackView>
                                ))
                              : ''}
                          </StackView>
                        )}
                      </StackView>
                      <Divider
                        space={5}
                        variant={{ decorative: true }}
                        vertical
                      />
                      {returnInfo.items ? (
                        <StackView tokens={{ flexShrink: 1 }}>
                          <FlexGrid fullWidth={true}>
                            <FlexGrid.Row>
                              <FlexGrid.Col xs={12} lg={6}>
                                <Typography
                                  block
                                  variant={{ size: 'h3' }}
                                  tokens={{ fontSize: 20 }}
                                >
                                  Return details
                                </Typography>
                                <Spacer space={7} />
                                {returnInfo.items[itemRadioControl].reasons ? (
                                  <Select
                                    label="Select for returns"
                                    value={
                                      returnDetails[itemRadioControl].reason
                                    }
                                    onChange={(value) =>
                                      updateReturnReason(
                                        value,
                                        itemRadioControl
                                      )
                                    }
                                    placeholder="Please select..."
                                  >
                                    {returnInfo.items[
                                      itemRadioControl
                                    ].reasons.map((reason, i) =>
                                      reason.reasonText ? (
                                        <Select.Item
                                          value={reason.reasonId}
                                          key={i}
                                        >
                                          {reason.reasonText}
                                        </Select.Item>
                                      ) : (
                                        ''
                                      )
                                    )}
                                  </Select>
                                ) : (
                                  ''
                                )}
                                <Spacer space={7} />
                                <TextInput
                                  label="Please provide any additional information for us to process your return request"
                                  value={
                                    returnDetails[itemRadioControl]
                                      .reasonDetails
                                  }
                                  onChange={(value) =>
                                    updateReturnDetails(value, itemRadioControl)
                                  }
                                />
                                <Spacer space={7} />
                                <TextInput
                                  label="Please enter the email address where we can send return instructions"
                                  value={returnDetails[itemRadioControl].email}
                                  onChange={(value) =>
                                    updateReturnEmail(value, itemRadioControl)
                                  }
                                />
                                <Spacer space={7} />
                                <StackWrap>
                                  <Typography>Return Due</Typography>
                                  <Spacer space={5} direction="row" />
                                  <Typography variant={{ bold: true }}>
                                    $
                                    {returnDetails[
                                      itemRadioControl
                                    ].returnDue.toFixed(2)}
                                  </Typography>
                                </StackWrap>
                                <Spacer space={3} />
                              </FlexGrid.Col>
                              <FlexGrid.Col xs={12} lg={6}>
                                <Spacer space={6} />
                                <RadioCardGroup
                                  legend="Would you like to cancel the subscriber along with the device return?"
                                  checkedId={
                                    returnDetails[itemRadioControl]
                                      .cancelSubscriber
                                  }
                                  onChange={(id) =>
                                    updateCancellation(id, itemRadioControl)
                                  }
                                  items={[
                                    {
                                      id: 'yes',
                                      content: (
                                        <StackView
                                          tokens={{
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                          }}
                                        >
                                          <Typography>Yes</Typography>
                                        </StackView>
                                      ),
                                    },
                                    {
                                      id: 'no',
                                      content: (
                                        <StackView
                                          tokens={{
                                            justifyContent: 'center',
                                            flexGrow: 1,
                                          }}
                                        >
                                          <Typography>No</Typography>
                                        </StackView>
                                      ),
                                    },
                                  ]}
                                  radioCardTokens={{
                                    paddingBottom: '8px',
                                    paddingTop: '8px',
                                    minWidth: '150px',
                                  }}
                                />
                                <Spacer space={5} />
                                {returnInfo.items[itemRadioControl].note ? (
                                  <Box
                                    variant={{ background: 'danger' }}
                                    space={4}
                                  >
                                    <Typography>
                                      {returnInfo.items[itemRadioControl].note}
                                    </Typography>
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </FlexGrid.Col>
                            </FlexGrid.Row>
                          </FlexGrid>
                        </StackView>
                      ) : (
                        ''
                      )}
                    </StackWrap>
                  </NowrapWrapper>
                ) : (
                  ''
                )}
                <Divider space={7} variant={{ decorative: true }} />
                <Spacer space={5} />
                <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                  <StackView tokens={{ justifyContent: 'center' }}>
                    <TextButton
                      onPress={handleCancel}
                      variant={{ alternative: true }}
                    >
                      Cancel
                    </TextButton>
                  </StackView>
                  <Spacer space={4} direction="row" />
                  <Button
                    onPress={() => setStepTracker(2)}
                    variant={{ priority: 'high' }}
                    tokens={{ fontWeight: 400 }}
                    inactive={nextInactive}
                  >
                    Next
                  </Button>
                  <Spacer space={1} direction="row" />
                </StackWrap>
              </StackView>
            ) : (
              ''
            )}
          </ReturnItemFrame>
          <ReturnItemFrame>
            <StackView>
              <StackWrap tokens={{ justifyContent: 'space-between' }}>
                <Typography block variant={{ size: 'h1' }}>
                  Return summary
                </Typography>
              </StackWrap>
              <Divider space={7} variant={{ decorative: true }} />
              {returnInfo ? (
                <FlexGrid fullWidth={true} outsideGutter={false}>
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={4}>
                      <StackView>
                        <Typography
                          block
                          variant={{ size: 'h3' }}
                          tokens={{ fontSize: 20 }}
                        >
                          Shipping information
                        </Typography>
                        <Spacer space={5} />
                        <Typography>
                          <Span variant={{ bold: true }}>Original Order: </Span>
                          {returnInfo.orderNumber}
                        </Typography>
                        <Spacer space={1} />
                        <Typography>
                          <Span variant={{ bold: true }}>Payment method: </Span>
                          {returnInfo.paymentMethod}
                        </Typography>
                        <Spacer space={1} />
                        <Typography>
                          <Span variant={{ bold: true }}>Status: </Span>
                          {returnInfo.status}
                        </Typography>
                        <Spacer space={4} />
                      </StackView>
                    </FlexGrid.Col>
                    <Divider space={4} vertical />
                    <FlexGrid.Col xs={7}>
                      <StackView>
                        <Typography
                          block
                          variant={{ size: 'h3' }}
                          tokens={{ fontSize: 20 }}
                        >
                          Contact information
                        </Typography>
                        <Spacer space={5} />
                        {returnInfo.shippingInfo.map((line, i) => (
                          <Typography key={i}>{line}</Typography>
                        ))}
                      </StackView>
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </FlexGrid>
              ) : (
                ''
              )}
              <Divider space={8} variant={{ decorative: true }} />
              <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
                Devices and plans
              </Typography>
              <Spacer space={8} />
              <Box space={4}>
                <FlexGrid gutter={false} limitWidth={false}>
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={5}>
                      <Typography tokens={{ color: palette.color.greyShuttle }}>
                        Device information
                      </Typography>
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={5}>
                      <Typography tokens={{ color: palette.color.greyShuttle }}>
                        Reason for return
                      </Typography>
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={1} xsOffset={1}>
                      <Typography tokens={{ color: palette.color.greyShuttle }}>
                        Return Due
                      </Typography>
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </FlexGrid>
              </Box>
              <Spacer space={2} />
              {returnDetails
                ? returnDetails.map((item, i) => (
                    <Box
                      variant={i % 2 === 0 ? { background: 'light' } : {}}
                      space={4}
                      key={i}
                    >
                      <FlexGrid gutter={false} limitWidth={false}>
                        <FlexGrid.Row>
                          <FlexGrid.Col xs={1}>
                            <Typography>Device:</Typography>
                            <Spacer space={4} />
                            {item.details
                              ? item.details.map((detail, i) => (
                                  <StackView key={i}>
                                    <Typography>{detail.name}:</Typography>
                                    <Spacer space={3} />
                                  </StackView>
                                ))
                              : ''}
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={4}>
                            <Typography>{item.itemName}</Typography>
                            <Spacer space={4} />
                            {item.details
                              ? item.details.map((detail, i) => (
                                  <StackView key={i}>
                                    <Typography>{detail.value}</Typography>
                                    <Spacer space={3} />
                                  </StackView>
                                ))
                              : ''}
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={5}>
                            {returnInfo.items[i].reasons ? (
                              <Typography>{item.reasonText}</Typography>
                            ) : (
                              ''
                            )}
                            <Spacer space={4} />
                            <Typography>{item.reasonDetails}</Typography>
                            <Spacer space={4} />
                            <Typography>
                              Would you like to cancel the subscriber along with
                              the device return?{' '}
                              {item.cancelSubscriber.charAt(0).toUpperCase() +
                                item.cancelSubscriber.slice(1)}
                            </Typography>
                            <Spacer space={4} />
                          </FlexGrid.Col>
                          <FlexGrid.Col xs={1} xsOffset={1}>
                            <Typography variant={{ bold: true }}>
                              ${item.returnDue.toFixed(2)}
                            </Typography>
                          </FlexGrid.Col>
                        </FlexGrid.Row>
                      </FlexGrid>
                    </Box>
                  ))
                : ''}
              <Spacer space={5} />
              {returnInfo ? (
                <Box space={4}>
                  <FlexGrid gutter={false} limitWidth={false}>
                    <FlexGrid.Row>
                      <FlexGrid.Col xs={2} xsOffset={9}>
                        <Typography>Subtotal</Typography>
                        <Spacer space={4} />
                        <Typography>Shipping</Typography>
                        <Spacer space={4} />
                        <Typography variant={{ bold: true }}>TOTAL</Typography>
                        <Spacer space={4} />
                      </FlexGrid.Col>
                      <FlexGrid.Col xs={1}>
                        <Typography>
                          ${returnInfo.subtotal.toFixed(2)}
                        </Typography>
                        <Spacer space={4} />
                        <Typography>
                          {returnInfo.shipping === 0
                            ? 'FREE'
                            : `$${returnInfo.shipping.toFixed(2)}`}
                        </Typography>
                        <Spacer space={4} />
                        <Typography variant={{ bold: true }}>
                          ${returnInfo.total.toFixed(2)}
                        </Typography>
                        <Spacer space={4} />
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid>
                </Box>
              ) : (
                ''
              )}
              <Spacer space={5} />
              <StackWrap tokens={{ justifyContent: 'flex-end' }}>
                <StackView tokens={{ justifyContent: 'center' }}>
                  <TextButton
                    onPress={() => setStepTracker(1)}
                    variant={{ alternative: true }}
                  >
                    Back
                  </TextButton>
                </StackView>
                <Spacer space={8} direction="row" />
                <Button
                  onPress={handleSubmit}
                  variant={{ priority: 'high' }}
                  tokens={{ fontWeight: 400 }}
                >
                  Submit
                </Button>
                <Spacer space={1} direction="row" />
              </StackWrap>
              <Spacer space={1} />
            </StackView>
          </ReturnItemFrame>
        </ReturnItemReel>
      </ReturnItemFlow>
      <Spacer space={10} />
    </OrderReturnPageContainer>
  );
};

export default OrderReturnPage;
