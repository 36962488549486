import { createGlobalStyle } from 'styled-components';
import { DeviceContext } from 'context/DeviceContext';
import StartPage from 'pages/StartPage';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import DevicesPage from 'pages/Devices/DevicesPage';
import OptionsPage from 'pages/Devices/OptionsPage';
import AccessoriesPage from 'pages/Devices/AccessoriesPage';
import CartFooter from 'components/CartFooter';
import SubscribersPage from 'pages/Devices/SubscribersPage';
import OrderHistoryPage from 'pages/Orders/OrderHistoryPage';
import OrderDetailsPage from 'pages/Orders/OrderDetailsPage';
import OrderReturnPage from 'pages/Orders/OrderReturnPage';
import ByodPage from 'pages/ByodPlans';
import AccessoriesSelectionPage from 'pages/Accessories';
import * as S from './mainLayout.styles';
import {
  ACCESSORIES,
  APPROVAL_VIEW_ADMIN,
  APPROVAL_VIEW_ENDUSER,
  BASE,
  BYOD_PLANS,
  DEVICE_ACCESSORIES,
  DEVICE_OPTIONS,
  DEVICE_SELECT,
  DEVICE_SUBSCRIBERS,
  MY_BUNDLES,
  ORDER_APPROVAL,
  ORDER_DETAILS,
  ORDER_HISTORY,
  ORDER_PAYMENT,
  ORDER_RETURN,
  ORDER_SUBMIT,
  UPGRADES,
  UPGRADE_BUNDLES,
  USER_ACCESSORIES,
  USER_DEVICES,
  USER_SETTINGS,
} from '../constants/routes';
import UpgradesPage from 'pages/UpgradeSubscriptions/UpgradesPage';
import BundlesPage from 'pages/UpgradeSubscriptions/BundlesPage';
import MyBundlesPage from 'pages/MyBundles';
import SubHeader from 'components/SubHeader';
import DisplayDevicesPage from 'pages/UserSettings/DisplayDevicesPage';
import DeviceOptionsPage from 'pages/UserSettings/DeviceOptionsPage';
import ApprovalViewPage from 'pages/Approval/ApprovalViewPage';
import AdminApprovalPage from 'pages/Approval/AdminApprovalPage/AdminApprovalPage';
import EnduserApprovalPage from 'pages/Approval/EnduserApprovalPage';
import { UserContext } from 'context/UserContext';
import EnduserPaymentPage from 'pages/Approval/EnduserPaymentPage';
import EnduserSubmitPage from 'pages/Approval/EnduserSubmitPage';
import DisplayAccessoriesPage from 'pages/UserSettings/DisplayAccessoriesPage copy';

const GlobalStyle = createGlobalStyle`
  body{
    font-family: HelveticaNow400normal;
  }
`;

const MainLayout = () => {
  const { cartFooterOn } = useContext(DeviceContext);
  const { userType } = useContext(UserContext);

  const CartFooterCheck = () => {
    if (cartFooterOn) {
      return <CartFooter />;
    }
    return null;
  };

  return (
    <>
      <S.MainLayout>
        <GlobalStyle />
        <S.Content>
          <SubHeader />
          <Routes>
            <Route path={BASE} element={<StartPage />} />
            <Route path={DEVICE_SELECT} element={<DevicesPage />} />
            <Route path={DEVICE_OPTIONS} element={<OptionsPage />} />
            <Route path={DEVICE_ACCESSORIES} element={<AccessoriesPage />} />
            <Route path={DEVICE_SUBSCRIBERS} element={<SubscribersPage />} />
            <Route path={ORDER_HISTORY} element={<OrderHistoryPage />} />
            <Route path={ORDER_DETAILS} element={<OrderDetailsPage />} />
            <Route path={ORDER_RETURN} element={<OrderReturnPage />} />
            <Route path={BYOD_PLANS} element={<ByodPage />} />
            <Route path={ACCESSORIES} element={<AccessoriesSelectionPage />} />
            <Route path={UPGRADES} element={<UpgradesPage />} />
            <Route path={UPGRADE_BUNDLES} element={<BundlesPage />} />
            <Route path={MY_BUNDLES} element={<MyBundlesPage />} />
            <Route path={USER_SETTINGS} element={<DeviceOptionsPage />} />
            <Route path={USER_DEVICES} element={<DisplayDevicesPage />} />
            <Route
              path={USER_ACCESSORIES}
              element={<DisplayAccessoriesPage />}
            />
            <Route
              path={APPROVAL_VIEW_ADMIN}
              element={<ApprovalViewPage user={'admin'} />}
            />
            <Route
              path={APPROVAL_VIEW_ENDUSER}
              element={<ApprovalViewPage user={'enduser'} />}
            />
            <Route
              path={ORDER_APPROVAL}
              element={
                userType === 'admin' ? (
                  <AdminApprovalPage />
                ) : (
                  <EnduserApprovalPage />
                )
              }
            />
            <Route path={ORDER_PAYMENT} element={<EnduserPaymentPage />} />
            <Route path={ORDER_SUBMIT} element={<EnduserSubmitPage />} />
          </Routes>
        </S.Content>
      </S.MainLayout>
      <CartFooterCheck />
    </>
  );
};

export default MainLayout;
