import {
  Typography,
  FlexGrid,
  Spacer,
  StackWrap,
  StackView,
  TextButton,
  Divider,
  Box,
  Icon,
  TextArea,
  Button,
  BaseModal,
} from '@telus-uds/ds-allium';
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  exampleOrderDetails,
  exampleCompletedOrderDetails,
} from 'context/exampleOrderHistoryData';
import { HistoryContext } from 'context/HistoryContext';
import OrderDetails from 'components/OrderDetails';
import OrderInformation from 'components/OrderInformation';
import { Toast } from '@telus-uds/components-web';
// import { useNavigate } from 'react-router-dom';

const OrderDetailsPageContainer = styled.div`
  width: 100%;
  position: relative;
  > div > div:first-of-type {
    z-index: 10;
  }

  @media screen and (min-width: 1008px) {
  }
`;

const ApproveModal = ({ order, updateOrderStatus }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [buttonInactive, setButtonInactive] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    console.log('hello');
    if (order.orderStatus === 'Rejected' || order.orderStatus === 'Approved') {
      setButtonInactive(true);
    }
  }, []);

  const handleCancel = () => {
    setModalOpen(false);
    setReject(false);
  };

  const handleRejectPress = () => {
    setReject(true);
    setModalOpen(true);
  };

  const handleReject = () => {
    alert(`Order ${order.orderNumber} rejected`);
    updateOrderStatus('Rejected');
    setModalOpen(false);
    setReject(false);
    setButtonInactive(true);
  };

  const handleApprove = () => {
    alert(`Order ${order.orderNumber} approved`);
    updateOrderStatus('Approved');
    setModalOpen(false);
    setReject(false);
    setButtonInactive(true);
  };

  return (
    <StackWrap tokens={{ justifyContent: 'flex-end' }}>
      <StackWrap>
        <StackView tokens={{ justifyContent: 'center' }}>
          {buttonInactive ? (
            <Typography variant={{ colour: 'light' }}>Reject</Typography>
          ) : (
            <TextButton
              onPress={handleRejectPress}
              variant={{ alternative: true }}
            >
              Reject
            </TextButton>
          )}
        </StackView>
        <Spacer space={4} direction="row" />
        <Button
          onPress={() => setModalOpen(true)}
          variant={{ priority: 'high' }}
          tokens={{ fontWeight: 400 }}
          inactive={buttonInactive}
        >
          Approve
        </Button>
        <BaseModal
          isOpen={modalOpen}
          onClose={handleCancel}
          variant={{ width: 'xl' }}
        >
          <Box space={3}>
            <Typography block variant={{ size: 'h1' }}>
              Confirmation
            </Typography>
            <Box vertical={5}>
              {reject ? (
                <Typography>Are you sure you want to reject order?</Typography>
              ) : (
                <Typography>Are you sure you want to approve order?</Typography>
              )}
            </Box>
            <Spacer space={4} />
            <Box vertical={3}>
              <StackView
                direction="row"
                tokens={{ alignItems: 'center', justifyContent: 'flex-end' }}
              >
                {reject ? (
                  <>
                    <StackView>
                      <TextButton
                        variant={{ alternative: true }}
                        onPress={handleCancel}
                      >
                        No
                      </TextButton>
                    </StackView>
                    <Spacer space={4} direction="row" />
                    <Button
                      variant={{ priority: 'high' }}
                      onPress={handleReject}
                    >
                      Yes
                    </Button>
                  </>
                ) : (
                  <>
                    <StackView>
                      <TextButton
                        variant={{ alternative: true }}
                        onPress={handleCancel}
                      >
                        Cancel
                      </TextButton>
                    </StackView>
                    <Spacer space={4} direction="row" />
                    <Button
                      variant={{ priority: 'high' }}
                      onPress={handleApprove}
                    >
                      Approve
                    </Button>
                  </>
                )}
              </StackView>
            </Box>
          </Box>
        </BaseModal>
      </StackWrap>
    </StackWrap>
  );
};

const CommentBox = () => {
  const { orderDetails, setOrderComments } = useContext(HistoryContext);
  const [boxOpen, setBoxOpen] = useState(false);

  return (
    <StackView>
      <Box vertical={5}>
        <StackWrap
          tokens={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography block variant={{ size: 'h2', colour: 'secondary' }}>
            Comments
          </Typography>
          <StackWrap tokens={{ alignItems: 'center' }}>
            <TextButton onPress={() => setBoxOpen(!boxOpen)}>
              {boxOpen ? (
                <Icon icon={CaretUp} variant={{ size: 'small' }} />
              ) : (
                <Icon icon={CaretDown} variant={{ size: 'small' }} />
              )}
            </TextButton>
            <Spacer space={1} direction="row" />
          </StackWrap>
        </StackWrap>
      </Box>
      {boxOpen ? (
        <>
          {orderDetails.comments && (
            <Box variant={{ background: 'light' }} space={4}>
              <Typography tokens={{ fontWeight: 700 }}>
                Submitter comments
              </Typography>
              <Spacer space={4} />
              <Typography>{orderDetails.comments}</Typography>
            </Box>
          )}
          <Box vertical={3}>
            <FlexGrid outsideGutter={false} limitWidth={false}>
              <FlexGrid.Row>
                <FlexGrid.Col xs={12} sm={10} md={8}>
                  <TextArea
                    label="Approver’s comments"
                    value={orderDetails.approverComments}
                    onChange={setOrderComments}
                  />
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
            <Spacer space={5} />
          </Box>
        </>
      ) : (
        ''
      )}
    </StackView>
  );
};

const AdminApprovalPage = () => {
  const {
    orderComplete,
    orderDetails,
    initializeOrderDetails,
    setOrderStatus,
    activateToast,
  } = useContext(HistoryContext);

  useEffect(() => {
    console.log('initializing order details');
    if (window.location.hash.substring(1) === 'complete') {
      initializeOrderDetails(exampleCompletedOrderDetails);
    } else {
      initializeOrderDetails(exampleOrderDetails);
    }
  }, []);

  const handleStatusUpdate = (status) => {
    setOrderStatus(status);
    activateToast();
  };

  return (
    <OrderDetailsPageContainer>
      <Spacer space={8} />
      <StackView>
        <StackWrap tokens={{ justifyContent: 'space-between' }}>
          <Typography block variant={{ size: 'h1' }}>
            {/* {window.location.hash
                ? `Order # ${window.location.hash.substring(1)}`
                : 'no order loaded'} */}
            Order # {orderDetails.orderNumber}
          </Typography>
        </StackWrap>
        <Divider space={6} />
      </StackView>
      <OrderInformation />
      <OrderDetails />
      {!orderComplete ? (
        <>
          <Divider />
          <CommentBox />
          <Divider />
          <Spacer space={8} />

          <ApproveModal
            order={orderDetails}
            updateOrderStatus={handleStatusUpdate}
          />
        </>
      ) : null}
      <Spacer space={10} />
    </OrderDetailsPageContainer>
  );
};

export default AdminApprovalPage;
