import React from 'react';
import ReactDOM from 'react-dom';
import MainLayout from 'layout';
import { BrowserRouter } from 'react-router-dom';
import { BaseProvider } from '@telus-uds/components-web';
// import { AlliumProvider } from '@telus-uds/ds-allium';
import alliumTheme from '@telus-uds/theme-allium';

import '@telus-uds/palette-allium/build/web/fonts/fonts-cdn.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ContextProvider } from 'context/ContextProvider';

ReactDOM.render(
  <BaseProvider defaultTheme={alliumTheme}>
    <BrowserRouter>
      <ContextProvider>
        <MainLayout />
      </ContextProvider>
    </BrowserRouter>
  </BaseProvider>,
  document.getElementById('app')
);
